import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  ListItem,
  Progress,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode, useMemo, useState } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RepeatIcon,
} from '@chakra-ui/icons';

import { timeAgo } from '../../utils/dateTime';
import {
  AffiliateNetworkId,
  MerchantFeedStatus,
} from '../../data/contracts/affiliateNetworksContracts';
import { MerchantProcessStatus } from '../../data/contracts/processStatus';

import { TriggerMerchantProcessingButton } from './TriggerMerchantProcessingButton';

type MerchantFeedStatusDetailsProps = {
  feedStatus: MerchantFeedStatus;
  goToCategoryMapping: () => void;
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
};

type ProgressBarDetailsProps = {
  status: MerchantProcessStatus;
  percentage: number;
};

const statusTexts: Record<MerchantProcessStatus, string> = {
  error: 'Failure',
  'in-progress': 'In progress',
  'in-queue': 'In progress',
  success: 'Success',
  warning: 'Needs attention',
  'initial-bootstrap': 'Processing initial setup',
  'initial-category-mapping': 'Needs attention',
};

const statusColors: Record<MerchantProcessStatus, string> = {
  error: 'red',
  'in-progress': 'gray',
  'in-queue': 'gray',
  success: 'green',
  warning: 'green',
  'initial-bootstrap': 'gray',
  'initial-category-mapping': 'gray',
};

function ProgressBar(props: ProgressBarDetailsProps) {
  return (
    <Progress
      colorScheme={statusColors[props.status]}
      size="lg"
      value={100 - props.percentage}
      isIndeterminate={
        props.status === 'in-progress' || props.status === 'initial-bootstrap'
      }
      mb="2rem"
      borderRadius="md"
    />
  );
}

type StatusAlertProps = {
  status: AlertProps['status'];
  title: ReactNode;
  description: ReactNode;
};

const StatusAlert = (props: StatusAlertProps) => (
  <Alert
    status={props.status}
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    mb={6}
  >
    <AlertIcon />
    <AlertTitle my={2}>{props.title}</AlertTitle>
    <AlertDescription mb={2}>{props.description}</AlertDescription>
  </Alert>
);

type ProductFailure = MerchantFeedStatus['groupedProductFailures'][number];
const ProductFailureDetails = (props: { failure: ProductFailure }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { linesNumber, failureMessage } = props.failure;

  const errors = useMemo(
    () => (
      <Text maxWidth={'100%'} fontSize="sm">
        Line numbers: {linesNumber}
      </Text>
    ),
    [linesNumber],
  );

  return (
    <Box>
      <Text>{failureMessage}</Text>
      <Box mt={1}>
        <Button onClick={isOpen ? onClose : onOpen} variant="link">
          {isOpen ? 'Hide line numbers' : 'Show line numbers'}
        </Button>
        <div style={{ overflow: 'hidden', height: isOpen ? 'auto' : 0 }}>
          {errors}
        </div>
      </Box>
    </Box>
  );
};

const Pagination = (props: {
  numberOfPages: number;
  page: number;
  setPage: (page: number) => void;
}) => {
  const { numberOfPages, page, setPage } = props;
  return (
    <Flex justifyContent={'center'} mt={'20px'}>
      <Button disabled={page === 0} onClick={() => setPage(page - 1)}>
        <ChevronLeftIcon />
      </Button>
      {
        <Button mx={'10px'} disabled={true}>
          {page + 1}
        </Button>
      }
      <Button
        disabled={page === numberOfPages}
        onClick={() => setPage(page + 1)}
      >
        <ChevronRightIcon />
      </Button>
    </Flex>
  );
};

const ProductFailuresDetails = (props: {
  productFailures: MerchantFeedStatus['groupedProductFailures'];
}) => {
  const { productFailures } = props;
  const [page, setPage] = useState(0);
  const visibleOnPage = 5;
  return (
    <>
      <Box mt={2} mx={0} height={400} overflowY={'auto'}>
        <UnorderedList spacing={4}>
          {productFailures
            .slice(page * visibleOnPage, (page + 1) * visibleOnPage)
            .map((failure) => (
              <ListItem key={failure.failureMessage}>
                <ProductFailureDetails failure={failure} />
              </ListItem>
            ))}
        </UnorderedList>
      </Box>
      {productFailures.length > visibleOnPage && (
        <Pagination
          numberOfPages={Math.floor(productFailures.length / visibleOnPage)}
          setPage={setPage}
          page={page}
        />
      )}
    </>
  );
};

const ProductFailuresTabs = (props: {
  groupedProductFailures: MerchantFeedStatus['groupedProductFailures'];
  groupedProductEnrichmentFailures: MerchantFeedStatus['groupedProductFailures'];
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  const { groupedProductFailures, groupedProductEnrichmentFailures } = props;

  const hasProductFailures = groupedProductFailures?.length > 0;
  const hasProductEnrichmentFailures =
    groupedProductEnrichmentFailures?.length > 0;

  return (
    <Tabs
      index={tabIndex}
      onChange={(index) => setTabIndex(index)}
      isLazy
      h="100%"
      d="flex"
      flexDir="column"
    >
      <TabList data-testid="merchant-feed-errors-tab-list">
        {hasProductFailures && <Tab>Transformation errors</Tab>}
        {hasProductEnrichmentFailures && <Tab>Enrichment errors</Tab>}
      </TabList>

      <TabPanels
        display="contents"
        data-testid="merchant-feed-error-tab-panels"
      >
        {hasProductFailures && (
          <TabPanel data-testid="merchant-feed-errors-common-tab-panel">
            <ProductFailuresDetails productFailures={groupedProductFailures} />
          </TabPanel>
        )}
        {hasProductEnrichmentFailures && (
          <TabPanel data-testid="merchant-feed-errors-enrichment-tab-panel">
            <ProductFailuresDetails
              productFailures={groupedProductEnrichmentFailures}
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export function MerchantFeedStatusDetails(
  props: MerchantFeedStatusDetailsProps,
) {
  const { feedStatus, merchantSlug, affiliateNetworkSlug } = props;
  const successfullyProcessedProductsCount = feedStatus.processedProductsCount;
  const failedProductsCount = feedStatus.failedProductsCount;
  const processedProductsCount =
    successfullyProcessedProductsCount + failedProductsCount;

  const failedProductsPercentage =
    (feedStatus.failedProductsCount / processedProductsCount) * 100;

  const status = feedStatus.processingStatus;

  const isCurrentlyProcessing =
    status === 'in-progress' ||
    status === 'in-queue' ||
    status === 'initial-bootstrap' ||
    status === 'initial-category-mapping';

  return (
    <>
      {feedStatus.error && (
        <StatusAlert
          status="error"
          title="Couldn't process the feed"
          description={feedStatus.error}
        />
      )}
      {status === 'initial-bootstrap' && (
        <StatusAlert
          status="info"
          title="Feed file is being preprocessed."
          description="You'll need to fill in category mapping after it's done."
        />
      )}
      {status === 'initial-category-mapping' && (
        <StatusAlert
          status="warning"
          title="Category mapping needs to be filled in"
          description={
            <Button
              onClick={props.goToCategoryMapping}
              colorScheme="blue"
              mt={2}
            >
              Fill in category mapping
            </Button>
          }
        />
      )}
      <HStack mt={4} mb={12}>
        <Heading size="lg" flexGrow={1}>
          {feedStatus.name}
        </Heading>
        <TriggerMerchantProcessingButton
          rightIcon={<RepeatIcon />}
          affiliateNetworkSlug={affiliateNetworkSlug}
          merchantSlug={merchantSlug}
          isLoading={isCurrentlyProcessing}
        >
          Trigger processing
        </TriggerMerchantProcessingButton>
      </HStack>
      <SimpleGrid minChildWidth="180px" spacing={6} mt={6} mb={12}>
        <Stat>
          <StatLabel>Status</StatLabel>
          <StatNumber>{statusTexts[feedStatus.processingStatus]}</StatNumber>
          <StatHelpText>
            {isCurrentlyProcessing ? 'Started ' : ''}
            {timeAgo(feedStatus.lastUpdatedAt)}
          </StatHelpText>
        </Stat>

        {!isCurrentlyProcessing && (
          <Stat>
            <StatLabel>Processed Products</StatLabel>
            <StatNumber>{successfullyProcessedProductsCount}</StatNumber>
          </Stat>
        )}

        {!isCurrentlyProcessing && (
          <Stat>
            <StatLabel>Failed Products</StatLabel>
            <StatNumber>{failedProductsCount}</StatNumber>
            {failedProductsCount > 0 && (
              <StatHelpText>
                <StatArrow type="decrease" />
                {failedProductsPercentage.toFixed(2)}%
              </StatHelpText>
            )}
          </Stat>
        )}
      </SimpleGrid>
      {feedStatus.comment && (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          p={4}
          mb={4}
          borderColor="gray.200"
          bg="white"
          boxShadow="sm"
        >
          <Heading size="xs" mb={2}>
            Comment
          </Heading>
          <Text whiteSpace="pre-line" mt={2}>
            {feedStatus.comment}
          </Text>
        </Box>
      )}
      <ProgressBar
        status={feedStatus.processingStatus}
        percentage={failedProductsPercentage}
      />
      <ProductFailuresTabs
        groupedProductFailures={feedStatus.groupedProductFailures}
        groupedProductEnrichmentFailures={
          feedStatus.groupedProductEnrichmentFailures
        }
      />
    </>
  );
}

export function MerchantFeedStatusDetailsSkeleton(props: {
  name?: MerchantFeedStatusDetailsProps['feedStatus']['name'];
  status?: MerchantFeedStatusDetailsProps['feedStatus']['processingStatus'];
}) {
  const { name, status } = props;
  return (
    <>
      <Heading size="lg" mt={4} mb={12}>
        {name ?? (
          <Box py={2}>
            <Skeleton width="200px" height={5}>
              Merchant name
            </Skeleton>
          </Box>
        )}
      </Heading>

      <SimpleGrid minChildWidth="180px" spacing={6} mt={6} mb={12}>
        {status ? (
          <Stat>
            <StatLabel>Status</StatLabel>
            <StatNumber>{statusTexts[status]}</StatNumber>
          </Stat>
        ) : (
          <Stat as="div">
            <StatLabel as="div" my={2}>
              <Skeleton height={3} width="100px">
                Status
              </Skeleton>
            </StatLabel>
            <StatNumber as="div" py={2}>
              <Skeleton height={5} width="200px">
                Success
              </Skeleton>
            </StatNumber>
            <Spacer height={6} />
          </Stat>
        )}
        <Stat as="div">
          <StatLabel as="div" my={2}>
            <Skeleton height={3} width="100px">
              Status
            </Skeleton>
          </StatLabel>
          <StatNumber as="div" py={2}>
            <Skeleton height={5} width="200px">
              Success
            </Skeleton>
          </StatNumber>
        </Stat>
        <Stat as="div">
          <StatLabel as="div" my={2}>
            <Skeleton height={3} width="100px">
              Status
            </Skeleton>
          </StatLabel>
          <StatNumber as="div" py={2}>
            <Skeleton height={5} width="200px">
              Success
            </Skeleton>
          </StatNumber>
        </Stat>
      </SimpleGrid>

      <Skeleton height={4} />

      <Skeleton height={3} width="150px" mt={10} />
      <Skeleton height={3} mt={5} />
    </>
  );
}
