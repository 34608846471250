import { cloneElement } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

type FormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  // body will be injected as contentWrapper child
  contentWrapper: React.ReactElement;
  header: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
};

export const FormModal = (props: FormModalProps) => (
  <Modal
    onClose={props.onClose}
    size="4xl"
    isOpen={props.isOpen}
    closeOnOverlayClick={false}
  >
    <ModalOverlay />
    <ModalContent>
      {cloneElement(
        props.contentWrapper,
        undefined,
        <>
          <ModalHeader>{props.header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{props.body}</ModalBody>
          <ModalFooter>{props.footer}</ModalFooter>
        </>,
      )}
    </ModalContent>
  </Modal>
);
