import { useCallback, useRef, useState } from 'react';
import {
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
} from '@chakra-ui/react';

import { ActiveColumnMapping } from '../../../data/contracts/columnsMapping';

export function useRemoveColumnMappingDialog(
  removeColumnMapping: (
    columnMapping: ActiveColumnMapping | null,
  ) => Promise<void>,
) {
  const columnMappingToRemoveRef = useRef<ActiveColumnMapping | null>(null);
  const [isRemovingColumnMapping, setIsRemovingColumnMapping] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onOpenRemoveColumnMappingDialog = useCallback(
    (columnMapping: ActiveColumnMapping) => {
      columnMappingToRemoveRef.current = columnMapping;
      onOpen();
    },
    [onOpen],
  );

  const onCloseRemoveColumnMapping = useCallback(() => {
    columnMappingToRemoveRef.current = null;
    onClose();
  }, [onClose]);

  const onRemoveColumnMapping = useCallback(async () => {
    try {
      const columnMapping = columnMappingToRemoveRef.current;
      setIsRemovingColumnMapping(true);
      await removeColumnMapping(columnMapping);
    } finally {
      onCloseRemoveColumnMapping();
      setIsRemovingColumnMapping(false);
    }
  }, [removeColumnMapping, onCloseRemoveColumnMapping]);

  return {
    isRemoveColumnMappingDialogOpen: isOpen,
    onOpenRemoveColumnMappingDialog,
    onCloseRemoveColumnMapping,
    onRemoveColumnMapping,
    isRemovingColumnMapping,
  };
}

type RemoveColumnMappingDialogProps = ReturnType<
  typeof useRemoveColumnMappingDialog
>;

export function RemoveColumnMappingDialog(
  props: RemoveColumnMappingDialogProps,
) {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={props.isRemoveColumnMappingDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onCloseRemoveColumnMapping}
    >
      <AlertDialogOverlay>
        <AlertDialogContent aria-label="confirm">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remove dynamic column mapping
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="red"
              onClick={props.onRemoveColumnMapping}
              isLoading={props.isRemovingColumnMapping}
            >
              Remove
            </Button>
            <Button
              ref={cancelRef}
              onClick={props.onCloseRemoveColumnMapping}
              isDisabled={props.isRemovingColumnMapping}
              variant="outline"
              ml={2}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
