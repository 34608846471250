import { Alert, AlertIcon, CloseButton } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { ChakraComponentProps } from '../../types/helpers';

type FormStatusAlertProps = ChakraComponentProps<typeof Alert> &
  PropsWithChildren<{ onClose: () => void }>;

export function FormStatusAlert(props: FormStatusAlertProps) {
  const { onClose, children, ...alertProps } = props;

  return (
    <Alert status="error" pr={10} {...alertProps}>
      <AlertIcon />
      {children}
      <CloseButton onClick={onClose} position="absolute" right={1} top={2} />
    </Alert>
  );
}
