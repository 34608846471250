import { useCallback, useState } from 'react';
import {
  HStack,
  VStack,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Form, Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';

import {
  AffiliateNetworkId,
  MerchantFeedConfig,
} from '../../data/contracts/affiliateNetworksContracts';
import { useMerchantFeedConfigMutation } from '../../utils/fetch';
import { EditOrCancelButton } from '../../components/EditOrCancelButton';
import {
  MerchantFeedSettingsFormFields,
  MerchantFeedSettingsFormValues,
  useMerchantFeedSettingsValidationSchema,
} from '../../components/MerchantFeedSettingsFormFields';
import { FormStatusAlert } from '../../components/Forms/FormStatusAlert';

import { DeleteMerchantButton } from './DeleteMerchantButton';

type AwinMerchantFeedSettingsFormProps = {
  affiliateNetworkSlug: 'AWIN';
  merchantSlug: string;
  config: MerchantFeedConfig<'AWIN'>;
  shouldShowInProgressWarning?: boolean;
};

type CjMerchantFeedSettingsFormProps = {
  affiliateNetworkSlug: 'CJ';
  merchantSlug: string;
  config: MerchantFeedConfig<'CJ'>;
  shouldShowInProgressWarning?: boolean;
};

type TradeDoublerMerchantFeedSettingsFormProps = {
  affiliateNetworkSlug: 'TDP';
  merchantSlug: string;
  config: MerchantFeedConfig<'TDP'>;
  shouldShowInProgressWarning?: boolean;
};

type StandardMerchantFeedSettingsFormProps = {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
  config: MerchantFeedConfig;
  shouldShowInProgressWarning?: boolean;
};

type MerchantFeedSettingsFormProps =
  | AwinMerchantFeedSettingsFormProps
  | CjMerchantFeedSettingsFormProps
  | TradeDoublerMerchantFeedSettingsFormProps
  | StandardMerchantFeedSettingsFormProps;

export function MerchantFeedSettingsForm(props: MerchantFeedSettingsFormProps) {
  const history = useHistory();
  const initialValues: MerchantFeedSettingsFormValues = props.config;
  const validationSchema = useMerchantFeedSettingsValidationSchema(
    props.affiliateNetworkSlug,
  );

  const configMutation = useMerchantFeedConfigMutation(
    props.affiliateNetworkSlug,
    props.merchantSlug,
    {
      onSuccess: (result) => {
        if (result.slug && result.slug !== props.merchantSlug) {
          history.replace(`/${props.affiliateNetworkSlug}/${result.slug}`);
        }
      },
    },
  );

  const [isReadOnly, setIsReadOnly] = useState(true);

  const startEdit = useCallback(() => setIsReadOnly(false), []);
  const cancelEdit = useCallback(() => setIsReadOnly(true), []);

  const submitHandler = useCallback(
    async (
      values: MerchantFeedSettingsFormValues,
      formikHelpers: FormikHelpers<MerchantFeedSettingsFormValues>,
    ) => {
      formikHelpers.setStatus();

      try {
        await configMutation.mutateAsync(values as MerchantFeedConfig);
        setIsReadOnly(true);
      } catch (error) {
        // Should distinguish 400 errors in future
        Sentry.captureException(error);
        formikHelpers.setStatus('error');
      }
    },
    [configMutation],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isSubmitting, resetForm, status, setStatus }) => (
        <>
          {props.shouldShowInProgressWarning && (
            <Alert
              status="info"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              mb={12}
            >
              <AlertIcon />
              <AlertTitle my={2}>Processing right now</AlertTitle>
              <AlertDescription mb={2}>
                Changes will be reflected in next run
              </AlertDescription>
            </Alert>
          )}
          <Box>
            <Form>
              <VStack spacing={6} alignItems="start">
                {status === 'error' && (
                  <FormStatusAlert onClose={() => setStatus()}>
                    Failed to save changes. Try again later or contact support
                    if problem occurs again.
                  </FormStatusAlert>
                )}
                <MerchantFeedSettingsFormFields
                  affiliateNetworkSlug={props.affiliateNetworkSlug}
                  isReadOnly={isReadOnly}
                  // Name & country is not editable for now as API can't handle that case yet
                  disableEditingNameAndSellableIn
                  isEditingForm
                />
                <HStack pt={4} transition="none">
                  {!isReadOnly && (
                    <Button type="submit" isLoading={isSubmitting}>
                      Update
                    </Button>
                  )}
                  {!isSubmitting && (
                    <EditOrCancelButton
                      isReadOnly={isReadOnly}
                      isDisabled={isSubmitting}
                      startEdit={startEdit}
                      cancelEdit={() => {
                        cancelEdit();
                        resetForm();
                      }}
                    />
                  )}
                  {isReadOnly && (
                    <DeleteMerchantButton
                      rightIcon={<DeleteIcon />}
                      affiliateNetworkSlug={props.affiliateNetworkSlug}
                      merchantSlug={props.merchantSlug}
                    >
                      Delete Merchant
                    </DeleteMerchantButton>
                  )}
                </HStack>
              </VStack>
            </Form>
          </Box>
        </>
      )}
    </Formik>
  );
}
