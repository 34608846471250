import * as Yup from 'yup';
import { useMemo } from 'react';

import {
  AffiliateNetworkId,
  MerchantFeedConfig,
} from '../data/contracts/affiliateNetworksContracts';
import { countriesSellableIn } from '../utils/countriesSellableIn';
import { FormValues } from '../types/helpers';

import { TextField } from './Forms/TextField';
import { TextAreaField } from './Forms/TextAreaField';
import { NumberField } from './Forms/NumberField';
import { CheckboxField } from './Forms/CheckboxField';
import { SelectField } from './Forms/SelectField';

const countries = countriesSellableIn.map((code) => ({
  label: code,
  value: code,
}));

type ExcludedFeedConfigFields<
  TConfig extends MerchantFeedConfig,
  TKeys extends keyof MerchantFeedConfig = 'columnsMapping'
> = Omit<TConfig, TKeys>;

export type StandardFormValues = FormValues<
  ExcludedFeedConfigFields<MerchantFeedConfig>
>;
export type AwinFormValues = FormValues<
  ExcludedFeedConfigFields<MerchantFeedConfig<'AWIN'>>
>;

export type MerchantFeedSettingsFormValues =
  | StandardFormValues
  | AwinFormValues;

type MerchantFeedSettingsFormFieldsProps = {
  isReadOnly?: boolean;
  affiliateNetworkSlug: AffiliateNetworkId;
  disableEditingNameAndSellableIn?: boolean;
  isEditingForm?: boolean;
};

const commonValidationShape: Record<
  keyof StandardFormValues,
  Yup.BaseSchema
> = {
  name: Yup.string().required('Required').strict().trim(),
  displayName: Yup.string().required('Required').strict().trim(),
  url: Yup.string().required('Required'),
  networkParameterPrefix: Yup.string().nullable(),
  networkParameterSuffix: Yup.string().nullable(),
  deliveryTime: Yup.string().nullable(),
  deliveryCost: Yup.string().nullable(),
  currency: Yup.string().nullable(),
  lowerPriceThreshold: Yup.number().min(0).required('Required'),
  upperPriceThreshold: Yup.number().nullable(),
  merchantScoreAdjustment: Yup.number().min(0.1).max(2.0),
  officeDealerId: Yup.string().required('Required').strict().trim(),
  comment: Yup.string().nullable(),
  sellableIn: Yup.string()
    .required('Required')
    .oneOf(countriesSellableIn, 'Must be one of supported countries'),
  storeName: Yup.string().matches(/DE/),
};

function getValidationShape(affiliateNetworkSlug: AffiliateNetworkId) {
  switch (affiliateNetworkSlug) {
    case 'AWIN': {
      const shape: Record<keyof AwinFormValues, Yup.BaseSchema> = {
        ...commonValidationShape,
        useAwinCategories: Yup.boolean(),
      };
      return shape;
    }
    default:
      return commonValidationShape;
  }
}

export function useMerchantFeedSettingsValidationSchema(
  affiliateNetworkSlug: AffiliateNetworkId,
) {
  const validationSchema = useMemo(
    () => Yup.object().shape(getValidationShape(affiliateNetworkSlug)),
    [affiliateNetworkSlug],
  );
  return validationSchema;
}

export function MerchantFeedSettingsFormFields(
  props: MerchantFeedSettingsFormFieldsProps,
) {
  return (
    <>
      <TextField<StandardFormValues>
        name="name"
        label="Merchant name"
        isReadOnly={props.disableEditingNameAndSellableIn || props.isReadOnly}
        maxWidth="400px"
      />

      <TextField<StandardFormValues>
        name="displayName"
        label="Merchant display name"
        isReadOnly={props.isReadOnly}
        maxWidth="400px"
      />

      <TextField<StandardFormValues>
        name="url"
        label="Feed's CSV file URL"
        isReadOnly={props.isReadOnly}
      />
      {props.isEditingForm && (
        <TextField<StandardFormValues>
          name="networkParameterPrefix"
          label="Network Tracking Parameter (prefix)"
          isReadOnly={props.isReadOnly}
        />
      )}
      {props.isEditingForm && (
        <TextField<StandardFormValues>
          name="networkParameterSuffix"
          label="Network Tracking Parameter (suffix)"
          isReadOnly={props.isReadOnly}
        />
      )}
      {props.isEditingForm && (
        <TextField<StandardFormValues>
          name="deliveryTime"
          label="Delivery Time"
          isReadOnly={props.isReadOnly}
        />
      )}
      {props.isEditingForm && (
        <TextField<StandardFormValues>
          name="deliveryCost"
          label="Delivery Cost"
          isReadOnly={props.isReadOnly}
        />
      )}
      {props.isEditingForm && (
        <TextField<StandardFormValues>
          name="currency"
          label="Currency Symbol"
          isReadOnly={props.isReadOnly}
        />
      )}

      <NumberField<StandardFormValues>
        name="lowerPriceThreshold"
        label="Minimum Product Price"
        step={1}
        min={0}
        isReadOnly={props.isReadOnly}
        width="140px"
        isPercent={false}
      />
      <NumberField<StandardFormValues>
        name="upperPriceThreshold"
        label="Maximum Product Price"
        step={1}
        min={0}
        isReadOnly={props.isReadOnly}
        width="140px"
        isPercent={false}
      />
      <NumberField<StandardFormValues>
        name="merchantScoreAdjustment"
        label="Merchant Score Adjustment"
        step={0.1}
        min={0.1}
        max={2.0}
        isReadOnly={props.isReadOnly}
        width="140px"
        isPercent={false}
      />

      <TextField<StandardFormValues>
        name="officeDealerId"
        label="Office dealer id"
        isReadOnly={props.isReadOnly}
        maxWidth="400px"
      />
      <TextAreaField<StandardFormValues>
        name="comment"
        label="Comment"
        isReadOnly={props.isReadOnly}
      />
      <SelectField<StandardFormValues>
        name="sellableIn"
        label="Sellable in"
        placeholder="Choose country"
        options={countries}
        isReadOnly={props.disableEditingNameAndSellableIn || props.isReadOnly}
        maxWidth="400px"
      />
      {props.affiliateNetworkSlug === 'AWIN' && (
        <CheckboxField<AwinFormValues>
          name="useAwinCategories"
          label="Has AWIN standard categories"
          isReadOnly={props.isReadOnly}
        />
      )}
    </>
  );
}
