import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from '@chakra-ui/react';
import { useState, useCallback } from 'react';

import {
  useBlacklistedQuery,
  useBlacklistedDeleteMutation,
  useBlacklistedMutation,
} from '../../utils/fetchBlacklisted';

import { ConfirmationDialog } from './ConfirmationDialog';
import { NoBlacklistedAlert } from './NoBlacklistedAlert';
import { SettingsBlacklistedTable } from './SettingsBlacklistedTable';
import { BlacklistAddForm } from './BlacklistAddForm';
import { blacklisted } from './utils/settingsUtils';

export function SettingsBlacklisted() {
  const [tabIndex, setTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    isConfirmationModalOpen,
    setIsConfirmationModal,
  ] = useState<blacklisted | null>(null);

  const blacklistedDeleteMutation = useBlacklistedDeleteMutation();
  const query = useBlacklistedQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const blacklistedMutation = useBlacklistedMutation();

  const isListEmpty = query.status !== 'success' || query.data.length < 1;

  let onSubmitRemove = useCallback(() => {
    if (isConfirmationModalOpen !== null) {
      blacklistedDeleteMutation.mutateAsync({
        id: isConfirmationModalOpen.id,
      });
    }

    setIsConfirmationModal(null);
  }, [
    setIsConfirmationModal,
    blacklistedDeleteMutation,
    isConfirmationModalOpen,
  ]);

  const onRowRemove = useCallback(
    (blacklistedElement: blacklisted) => {
      setIsConfirmationModal(blacklistedElement);
    },
    [setIsConfirmationModal],
  );

  const onAddFormSubmit = useCallback(
    async (formData) => {
      return await blacklistedMutation.mutateAsync({
        productId: formData.id,
        name: formData.productName,
        comment: formData.comment,
      });
    },
    [blacklistedMutation],
  );

  const blacklistedTable = (
    <>
      {isListEmpty ? (
        <NoBlacklistedAlert />
      ) : (
        <SettingsBlacklistedTable
          tableElements={query.data || []}
          onRowRemove={onRowRemove}
        />
      )}
      <Button
        data-testid="blacklisted-products-add-new-btn"
        onClick={() => setIsModalOpen(true)}
        mt={6}
      >
        Add new blacklisted ID
      </Button>
      <BlacklistAddForm
        formType={{ kind: 'add' }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={onAddFormSubmit}
      ></BlacklistAddForm>
      <ConfirmationDialog
        isOpen={isConfirmationModalOpen !== null}
        isLoading={false}
        onSubmit={onSubmitRemove}
        onClose={() => setIsConfirmationModal(null)}
        headerText={'Remove it?'}
        bodyText={'Are you sure?'}
        submitBtnText={'Remove'}
        cancelBtnText={'No'}
      ></ConfirmationDialog>
    </>
  );

  return (
    <Box
      data-testid="blacklisted-products-section"
      width="100%"
      pt={4}
      px={6}
      h="100%"
    >
      <Tabs
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        isLazy
        h="100%"
        d="flex"
        flexDir="column"
      >
        <TabList data-testid="blacklisted-products-tab-list">
          <Tab>ID blacklist</Tab>
        </TabList>
        <TabPanels display="contents">
          <TabPanel data-testid="blacklisted-products-tab-panel">
            {blacklistedTable}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
