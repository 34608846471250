import { Button, Tooltip } from '@chakra-ui/react';

type AddNewColumnMappingButtonProps = {
  canAddNewColumnMapping: boolean;
  onClick: () => void;
};

export function AddNewColumnMappingButton(
  props: AddNewColumnMappingButtonProps,
) {
  return (
    <Tooltip
      isOpen={!props.canAddNewColumnMapping ? undefined : false}
      label="All supported columns are already mapped"
      // wrapper is needed as disabled button itself won't work with tooltip
      // see more at https://github.com/chakra-ui/chakra-ui/issues/500
      shouldWrapChildren
    >
      <Button
        onClick={props.onClick}
        isDisabled={!props.canAddNewColumnMapping}
        mt={6}
      >
        Add new column mapping
      </Button>
    </Tooltip>
  );
}
