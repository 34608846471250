import {
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { CloseIcon, Search2Icon } from '@chakra-ui/icons';
import { cloneElement, useRef } from 'react';
import Fuse from 'fuse.js';
import zip from 'lodash/zip';

import { ChakraComponentProps } from '../../types/helpers';

type Matches = [number, number][];

export const defaultFuseOptions = {
  ignoreLocation: true,
  includeMatches: true,
  threshold: 0.3,
  keys: ['0'],
};

export function useSearchTextFilter<TDatum>(
  data: TDatum[],
  searchText: string,
  fuseOptions?: Fuse.IFuseOptions<TDatum>,
): [TDatum[], Matches[]] {
  const textSearchRef = useRef<Fuse<TDatum> | null>(null);
  const lastDataRef = useRef(data);

  if (
    fuseOptions &&
    (!('includeMatches' in fuseOptions) || !fuseOptions.includeMatches)
  ) {
    throw new Error(
      `'includeMatches' option must be set to true when using 'useSearchTextFilter'`,
    );
  }

  if (!textSearchRef.current) {
    textSearchRef.current = new Fuse(data, fuseOptions ?? defaultFuseOptions);
  }

  if (data !== lastDataRef.current) {
    lastDataRef.current = data;
    textSearchRef.current.setCollection(data);
  }

  if (!searchText) {
    return [data, []];
  }

  const results = textSearchRef.current
    .search(searchText)
    .map((searchResult) => {
      const item = searchResult.item;
      const matchingIndices = searchResult.matches?.[0].indices ?? [];
      return [item, matchingIndices] as const;
    });

  const [items = [], matchingIndexes = []] = zip(...results) as [
    TDatum[],
    Matches[],
  ];

  return [items, matchingIndexes];
}

export const SearchTextInput = Input;

type SearchTextInputGroupProps = Omit<InputGroupProps, 'onChange'> & {
  value: string;
  onChange: ChakraComponentProps<typeof Input>['onChange'];
  onReset: () => void;
  input: React.ReactElement<ChakraComponentProps<typeof Input>>;
};

export function SearchTextInputGroup({
  value,
  onChange,
  onReset,
  input: passedInput,
  ...props
}: SearchTextInputGroupProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const input = cloneElement(passedInput, {
    ref: inputRef,
    value: value,
    onChange: onChange,
  });

  return (
    <InputGroup role="group" {...props}>
      <InputLeftElement pointerEvents="none">
        <Search2Icon color="gray.300" />
      </InputLeftElement>
      {input}
      {value && (
        <InputRightElement>
          <IconButton
            onClick={() => {
              inputRef.current?.focus();
              onReset();
            }}
            variant="ghost"
            size="xs"
            icon={<CloseIcon />}
            aria-label="reset"
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
