import * as Sentry from '@sentry/react';
import * as Severity from '@sentry/types/types/severity';

import { ErrorResponseResult } from './fetch';

export const sentryIntegrations = [
  new Sentry.Integrations.Breadcrumbs({
    // Covered in api fetch code
    fetch: false,
  }),
  new Sentry.Integrations.TryCatch({
    // Integrations below break dependent (with `enabled` prop) react-query queries
    // somehow and make them hang forever in tests.
    setInterval: false,
    setTimeout: false,
  }),
];

function getSerializedRequestBody(
  requestBody?: FormData | URLSearchParams | string | unknown,
) {
  if (requestBody instanceof FormData) {
    return JSON.stringify(Object.fromEntries(requestBody));
  } else if (requestBody instanceof URLSearchParams) {
    return requestBody.toString();
  } else if (typeof requestBody === 'string') {
    return requestBody;
  }

  return undefined;
}

type ApiBreadcrumbs = {
  url: string;
  method: string;
  status_code?: number;
  requestBody?: string;
  responseBody?: string;
};

function getBreadcrumbsData(
  url: string,
  response?: Response,
  errorResult?: ErrorResponseResult,
  requestInit?: RequestInit,
): ApiBreadcrumbs {
  let breadcrumbs: ApiBreadcrumbs = {
    url,
    method: requestInit?.method?.toUpperCase() ?? 'GET',
    status_code: response?.status,
  };

  if (!errorResult) {
    return breadcrumbs;
  }

  const requestBody = getSerializedRequestBody(requestInit?.body);
  if (requestBody) {
    breadcrumbs.requestBody = requestBody;
  }

  breadcrumbs.responseBody = errorResult.error;

  return breadcrumbs;
}

type AddSentryBreadcrumbArgs = {
  url: string;
  requestInit?: RequestInit;
  response?: Response;
  errorResult?: ErrorResponseResult;
  error?: Error;
};

export function addSentryApiBreadcrumb({
  url,
  requestInit,
  response,
  errorResult,
  error,
}: AddSentryBreadcrumbArgs) {
  let logLevel: Severity.SeverityLevel = 'info';

  if (error || !response || response.status >= 500) {
    logLevel = 'error';
  } else if (response.status >= 400) {
    logLevel = 'warning';
  }

  Sentry.addBreadcrumb({
    category: 'api',
    type: 'http',
    level: logLevel,
    data: getBreadcrumbsData(url, response, errorResult, requestInit),
    message: error?.stack ?? error?.toString(),
    timestamp: Date.now(),
  });
}
