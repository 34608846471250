import {
  Avatar,
  Box,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router';

type UserInfo = {
  name: string;
  email: string;
};

function useUserInfo() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserInfo | null | undefined>();

  useEffect(() => {
    if (!authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info: any) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  return userInfo;
}

export function UserMenu() {
  const userInfo = useUserInfo();
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();

  const logout = () => {
    oktaAuth.signOut();
  };
  const openSettings = () => {
    history.replace(`/settings/products-blacklisted`);
  };

  if (!userInfo) {
    return null;
  }

  return (
    <Grid
      templateColumns="max-content fit-content(200px)"
      alignItems="center"
      margin="4"
      gap={4}
    >
      <Box>
        <Menu>
          <MenuButton data-testid="user-actions-button">
            <Avatar name={userInfo.name} />
          </MenuButton>
          <MenuList data-testid="user-actions-menu">
            <MenuItem onClick={logout}>Logout</MenuItem>
            <MenuItem onClick={openSettings}>Settings</MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <Stack spacing="0">
        <Text fontWeight="bold">{userInfo.name}</Text>
        <Text>{userInfo.email}</Text>
      </Stack>
    </Grid>
  );
}
