import { useCallback } from 'react';
import * as Yup from 'yup';
import { Button, Flex } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';

import { TextAreaField } from '../../components/Forms/TextAreaField';
import { TextField } from '../../components/Forms/TextField';
import { FormStatusAlert } from '../../components/Forms/FormStatusAlert';
import { FormModal } from '../../components/Forms/FormModal';

export const ErrorAlert = (props: {
  onClose: () => void;
  errorStatus: string;
}) => (
  <FormStatusAlert onClose={props.onClose} mb={6}>
    {props.errorStatus === 'error409'
      ? `Failed to save changes, duplicated ID.`
      : ` Failed to save changes. Try again later or contact support if problem occurs
    again.`}
  </FormStatusAlert>
);

const FormActions = (props: {
  isSubmitting: boolean;
  onCancel: () => void;
}) => (
  <>
    <Button
      type="submit"
      isLoading={props.isSubmitting}
      mr={2}
      colorScheme="blue"
    >
      Save
    </Button>
    <Button
      onClick={props.onCancel}
      isDisabled={props.isSubmitting}
      variant="outline"
    >
      Cancel
    </Button>
  </>
);

type BlacklistFormValues = {
  id: string;
  productName: string;
  comment: string;
};

export type BlacklistFormType =
  | { kind: 'add' }
  | { kind: 'edit'; id: string; productName: string; comment: string };

type BlacklistFormProps = {
  formType: BlacklistFormType;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formValues: BlacklistFormValues) => void;
};

const initialValues: BlacklistFormValues = {
  id: '',
  comment: '',
  productName: '',
};
export function BlacklistAddForm({
  formType,
  isOpen,
  onClose,
  onSubmit,
}: BlacklistFormProps) {
  const validationShape: Record<keyof BlacklistFormValues, Yup.BaseSchema> = {
    id: Yup.string().required('Required').strict().trim(),
    productName: Yup.string().required('Required').strict().trim(),
    comment: Yup.string().required('Required').strict().trim(),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const submitHandler = useCallback(
    async (
      formData: BlacklistFormValues,
      formikHelpers: FormikHelpers<BlacklistFormValues>,
    ) => {
      try {
        formikHelpers.setStatus();
        await onSubmit(formData);

        onClose();
        formikHelpers.resetForm();
      } catch (error: any | unknown) {
        if (error?.status) {
          formikHelpers.setStatus(error.status === 409 ? 'error409' : 'error');
        } else {
          formikHelpers.setStatus('error');
        }
      }
    },
    [onSubmit, onClose],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={submitHandler}
    >
      {({ isSubmitting, resetForm, status, setStatus, values }) => (
        <FormModal
          isOpen={isOpen}
          onClose={onClose}
          contentWrapper={<Form aria-label="New blacklisted Element" />}
          header={'Add new blacklisted ID'}
          body={
            <>
              {(status === 'error409' || status === 'error') && (
                <ErrorAlert onClose={() => setStatus()} errorStatus={status} />
              )}

              <Flex
                flexDirection="column"
                flexWrap="nowrap"
                alignItems="flex-start"
                mt={6}
              >
                <TextField<BlacklistFormValues>
                  name="id"
                  label={`ID`}
                  isReadOnly={false}
                  title="ID"
                />
                <TextField<BlacklistFormValues>
                  name="productName"
                  label={`Product Name`}
                  isReadOnly={false}
                  title="productName"
                />
                <TextAreaField<BlacklistFormValues>
                  name="comment"
                  label={`Comment`}
                  isReadOnly={false}
                  title="Comment"
                />
              </Flex>
            </>
          }
          footer={
            <FormActions
              isSubmitting={isSubmitting}
              onCancel={() => {
                onClose();
                resetForm();
              }}
            />
          }
        />
      )}
    </Formik>
  );
}
