import { MerchantOverview } from '../../data/contracts/affiliateNetworksContracts';
import { StatusItem } from '../../components/StatusList';
import { MerchantProcessStatus } from '../../data/contracts/processStatus';
import { timeAgo } from '../../utils/dateTime';

type MerchantItemProps = Omit<
  React.ComponentProps<typeof StatusItem>,
  keyof MerchantOverview
> &
  MerchantOverview & {
    displayName: React.ReactNode;
  };

export const statusTexts: Record<MerchantProcessStatus, string> = {
  error: 'Failure',
  'in-progress': 'In progress',
  'in-queue': 'In progress',
  success: 'Success',
  warning: 'Needs attention',
  'initial-bootstrap': 'Initial setup',
  'initial-category-mapping': 'Needs attention',
};

function getMerchantSecondaryText(
  processingStatus: MerchantProcessStatus,
  lastUpdatedAt: number,
) {
  const statusText = statusTexts[processingStatus];

  if (processingStatus === 'in-progress' || processingStatus === 'in-queue') {
    return `${statusText}, updating now`;
  }

  if (processingStatus === 'initial-bootstrap') {
    return `${statusText}, processing now`;
  }

  const lastUpdateText = timeAgo(lastUpdatedAt);

  return `${statusText}, updated ${lastUpdateText}`;
}

export const MerchantItem = ({
  name,
  displayName,
  slug,
  affiliateNetwork: affiliateNetworkSlug,
  processingStatus,
  lastUpdatedAt,
  sellableIn,
  ...props
}: MerchantItemProps) => (
  <StatusItem
    data-testid="merchant-item"
    as="div"
    detailsPath={`/${affiliateNetworkSlug}/${slug}`}
    status={processingStatus}
    primaryText={displayName}
    secondaryText={getMerchantSecondaryText(processingStatus, lastUpdatedAt)}
    title={name}
    sideContent={sellableIn}
    {...props}
  />
);
