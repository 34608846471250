import { MappableColumn } from '../../../../data/contracts/columnsMapping';
import { AssertAllValuesAreUsed } from '../../../../types/helpers';

export const columnNameLabels = [
  {
    label: 'Category',
    value: 'category' as const,
  },
  { label: 'EAN', value: 'eanCandidate' as const },
  { label: 'Abstract SKU', value: 'abstractSku' as const },
  { label: 'UI image URL', value: 'imageUrl' as const },
  { label: 'LINK', value: 'link' as const },
  { label: 'NAME', value: 'name' as const },
  { label: 'PRICE', value: 'price' as const },
];

export function getLabelForColumn(columnName: MappableColumn) {
  return columnNameLabels.find(({ value }) => value === columnName)!.label;
}

const allMappableColumnsTuple = [
  'category',
  'eanCandidate',
  'abstractSku',
  'imageUrl',
  'link',
  'name',
  'price',
] as const;

export const allMappableColumns = (allMappableColumnsTuple as unknown) as string[];

type AreAllColumnNamesLabeled = AssertAllValuesAreUsed<
  MappableColumn,
  typeof columnNameLabels[number]['value']
>;

type AreAllMappableColumnsUsed = AssertAllValuesAreUsed<
  MappableColumn,
  typeof allMappableColumnsTuple[number]
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const areAllColumnNamesLabeled: AreAllColumnNamesLabeled = true as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const areAllMappableColumnsUsed: AreAllMappableColumnsUsed = true as const;
