import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useRef, ComponentProps } from 'react';
import * as Sentry from '@sentry/react';

import {
  AffiliateNetworkId,
  MerchantFeedConfig,
} from '../../../data/contracts/affiliateNetworksContracts';
import {
  activateColumnMapping,
  ActiveColumnMapping,
  areAllPossibleColumnMappingsApplied,
  ColumnsMapping,
  deactivateColumnMapping,
  isEmptyColumnsMapping,
} from '../../../data/contracts/columnsMapping';
import { useMerchantFeedConfigMutation } from '../../../utils/fetch';
import { useErrorToast } from '../../../utils/hooks/useErrorToast';

import { NoColumnsMappingAlert } from './NoColumnsMappingAlert';
import { ColumnsMappingTable } from './ColumnsMappingTable';
import { AddNewColumnMappingButton } from './AddNewColumnMappingButton';
import {
  RemoveColumnMappingDialog,
  useRemoveColumnMappingDialog,
} from './RemoveColumnMappingDialog';
import {
  ColumnMappingFormType,
  ColumnsMappingForm,
} from './ColumnsMappingForm';

function useColumnMappingFormDisclosure() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return {
    isColumnMappingFormOpen: isOpen,
    onColumnMappingFormOpen: onOpen,
    onColumnMappingFormClose: onClose,
  };
}

type MerchantColumnsMappingProps = {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
  columnsMapping: ColumnsMapping;
  merchantFeedConfig: MerchantFeedConfig;
};

export function MerchantColumnsMapping(props: MerchantColumnsMappingProps) {
  const {
    isColumnMappingFormOpen,
    onColumnMappingFormOpen,
    onColumnMappingFormClose,
  } = useColumnMappingFormDisclosure();

  const showErrorToast = useErrorToast(
    'Unable to save changes. Try again later or contact support if problem occurs again.',
  );

  const configMutation = useMerchantFeedConfigMutation(
    props.affiliateNetworkSlug,
    props.merchantSlug,
  );

  const columnMappingFormKindRef = useRef<ColumnMappingFormType>({
    kind: 'add',
  });

  const removeColumnMappingHandler = async (
    columnMapping: ActiveColumnMapping | null,
  ) => {
    try {
      if (!columnMapping) {
        throw new Error(
          'No column mapping to remove. Make sure that relevant column mapping ref is set before trying to remove it.',
        );
      }
      const { name } = columnMapping;

      const newColumnsMapping = props.columnsMapping.map((mapping) =>
        mapping.name === name ? deactivateColumnMapping(mapping) : mapping,
      ) as ColumnsMapping;

      await configMutation.mutateAsync({
        ...props.merchantFeedConfig,
        columnsMapping: newColumnsMapping,
      });
    } catch (exception) {
      // Should distinguish 400 errors in future
      Sentry.captureException(exception);
      showErrorToast();
    }
  };

  const {
    onOpenRemoveColumnMappingDialog,
    ...removeColumnDialogProps
  } = useRemoveColumnMappingDialog(removeColumnMappingHandler);

  const columnMappingSubmitHandler: ComponentProps<
    typeof ColumnsMappingForm
  >['onSubmit'] = useCallback(
    async ({ name, columns }) => {
      const newColumnsMapping = props.columnsMapping.map((columnMapping) =>
        columnMapping.name === name
          ? activateColumnMapping(columnMapping, columns)
          : columnMapping,
      ) as ColumnsMapping;

      await configMutation.mutateAsync({
        ...props.merchantFeedConfig,
        columnsMapping: newColumnsMapping,
      });
    },
    [configMutation, props.columnsMapping, props.merchantFeedConfig],
  );

  const canAddNewColumnMapping = !areAllPossibleColumnMappingsApplied(
    props.columnsMapping,
  );

  return (
    <>
      <ColumnsMappingForm
        formType={columnMappingFormKindRef.current}
        isOpen={isColumnMappingFormOpen}
        onClose={onColumnMappingFormClose}
        onSubmit={columnMappingSubmitHandler}
        columnsMapping={props.columnsMapping}
      />
      {isEmptyColumnsMapping(props.columnsMapping) ? (
        <NoColumnsMappingAlert />
      ) : (
        <ColumnsMappingTable
          columnsMapping={props.columnsMapping}
          onColumnMappingEdit={(columnMapping) => {
            columnMappingFormKindRef.current = {
              kind: 'edit',
              name: columnMapping.name,
              columns: columnMapping.columns,
            };
            onColumnMappingFormOpen();
          }}
          onColumnMappingRemove={(columnMapping) => {
            onOpenRemoveColumnMappingDialog(columnMapping);
          }}
        />
      )}
      <AddNewColumnMappingButton
        canAddNewColumnMapping={canAddNewColumnMapping}
        onClick={() => {
          columnMappingFormKindRef.current = { kind: 'add' };
          onColumnMappingFormOpen();
        }}
      />
      <RemoveColumnMappingDialog
        onOpenRemoveColumnMappingDialog={onOpenRemoveColumnMappingDialog}
        {...removeColumnDialogProps}
      />
    </>
  );
}
