import { Button } from '@chakra-ui/react';
import { useLayoutEffect, useState } from 'react';

import { ChakraComponentProps } from '../../../types/helpers';
import { AffiliateNetworkId } from '../../../data/contracts/affiliateNetworksContracts';
import { useMerchantCategoryMappingFile } from '../../../utils/fetch';

type DownloadCategoryMappingButtonProps = ChakraComponentProps<
  typeof Button
> & {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
};

export function DownloadCategoryMappingFileButtonInternal(
  props: DownloadCategoryMappingButtonProps,
) {
  let blobPromise = useMerchantCategoryMappingFile(
    props.affiliateNetworkSlug,
    props.merchantSlug,
  );

  useLayoutEffect(() => {
    const data = blobPromise.data;
    if (blobPromise.isSuccess && data) {
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = props.merchantSlug + '.csv';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    }
  }, [blobPromise.isSuccess, blobPromise.data, props.merchantSlug]);

  return <></>;
}

export function DownloadCategoryMappingFileButton(
  props: DownloadCategoryMappingButtonProps,
) {
  const [flag, setFlag] = useState(false);

  const handleClick = async () => {
    setFlag(true);
  };

  return (
    <>
      <Button variant="outline" onClick={handleClick}>
        Download
      </Button>
      {flag && (
        <DownloadCategoryMappingFileButtonInternal
          affiliateNetworkSlug={props.affiliateNetworkSlug}
          merchantSlug={props.merchantSlug}
        ></DownloadCategoryMappingFileButtonInternal>
      )}
    </>
  );
}
