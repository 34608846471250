import {
  Box,
  HStack,
  Flex,
  Button,
  Text,
  Skeleton,
  Divider,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import * as Sentry from '@sentry/react';

import {
  AffiliateNetworkId,
  CategoryMapping,
  Categories,
} from '../../../data/contracts/affiliateNetworksContracts';
import { EditOrCancelButton } from '../../../components/EditOrCancelButton';
import { useStateWithDebounce } from '../../../utils/hooks/useStateWithDebounce';
import { useCategoryMappingMutation } from '../../../utils/fetch';
import { useErrorToast } from '../../../utils/hooks/useErrorToast';

import {
  CategoryMappingList,
  CategoryMappingListRef,
} from './CategoryMappingList';
import { SortButtons, useSortConfig, useSorting } from './SortingByCategories';
import { useSearchFilter, SearchTextInput } from './MerchantCategoryTextSearch';
import { ShowUnmappedOnlySwitch, useFilterUnmapped } from './UnmappedFilter';
import { SprykerCategoryField } from './SprykerCategoryField';
import { UploadCategoryMappingFileButton } from './UploadCategoryMappingFileButton';
import { DownloadCategoryMappingFileButton } from './DownloadCategoryMappingFileButton';

export const CategoryMappingFormSkeleton = () => (
  <Flex flexDirection="column" h="100%" mx={-6}>
    <Flex wrap="wrap" boxShadow="md" zIndex={1} px={6} alignItems="center">
      <HStack width="100%">
        <Skeleton flexGrow={1} h={8} my={1} />
        <Skeleton w="60px" h={8} my={1} />
      </HStack>
      <Skeleton w="100%" h={6} mt={6} mb={4} />
    </Flex>
    <Box flexGrow={1}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((i) => (
        <Flex key={i} pos="relative" h="64px" alignItems="center" px={6}>
          <Skeleton w="100%" h={4} />
          <Divider pos="absolute" bottom={0} right={0} left={0} />
        </Flex>
      ))}
    </Box>
  </Flex>
);

const NoCategoriesInfo = (props: { text: string }) => (
  <HStack justifyContent="center" p={8}>
    <InfoOutlineIcon />
    <Text>{props.text}</Text>
  </HStack>
);

// Keeps order of original data when merging
function useMergeDataWithOriginalOrderKept(
  allData: CategoryMapping,
  filteredData: CategoryMapping,
  editedData: CategoryMapping,
) {
  return useMemo(() => {
    const data = { ...allData };

    for (const merchantCategory in allData) {
      const editedSprykerCategory = editedData[merchantCategory] ?? null;

      if (editedSprykerCategory !== null) {
        data[merchantCategory] = editedSprykerCategory;
      } else if (merchantCategory in filteredData) {
        data[merchantCategory] = filteredData[merchantCategory];
      } else {
        delete data[merchantCategory];
      }
    }

    return data;
  }, [allData, filteredData, editedData]);
}

type CategoryMappingFormProps = {
  data: CategoryMapping;
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
  categoryMapping: Categories;
};

export function CategoryMappingForm(props: CategoryMappingFormProps) {
  const listRef = useRef<CategoryMappingListRef>(null);
  const showErrorToast = useErrorToast(
    'Unable to save changes. Try again later or contact support if problem occurs again.',
  );

  const categoryMappingMutation = useCategoryMappingMutation(
    props.affiliateNetworkSlug,
    props.merchantSlug,
  );

  const [values, setValues] = useState<CategoryMapping>({});

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [showUnmappedOnly, setShowUnmappedOnly] = useState(false);
  const [
    searchText,
    debouncedSearchText,
    setSearchText,
    flushDebouncedSearchText,
  ] = useStateWithDebounce('');
  const [sortConfig, changeSortConfig] = useSortConfig();

  useEffect(() => {
    listRef.current?.scrollTo(0);
  }, [debouncedSearchText]);

  const allOrUnmappedData = useFilterUnmapped(props.data, showUnmappedOnly);
  const mergedData = useMergeDataWithOriginalOrderKept(
    props.data,
    allOrUnmappedData,
    values,
  );
  const sortedCategoryEntries = useSorting(mergedData, sortConfig);
  const [data, filterMatches] = useSearchFilter(
    sortedCategoryEntries,
    debouncedSearchText,
  );

  const submitHandler = useCallback(
    async (values: CategoryMapping, { resetForm }) => {
      try {
        await categoryMappingMutation.mutateAsync(values);
        setIsReadOnly(true);
        resetForm();
      } catch (error) {
        // Should distinguish 400 errors in future
        Sentry.captureException(error);
        showErrorToast();
      }
    },
    [categoryMappingMutation, showErrorToast],
  );

  const formik = useFormik({
    initialValues: {},
    onSubmit: submitHandler,
    enableReinitialize: true,
  });

  useEffect(() => {
    // Keeps values synced with formik.
    // This is necessary to keep already edited (but not yet saved) changes
    // while filtering or sorting - as new data will be calculated;
    // Workaround for this is to always merge data from API with saved values
    // before any transformation is applied.
    setValues(formik.values);
  }, [formik.values]);

  return (
    <Flex flexDirection="column" h="100%" mx={-6}>
      <FormikProvider value={formik}>
        <Box as={Form} display="contents">
          <Flex
            wrap="wrap"
            boxShadow="md"
            zIndex={1}
            px={6}
            alignItems="center"
          >
            <HStack width="100%">
              <SearchTextInput
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onReset={() => {
                  setSearchText('');
                  flushDebouncedSearchText();
                }}
              />
              <UploadCategoryMappingFileButton
                affiliateNetworkSlug={props.affiliateNetworkSlug}
                merchantSlug={props.merchantSlug}
              >
                Upload Config File
              </UploadCategoryMappingFileButton>
              TODO: https://360codelab.atlassian.net/browse/PAPI23-103
              <DownloadCategoryMappingFileButton
                affiliateNetworkSlug={props.affiliateNetworkSlug}
                merchantSlug={props.merchantSlug}
              ></DownloadCategoryMappingFileButton>
              {!isReadOnly && (
                <Button type="submit" mr={2} isLoading={formik.isSubmitting}>
                  Save
                </Button>
              )}
              <EditOrCancelButton
                isReadOnly={isReadOnly}
                startEdit={() => setIsReadOnly(false)}
                cancelEdit={() => {
                  setIsReadOnly(true);
                  formik.resetForm();
                }}
                isDisabled={formik.isSubmitting}
              />
            </HStack>
            <ShowUnmappedOnlySwitch
              isChecked={showUnmappedOnly}
              onChange={() =>
                setShowUnmappedOnly((previousValue) => !previousValue)
              }
              flexGrow={1}
              pt={4}
              pb={2}
            />
            <SortButtons
              flexGrow={1}
              justifyContent="flex-end"
              pt={4}
              pb={2}
              sortConfig={sortConfig}
              changeSortConfig={changeSortConfig}
            />
          </Flex>
          {/* This needs to be expanded to whole viewport hence flex hassle */}
          <Box flexGrow={1}>
            {data.length > 0 ? (
              <CategoryMappingList
                ref={listRef}
                data={data}
                isReadOnly={isReadOnly}
                Field={SprykerCategoryField}
                highlightedMatches={filterMatches}
                isSubmitting={formik.isSubmitting}
                categoryMapping={props.categoryMapping}
              />
            ) : (
              <NoCategoriesInfo
                text={
                  showUnmappedOnly
                    ? 'All categories are mapped'
                    : 'No categories found'
                }
              />
            )}
          </Box>
        </Box>
      </FormikProvider>
    </Flex>
  );
}
