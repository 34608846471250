import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { useMemo } from 'react';

import { CategoryMapping } from '../../../data/contracts/affiliateNetworksContracts';
import { ChakraComponentProps } from '../../../types/helpers';

export function useFilterUnmapped(
  data: CategoryMapping,
  showUnmappedOnly: boolean,
) {
  return useMemo(() => {
    if (!showUnmappedOnly) {
      return data;
    }

    const filteredData = { ...data };

    for (const merchantCategory in filteredData) {
      const hasSprykerCategory = !!filteredData[merchantCategory];

      if (hasSprykerCategory) {
        delete filteredData[merchantCategory];
      }
    }

    return filteredData;
  }, [data, showUnmappedOnly]);
}

type ShowUnmappedOnlySwitchProps = ChakraComponentProps<typeof FormControl> & {
  isChecked: ChakraComponentProps<typeof Switch>['isChecked'];
  onChange: ChakraComponentProps<typeof Switch>['onChange'];
};

export const ShowUnmappedOnlySwitch = ({
  isChecked,
  onChange,
  ...props
}: ShowUnmappedOnlySwitchProps) => (
  <FormControl width="auto" display="flex" alignItems="center" {...props}>
    <FormLabel htmlFor="showOnlyUnmapped" mb="0" cursor="pointer">
      Hide already mapped
    </FormLabel>
    <Switch id="showOnlyUnmapped" isChecked={isChecked} onChange={onChange} />
  </FormControl>
);
