import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
} from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';

import { ChakraComponentProps, KeysOfType } from '../../types/helpers';

type Props<TPropNames> = {
  name: TPropNames;
  label?: string;
  isReadOnly?: boolean;
  align?: 'left' | 'right';
};

type TextFieldProps<TPropNames> = Props<TPropNames> &
  Omit<ChakraComponentProps<typeof Input>, keyof Props<TPropNames>>;

export const TextField = <TFormValues extends {} = any>({
  name,
  label,
  isReadOnly,
  align = 'left',
  ...props
}: TextFieldProps<KeysOfType<TFormValues, string>>) => {
  const [field, meta] = useField<string>(name);
  const form = useFormikContext<TFormValues>();

  return (
    <FormControl
      isInvalid={!isReadOnly && !!meta.error && meta.touched}
      textAlign={align}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {!isReadOnly ? (
        <Input
          {...field}
          id={name}
          isDisabled={form.isSubmitting}
          {...props}
          textAlign={align}
        />
      ) : (
        <Text color="dimgrey" noOfLines={3}>
          {field.value}
        </Text>
      )}
      {!isReadOnly && (
        <FormErrorMessage
          justifyContent={align === 'right' ? 'flex-end' : 'flex-start'}
        >
          {meta.error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
