import { useToast } from '@chakra-ui/react';

export function useErrorToast(description: string) {
  return useToast({
    description,
    isClosable: true,
    status: 'error',
    position: 'bottom-right',
  });
}
