import { Box, Heading } from '@chakra-ui/react';

import { useAffiliateNetworksQuery } from '../../utils/fetch';
import { pluralize } from '../../utils/text';
import { StatusItem, StatusList } from '../../components/StatusList';

export function AffiliateNetworksSection() {
  const query = useAffiliateNetworksQuery({
    handleErrors: true,
    shouldRefetch: true,
  });

  return (
    <Box data-testid="affiliate-networks-section" p={6}>
      <Heading as="h4" size="md">
        Affiliate networks
      </Heading>
      <StatusList isLoading={!query.isFetched} mt={8}>
        {query.isFetched &&
          query.data!.map((item) => (
            <StatusItem
              key={item.slug}
              detailsPath={`/${item.slug}`}
              status={item.processingStatus}
              primaryText={item.name}
              secondaryText={`${item.merchantsNumber} ${pluralize(
                item.merchantsNumber,
                'merchant',
              )}`}
              title={item.name}
            />
          ))}
      </StatusList>
    </Box>
  );
}
