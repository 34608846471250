import debounce from 'lodash/debounce';
import { DebouncedFunc } from 'lodash';
import { useEffect, useRef } from 'react';

import { AnyFn } from '../../types/helpers';

export function useDebouncedHandler<T extends AnyFn>(
  handler: T,
  wait = 100,
): DebouncedFunc<T> {
  const ref = useRef<DebouncedFunc<T> | null>(null);

  if (!ref.current) {
    ref.current = debounce(handler, wait);
  }

  useEffect(() => {
    const debouncedHandler = debounce(handler, wait);
    ref.current = debouncedHandler;
    return () => {
      debouncedHandler.cancel();
    };
  }, [handler, wait]);

  return ref.current;
}
