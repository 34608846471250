import { Text, HStack } from '@chakra-ui/react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';

import { TextWithHighlights } from '../../components/TextWithHighlights';
import {
  AffiliateNetworkId,
  MerchantOverview,
} from '../../data/contracts/affiliateNetworksContracts';

import { MerchantItem } from './MerchantStatusItem';
import {
  useIsMerchantsSortingApplied,
  useMerchantsSorting,
} from './MerchantsSorting';
import {
  useIsCountryFilterApplied,
  useIsStatusFilterApplied,
  useMerchantsFilters,
} from './MerchantsFilters';
import {
  useIsMerchantsSearchApplied,
  useMerchantsSearchFilter,
} from './MerchantsSearch';

function useScrollToTopOnFiltersOrSortingChange(
  listRef: React.RefObject<FixedSizeList>,
) {
  const isSearchApplied = useIsMerchantsSearchApplied();
  const isCountryFilterApplied = useIsCountryFilterApplied();
  const isStatusFilterApplied = useIsStatusFilterApplied();
  const isSortingApplied = useIsMerchantsSortingApplied();

  const wasSearchAppliedRef = useRef(isSearchApplied);
  const wasCountryFilterAppliedRef = useRef(isCountryFilterApplied);
  const wasStatusFilterAppliedRef = useRef(isStatusFilterApplied);
  const wasSortingAppliedRef = useRef(isSortingApplied);

  const [isAnythingChanged, setIsAnythingChanged] = useState(false);

  useEffect(() => {
    const isSearchChanged = isSearchApplied !== wasSearchAppliedRef.current;
    const isCountryFilterChanged =
      isCountryFilterApplied !== wasCountryFilterAppliedRef.current;
    const isStatusFilterChanged =
      isStatusFilterApplied !== wasStatusFilterAppliedRef.current;
    const isSortingChanged = isSortingApplied !== wasSortingAppliedRef.current;

    setIsAnythingChanged(
      isSearchChanged ||
        isCountryFilterChanged ||
        isStatusFilterChanged ||
        isSortingChanged,
    );

    wasSearchAppliedRef.current = isSearchApplied;
    wasCountryFilterAppliedRef.current = isCountryFilterApplied;
    wasStatusFilterAppliedRef.current = isStatusFilterApplied;
    wasSortingAppliedRef.current = isSortingApplied;
  }, [
    isSearchApplied,
    isCountryFilterApplied,
    isStatusFilterApplied,
    isSortingApplied,
  ]);

  const onChangeResolved = useCallback(() => {
    wasSearchAppliedRef.current = isSearchApplied;
    wasCountryFilterAppliedRef.current = isCountryFilterApplied;
    wasStatusFilterAppliedRef.current = isStatusFilterApplied;
    wasSortingAppliedRef.current = isSortingApplied;

    setIsAnythingChanged(false);
  }, [
    isSearchApplied,
    isCountryFilterApplied,
    isStatusFilterApplied,
    isSortingApplied,
  ]);

  useEffect(() => {
    if (isAnythingChanged) {
      listRef.current?.scrollTo(0);
      onChangeResolved();
    }
  });
}

function scheduleScrollToMerchantItem(
  listRef: React.RefObject<FixedSizeList>,
  merchantSlug: string | undefined,
  merchants: MerchantOverview[],
) {
  const merchantIndex = merchants.findIndex(
    (merchant) => merchant.slug === merchantSlug,
  );

  if (merchantIndex !== -1) {
    setTimeout(() => {
      listRef.current?.scrollToItem(merchantIndex, 'start');
    }, 0);
  }
}

function useScrollToRelevantItemAfterRedirect(
  listRef: React.RefObject<FixedSizeList>,
  newMerchantRedirectSlug: string | undefined,
  merchants: MerchantOverview[],
) {
  const newMerchantRedirectHandleRef = useRef(false);

  useEffect(() => {
    const hasSyncedNewMerchant = newMerchantRedirectHandleRef.current;

    if (newMerchantRedirectSlug && !hasSyncedNewMerchant) {
      scheduleScrollToMerchantItem(listRef, newMerchantRedirectSlug, merchants);
    }
  }, [listRef, newMerchantRedirectSlug, merchants]);
}

function useScrollToRelevantItemOnMount(
  listRef: React.RefObject<FixedSizeList>,
  merchantSlug: string | undefined,
  merchants: MerchantOverview[],
) {
  const hasScrolledRef = useRef(false);

  useEffect(() => {
    if (!hasScrolledRef.current) {
      scheduleScrollToMerchantItem(listRef, merchantSlug, merchants);
      hasScrolledRef.current = true;
    }
  }, [listRef, merchantSlug, merchants]);
}

function useMerchantItemScrollSync(
  listRef: React.RefObject<FixedSizeList>,
  merchantSlug: string | undefined,
  newMerchantRedirectSlug: string | undefined,
  merchants: MerchantOverview[],
) {
  useScrollToTopOnFiltersOrSortingChange(listRef);
  useScrollToRelevantItemAfterRedirect(
    listRef,
    newMerchantRedirectSlug,
    merchants,
  );
  useScrollToRelevantItemOnMount(listRef, merchantSlug, merchants);
}

type MerchantsListProps = {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchants: MerchantOverview[];
  merchantSlug?: string;
  newMerchantRedirectSlug?: string;
};

export function MerchantsList(props: MerchantsListProps) {
  const sortedMerchants = useMerchantsSorting(props.merchants);
  const sortedAndFilteredMerchants = useMerchantsFilters(sortedMerchants);
  const [merchants, searchMatches] = useMerchantsSearchFilter(
    sortedAndFilteredMerchants,
  );

  const listRef = useRef<FixedSizeList>(null);

  useMerchantItemScrollSync(
    listRef,
    props.merchantSlug,
    props.newMerchantRedirectSlug,
    merchants,
  );

  if (merchants.length === 0) {
    return (
      <HStack justifyContent="center" p={8}>
        <InfoOutlineIcon />
        <Text>No merchants found</Text>
      </HStack>
    );
  }

  return (
    <AutoSizer>
      {({ width, height }) => (
        <FixedSizeList
          ref={listRef}
          width={width}
          height={height}
          itemSize={76}
          itemCount={merchants.length}
          itemKey={(index) => merchants[index].slug}
        >
          {({ index, style }) => (
            <MerchantItem
              pos={style.position}
              top={`${style.top}px`}
              left="0"
              right="0"
              mt={2}
              px={6}
              displayName={
                <TextWithHighlights
                  text={merchants[index].name}
                  highlightedMatch={searchMatches[index] ?? []}
                />
              }
              {...merchants[index]}
            />
          )}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}
