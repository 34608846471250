import { ReactNode, useRef, useState } from 'react';
import { Box, Divider, Grid } from '@chakra-ui/react';
import Draggable from 'react-draggable';
import { DragHandleIcon } from '@chakra-ui/icons';

import { UserMenu } from '../components/UserMenu';
import { ChakraComponentProps } from '../types/helpers';

type LayoutProps = {
  leftColumnContent: ReactNode;
  middleColumnContent?: ReactNode;
  rightColumnContent: ReactNode;
  isResizable?: boolean;
};

// Additional wrapper is added to extend column above the actual viewport
// to make box shadow stay consistent for whole height (would get more blurry
// near top otherwise).
const ColumnWithShadow: typeof Box = (props) => (
  <Box
    h="calc(100vh + 80px)"
    p="40px 0"
    m="-40px 0"
    boxShadow="xl"
    bg="white"
    {...props}
  />
);

const ColumnContent: typeof Box = (props) => (
  <Box pos="relative" overflow="auto" h="100vh" {...props} />
);

const ColumnDivider = () => (
  <Divider
    orientation="vertical"
    pos="absolute"
    top={0}
    bottom={0}
    right={0}
    borderLeftWidth={2}
  />
);

const minColumnWidth = 160;

const ColumnDragArea = (props: {
  setColumnWidth: (value: React.SetStateAction<number>) => void;
}) => {
  const draggableRef = useRef<HTMLDivElement>(null);

  const positionProps: ChakraComponentProps<typeof Box> = {
    pos: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
  };

  return (
    <>
      <DragHandleIcon pos="absolute" top={6} right={3} />
      <Draggable
        axis="x"
        nodeRef={draggableRef}
        onDrag={(e, data) => {
          props.setColumnWidth((previousWidth) =>
            Math.max(previousWidth + data.deltaX, minColumnWidth),
          );
        }}
        // Force area to stick to the edge after drag
        position={{ x: 0, y: 0 }}
      >
        <Box ref={draggableRef} {...positionProps} cursor="col-resize">
          <Box {...positionProps} width={4} />
          <Box pos="absolute" top={4} right={0} width={8} height={8} />
        </Box>
      </Draggable>
    </>
  );
};

export function Layout({
  leftColumnContent,
  middleColumnContent,
  rightColumnContent,
  isResizable,
}: LayoutProps) {
  const [leftColumnWith, setLeftColumnWidth] = useState(350);
  const [middleColumnWith, setMiddleColumnWidth] = useState(
    middleColumnContent === undefined ? 1000 : 380,
  );
  let templateColumns = middleColumnContent
    ? `${leftColumnWith}px ${middleColumnWith}px minmax(0, 3fr)`
    : `${leftColumnWith}px calc(100vw - ${leftColumnWith}px) minmax(0, 2fr)`;
  let isResizable_ = isResizable === undefined ? true : isResizable;

  return (
    <>
      <Grid templateColumns={templateColumns} maxH="100vh" overflow="hidden">
        <ColumnWithShadow zIndex="3">
          <ColumnContent
            as={Grid}
            templateRows="1fr max-content"
            templateColumns="100%"
          >
            <Box>{leftColumnContent}</Box>
            <UserMenu />
            <ColumnDivider />
            {isResizable_ && (
              <ColumnDragArea setColumnWidth={setLeftColumnWidth} />
            )}
          </ColumnContent>
        </ColumnWithShadow>
        {middleColumnContent && (
          <ColumnWithShadow zIndex="2">
            <ColumnContent>
              {middleColumnContent}
              <ColumnDivider />
              {isResizable_ && (
                <ColumnDragArea setColumnWidth={setMiddleColumnWidth} />
              )}
            </ColumnContent>
          </ColumnWithShadow>
        )}
        <ColumnWithShadow>
          <ColumnContent>{rightColumnContent}</ColumnContent>
        </ColumnWithShadow>
      </Grid>
    </>
  );
}
