import {
  Box,
  Button,
  Grid,
  HStack,
  IconButton,
  useDisclosure,
  Icon,
  Collapse,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MdTune } from '@react-icons/all-files/md/MdTune';

import {
  AffiliateNetworkId,
  MerchantOverview,
} from '../../data/contracts/affiliateNetworksContracts';

import { MerchantsSortMenu } from './MerchantsSorting';
import { CountryFilter, StatusFilter } from './MerchantsFilters';
import { MerchantsSearchInput } from './MerchantsSearch';

type FiltersPanelProps = {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchants: MerchantOverview[];
};

export function MerchantsActionsPanel(props: FiltersPanelProps) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box pb={4} boxShadow="md" zIndex={2}>
      <HStack mx={6}>
        <Button
          as={Link}
          to={`/${props.affiliateNetworkSlug}/new-merchant`}
          colorScheme="blue"
          flexGrow={3}
          maxWidth="400px"
        >
          Add new merchant
        </Button>
        <IconButton
          aria-label="filters and sorting"
          onClick={onToggle}
          icon={<Icon as={MdTune} />}
          flexGrow={1}
          maxWidth="100px"
        />
      </HStack>
      <Collapse in={isOpen} animateOpacity={false}>
        <Grid
          opacity={1}
          mt={4}
          mb={1}
          mx={6}
          templateAreas="
                       'search search'
                       'country country'
                       'status sort'"
          gridGap={2}
        >
          <MerchantsSearchInput gridArea="search" />
          <CountryFilter gridArea="country" merchants={props.merchants} />
          <StatusFilter gridArea="status" />
          <MerchantsSortMenu gridArea="sort" />
        </Grid>
      </Collapse>
    </Box>
  );
}
