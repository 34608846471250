import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SkeletonText,
} from '@chakra-ui/react';
import { useState } from 'react';

import {
  AffiliateNetworkId,
  MerchantFeedConfig,
  MerchantFeedStatus,
} from '../../data/contracts/affiliateNetworksContracts';
import {
  useMerchantFeedConfigQuery,
  useMerchantFeedStatusQuery,
  useMerchantsQuery,
} from '../../utils/fetch';
import { useDelayedLoader } from '../../utils/hooks/useDelayedLoader';

import {
  MerchantFeedStatusDetails,
  MerchantFeedStatusDetailsSkeleton,
} from './MerchantFeedStatusDetails';
import { MerchantFeedSettingsForm } from './MerchantFeedSettings';
import { MerchantCategoryMapping } from './MerchantCategoryMapping/MerchantCategoryMapping';
import { MerchantColumnsMapping } from './MerchantColumnsMapping/MerchantColumnsMapping';

type MerchantsFeedSectionProps = {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
};

export function MerchantsFeedSection(props: MerchantsFeedSectionProps) {
  const [tabIndex, setTabIndex] = useState(0);

  /*
   * This guarantees that on every mount (i.e. switching between merchants)
   * merchants query is refetched and in sync with merchant feed details
   */
  const merchantsQuery = useMerchantsQuery(props.affiliateNetworkSlug);

  const feedStatusQuery = useMerchantFeedStatusQuery(
    props.affiliateNetworkSlug,
    props.merchantSlug,
  );
  const feedConfigQuery = useMerchantFeedConfigQuery(
    props.affiliateNetworkSlug,
    props.merchantSlug,
  );

  const isLoading = !feedStatusQuery.isFetched || !feedConfigQuery.isFetched;
  const shouldShowSkeleton = useDelayedLoader(isLoading);

  if (isLoading) {
    if (!shouldShowSkeleton) {
      return null;
    }

    const name =
      feedStatusQuery.data?.name ??
      feedConfigQuery.data?.name ??
      merchantsQuery.data?.find(
        (merchant) => merchant.slug === props.merchantSlug,
      )?.name;

    const status =
      feedStatusQuery.data?.processingStatus ??
      merchantsQuery.data?.find(
        (merchant) => merchant.slug === props.merchantSlug,
      )?.processingStatus;

    return <MerchantFeedSectionSkeleton name={name} status={status} />;
  }

  const shouldShowCategoryMapping =
    feedStatusQuery.data?.processingStatus !== 'initial-bootstrap' &&
    (props.affiliateNetworkSlug !== 'AWIN' ||
      !(feedConfigQuery.data as MerchantFeedConfig<'AWIN'>)?.useAwinCategories);

  return (
    <Box
      data-testid="merchant-details-section"
      width="100%"
      pt={4}
      px={6}
      h="100%"
    >
      <Tabs
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        isLazy
        h="100%"
        d="flex"
        flexDir="column"
      >
        <TabList data-testid="merchant-feed-tab-list">
          <Tab>Status</Tab>
          <Tab>Settings</Tab>
          <Tab>Columns mapping</Tab>
          {shouldShowCategoryMapping && <Tab>Category mapping</Tab>}
        </TabList>

        {/*
          Skip wrapper and make particular tab panel a flex container's child
          by using display: 'contents'
        */}
        <TabPanels display="contents">
          <TabPanel data-testid="merchant-feed-status-tab-panel">
            <MerchantFeedStatusDetails
              feedStatus={feedStatusQuery.data!}
              goToCategoryMapping={() => setTabIndex(2)}
              affiliateNetworkSlug={props.affiliateNetworkSlug}
              merchantSlug={props.merchantSlug}
            />
          </TabPanel>
          <TabPanel pr={0} data-testid="merchant-feed-settings-tab-panel">
            <MerchantFeedSettingsForm
              affiliateNetworkSlug={props.affiliateNetworkSlug}
              merchantSlug={props.merchantSlug}
              config={feedConfigQuery.data! as any}
              shouldShowInProgressWarning={
                feedStatusQuery.data?.processingStatus === 'in-progress' ||
                feedStatusQuery.data?.processingStatus === 'initial-bootstrap'
              }
            />
          </TabPanel>
          <TabPanel data-testid="merchant-feed-columns-mapping-tab-panel">
            <MerchantColumnsMapping
              affiliateNetworkSlug={props.affiliateNetworkSlug}
              merchantSlug={props.merchantSlug}
              columnsMapping={feedConfigQuery.data!.columnsMapping}
              merchantFeedConfig={feedConfigQuery.data!}
            />
          </TabPanel>
          {shouldShowCategoryMapping && (
            // This panel needs to fill whole available size of viewport
            // hence the flex hassle in tabs
            <TabPanel
              flexGrow={1}
              pb={0}
              px={0}
              data-testid="merchant-feed-category-mapping-tab-panel"
            >
              <MerchantCategoryMapping
                affiliateNetworkSlug={props.affiliateNetworkSlug}
                merchantSlug={props.merchantSlug}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export function MerchantFeedSectionSkeleton(props: {
  name?: MerchantFeedStatus['name'];
  status?: MerchantFeedStatus['processingStatus'];
}) {
  return (
    <Box
      data-testid="merchant-details-section"
      width="100%"
      pt={4}
      px={6}
      h="100%"
    >
      <Tabs index={0} h="100%" d="flex" flexDir="column">
        <TabList>
          <Tab>
            <SkeletonText noOfLines={1} my="2">
              Status
            </SkeletonText>
          </Tab>
          <Tab>
            <SkeletonText noOfLines={1} my="2">
              Settings
            </SkeletonText>
          </Tab>
          <Tab>
            <SkeletonText noOfLines={1} my="2">
              Columns mapping
            </SkeletonText>
          </Tab>
          <Tab>
            <SkeletonText noOfLines={1} my="2">
              Category mapping
            </SkeletonText>
          </Tab>
        </TabList>

        <TabPanels display="contents">
          <TabPanel>
            <MerchantFeedStatusDetailsSkeleton
              name={props.name}
              status={props.status}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
