import React, { useMemo, useReducer } from 'react';

export type SortOrder = 'asc' | 'desc';

type SortConfig<TSortField> = {
  order: SortOrder;
  field: TSortField | null;
};

type ApplicableSortConfig<TSortField> = {
  order: SortOrder;
  field: TSortField;
};

export type Sort<TSortField> = SortConfig<TSortField> | null;

type SortChange<TSortField> =
  | null
  | SortConfig<TSortField>
  | Pick<SortConfig<TSortField>, 'order'>
  | Pick<SortConfig<TSortField>, 'field'>;

function sortReducer<TSortField>(
  state: Sort<TSortField>,
  change: SortChange<TSortField>,
): SortConfig<TSortField> | null {
  if (change === null) {
    return null;
  }

  return {
    field: 'field' in change ? change.field : state?.field ?? null,
    order: 'order' in change ? change.order : state?.order ?? 'asc',
  };
}

export function useSortConfig<TSortField extends string | number>(
  defaultSortConfig: Sort<TSortField> = null,
) {
  type SortReducer = React.Reducer<Sort<TSortField>, SortChange<TSortField>>;

  const [sortConfig, changeSortConfig] = useReducer<SortReducer>(
    sortReducer,
    defaultSortConfig,
  );
  return [sortConfig, changeSortConfig] as const;
}

export function isSortConfigApplicable<TSortField>(
  sortConfig: Sort<TSortField>,
): sortConfig is ApplicableSortConfig<TSortField> {
  return !!sortConfig && !!sortConfig.field;
}

type FieldExtractor<TSortField extends string | number, TData, TValue> = (
  field: TSortField,
  item: TData,
) => TValue;

function isKeyedData<
  TSortField extends string | number,
  TValue,
  TKeyedData extends { [key in TSortField]: TValue },
  TNotKeyedData
>(item: TKeyedData | TNotKeyedData, field: TSortField): item is TKeyedData {
  try {
    return field in item;
  } catch (error) {
    return false;
  }
}

type FieldComparator<TValue> = (a: TValue, b: TValue) => number;

export function sortData<
  TSortField extends string | number,
  TValue,
  TData = { [key in TSortField]: TValue }
>(args: {
  data: TData[];
  sortConfig: Sort<TSortField>;
  comparator?: FieldComparator<TValue>;
  fieldExtractor?: FieldExtractor<TSortField, TData, TValue>;
}) {
  const { data, sortConfig, comparator, fieldExtractor } = args;

  if (!isSortConfigApplicable(sortConfig)) {
    return data;
  }

  const field = sortConfig.field;

  return [...data].sort((itemA, itemB) => {
    const modifier = sortConfig.order === 'desc' ? -1 : 1;

    let valueA: TValue;
    let valueB: TValue;

    if (fieldExtractor) {
      valueA = fieldExtractor(field, itemA);
      valueB = fieldExtractor(field, itemB);
    } else if (isKeyedData(itemA, field) && isKeyedData(itemB, field)) {
      valueA = (itemA[field] as unknown) as TValue;
      valueB = (itemB[field] as unknown) as TValue;
    } else {
      throw new Error(
        `Either specify 'fieldExtractor' or use array elements that are accessible with '${field}' key`,
      );
    }

    if (comparator) {
      return modifier * comparator(valueA, valueB);
    }

    if (typeof valueA !== 'string' || typeof valueB !== 'string') {
      throw new Error(
        `Either specify 'comparator' or use string values for '${field}' key.`,
      );
    }

    return modifier * valueA.localeCompare(valueB);
  });
}

export function useSorting<
  TSortField extends string | number,
  TValue = string,
  TData = { [key in TSortField]: string }
>(args: {
  data: TData[];
  sortConfig: Sort<TSortField>;
  comparator?: FieldComparator<TValue>;
  fieldExtractor?: FieldExtractor<TSortField, TData, TValue>;
}) {
  const { data, sortConfig, comparator, fieldExtractor } = args;

  return useMemo(
    () => sortData({ data, sortConfig, fieldExtractor, comparator }),
    [data, sortConfig, fieldExtractor, comparator],
  );
}
