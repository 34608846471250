import { useCallback, useState } from 'react';

import { useDebouncedHandler } from './useDebouncedHandler';

export function useStateWithDebounce<T>(initialValue: T, wait = 100) {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  const setDebouncedValueDebouncedHandler = useDebouncedHandler(
    setDebouncedValue,
    wait,
  );

  const setBothValues: typeof setValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setDebouncedValueDebouncedHandler(newValue);
    },
    [setDebouncedValueDebouncedHandler],
  );

  const flush = setDebouncedValueDebouncedHandler.flush;

  return [value, debouncedValue, setBothValues, flush] as const;
}
