export type AffiliateNetworkStatus = 'success' | 'warning' | 'error';

export const allMerchantProcessStatuses = [
  'success',
  'warning',
  'error',
  'in-progress',
  'initial-bootstrap',
  'initial-category-mapping',
  'in-queue',
] as const;

export type MerchantProcessStatus = typeof allMerchantProcessStatuses[number];
