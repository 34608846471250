import React, { PropsWithChildren, useCallback } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { useAffiliateNetworksQuery, useMerchantsQuery } from './utils/fetch';
import { CriticalErrorFallback } from './CriticalErrorFallback';
import { oktaConfig } from './utils/config';
import { Login } from './sections/Login/Login';
import { Layout } from './layout/Layout';
import { AffiliateNetworksSection } from './sections/AffiliateNetworks/AffiliateNetworksSection';
import { MerchantsSection } from './sections/Merchants/MerchantsSection';
import { SettingsLeftColumn } from './sections/Settings/SettingsLeftColumn';
import {
  MerchantFeedSectionSkeleton,
  MerchantsFeedSection,
} from './sections/MerchantFeed/MerchantFeedSection';
import { NewMerchantSection } from './sections/NewMerchant/NewMerchantSection';
import { useDelayedLoader } from './utils/hooks/useDelayedLoader';
import { SettingsBlacklisted } from './sections/Settings/SettingsBlacklisted';
const oktaAuth = new OktaAuth(oktaConfig);

function AffiliateNetworkRedirect(props: { affiliateNetworkSlug?: string }) {
  const affiliateNetworksQuery = useAffiliateNetworksQuery({
    handleErrors: true,
    shouldRefetch: true,
  });

  const affiliateNetworkSlug =
    props.affiliateNetworkSlug ?? affiliateNetworksQuery.data?.[0].slug;

  const merchantsQuery = useMerchantsQuery(
    affiliateNetworkSlug,
    { handleErrors: true, shouldRefetch: true },
    {
      enabled: !!affiliateNetworkSlug,
    },
  );

  const merchantSlug = merchantsQuery.data?.[0]?.slug;

  if (
    affiliateNetworksQuery.status !== 'success' ||
    merchantsQuery.status !== 'success'
  ) {
    return null;
  }

  const redirectPath = `/${affiliateNetworkSlug}/${
    merchantSlug ?? 'new-merchant'
  }`;

  return <Redirect to={redirectPath} />;
}

function ValidAffiliateNetworkRouteGuard(
  props: PropsWithChildren<{ loader?: React.ReactNode }>,
) {
  const params = useParams<{ affiliateNetwork?: string }>();
  const affiliateNetworksQuery = useAffiliateNetworksQuery({
    handleErrors: true,
    shouldRefetch: true,
  });
  const shouldShowLoader = useDelayedLoader(
    affiliateNetworksQuery.status !== 'success' && !!props.loader,
  );

  if (affiliateNetworksQuery.status !== 'success') {
    return shouldShowLoader ? <>{props.loader}</> : null;
  }

  const isValidAffiliateNetwork =
    !!params.affiliateNetwork &&
    affiliateNetworksQuery.data
      .map((af) => af.slug)
      .includes(params.affiliateNetwork);

  if (!isValidAffiliateNetwork) {
    return <Redirect to={`/${affiliateNetworksQuery.data[0].slug}`} />;
  }

  return <>{props.children}</> ?? null;
}

function ValidMerchantRouteGuard(
  props: PropsWithChildren<{ loader?: React.ReactNode }>,
) {
  const params = useParams<{ affiliateNetwork: string; merchant: string }>();

  const merchantsQuery = useMerchantsQuery(params.affiliateNetwork, {
    handleErrors: true,
    shouldRefetch: true,
  });

  if (merchantsQuery.status !== 'success') {
    return props.loader ? <>{props.loader}</> : null;
  }

  const isValidMerchant = params.merchant
    ? merchantsQuery.data.map((m) => m.slug).includes(params.merchant)
    : false;

  if (!isValidMerchant) {
    const merchantSlug = merchantsQuery.data[0]?.slug;
    return (
      <Redirect
        to={`/${params.affiliateNetwork}/${merchantSlug ?? 'new-merchant'}`}
      />
    );
  }

  return <>{props.children}</> ?? null;
}

const MerchantsSectionWrapper = () => {
  const params = useParams<{ affiliateNetwork: string }>();

  const merchantRouteMatch = useRouteMatch<{
    affiliateNetwork: string;
    merchant: string;
  }>('/:affiliateNetwork/:merchant');

  const merchant = merchantRouteMatch?.params.merchant;

  const location = useLocation();

  const state = location.state as undefined | { newMerchantRedirect: string };
  const newMerchantRedirect = state?.newMerchantRedirect;

  return (
    <MerchantsSection
      affiliateNetworkSlug={params.affiliateNetwork}
      merchantSlug={merchant}
      newMerchantRedirectSlug={newMerchantRedirect}
    />
  );
};

const NewMerchantForm = () => {
  const params = useParams<{ affiliateNetwork: string }>();

  return <NewMerchantSection affiliateNetworkSlug={params.affiliateNetwork} />;
};

const MerchantDetailsWrapper = () => {
  const params = useParams<{ affiliateNetwork: string; merchant: string }>();

  return (
    <MerchantsFeedSection
      key={`${params.affiliateNetwork}-${params.merchant}`}
      affiliateNetworkSlug={params.affiliateNetwork}
      merchantSlug={params.merchant}
    />
  );
};

function SecureApp() {
  const merchantDetailsLoader = <MerchantFeedSectionSkeleton />;

  return (
    <Switch>
      <Route exact path="/" component={AffiliateNetworkRedirect} />
      <Route path="/settings">
        <Layout
          isResizable={false}
          leftColumnContent={<SettingsLeftColumn />}
          rightColumnContent={
            <Switch>
              <Route exact path="/settings/products-blacklisted">
                <SettingsBlacklisted />
              </Route>
            </Switch>
          }
        />
      </Route>
      <Route path="/:affiliateNetwork">
        <ValidAffiliateNetworkRouteGuard />
        <Layout
          leftColumnContent={<AffiliateNetworksSection />}
          middleColumnContent={<MerchantsSectionWrapper />}
          rightColumnContent={
            <Switch>
              <Route exact path="/:affiliateNetwork/new-merchant">
                <NewMerchantForm />
              </Route>
              <Route path="/:affiliateNetwork/:merchant">
                <ValidAffiliateNetworkRouteGuard loader={merchantDetailsLoader}>
                  <ValidMerchantRouteGuard />
                  <MerchantDetailsWrapper />
                </ValidAffiliateNetworkRouteGuard>
              </Route>
              <Route
                render={(props) => (
                  <ValidAffiliateNetworkRouteGuard
                    loader={merchantDetailsLoader}
                  >
                    {/*
                      Bare loader here is shown when we are switching between
                      affiliate networks after initial load - it covers case
                      when guard's loader is not shown as affiliate networks
                      are already loaded but merchant's loader is not visible
                      as links to affiliates are simply /:affiliateNetwork
                      so the redirect need to happen before merchant's loader
                      will pick up.
                    */}
                    {merchantDetailsLoader}
                    <AffiliateNetworkRedirect
                      affiliateNetworkSlug={props.match.params.affiliateNetwork}
                    />
                  </ValidAffiliateNetworkRouteGuard>
                )}
              />
            </Switch>
          }
        />
      </Route>

      <Redirect push to="/" />
    </Switch>
  );
}

export function AppRouter() {
  const history = useHistory();

  const customAuthHandler = useCallback(() => {
    history.push('/login');
  }, [history]);

  const restoreOriginalUri = useCallback(
    async (_oktaAuth, originalUri) => {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    },
    [history],
  );

  return (
    <ErrorBoundary fallback={CriticalErrorFallback}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <SecureRoute path="/">
            <SecureApp />
          </SecureRoute>
        </Switch>
      </Security>
    </ErrorBoundary>
  );
}
