import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Text,
  MenuButtonProps,
  InputGroupProps,
} from '@chakra-ui/react';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import React, { useMemo, useState } from 'react';

import {
  SupportedCountry,
  countriesSellableIn,
} from '../../utils/countriesSellableIn';
import { StatusIcon } from '../../components/StatusList';
import {
  allMerchantProcessStatuses,
  MerchantProcessStatus,
} from '../../data/contracts/processStatus';
import { MerchantOverview } from '../../data/contracts/affiliateNetworksContracts';
import {
  createContextWithoutDefaultValue,
  useDefinedContext,
} from '../../utils/hooks/useDefinedContext';
import { AutoCompleteInput } from '../../components/Forms/AutoCompleteInput';
import { TextWithHighlights } from '../../components/TextWithHighlights';

import { statusTexts } from './MerchantStatusItem';

const distinctStatusTexts: Record<MerchantProcessStatus, string> = {
  ...statusTexts,
  'initial-category-mapping': 'Needs initial category mapping',
};

type MerchantsFiltersContextValue = {
  countries: ReturnType<typeof useCountriesFilterCriteria>['0'];
  setCountries: ReturnType<typeof useCountriesFilterCriteria>['1'];
  isCountryFilterApplied: boolean;
  statuses: ReturnType<typeof useStatusFilterCriteria>['0'];
  setStatuses: ReturnType<typeof useStatusFilterCriteria>['1'];
  isStatusFilterApplied: boolean;
};

const MerchantsFiltersContext = createContextWithoutDefaultValue<MerchantsFiltersContextValue>();

export const MerchantsFiltersContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const [countries, setCountries] = useCountriesFilterCriteria();
  const [statuses, setStatuses] = useStatusFilterCriteria();

  const isCountryFilterApplied = countries.length > 0;
  const isStatusFilterApplied = statuses.length > 0;

  const value = useMemo(
    () => ({
      countries,
      setCountries,
      isCountryFilterApplied,
      statuses,
      setStatuses,
      isStatusFilterApplied,
    }),
    [
      countries,
      setCountries,
      isCountryFilterApplied,
      statuses,
      setStatuses,
      isStatusFilterApplied,
    ],
  );

  return (
    <MerchantsFiltersContext.Provider value={value}>
      {props.children}
    </MerchantsFiltersContext.Provider>
  );
};

function useCountriesFilterCriteria() {
  return useState<SupportedCountry[]>([]);
}

function useCountriesFilter(data: MerchantOverview[]) {
  const { countries, isCountryFilterApplied } = useDefinedContext(
    MerchantsFiltersContext,
  );

  return useMemo(
    () =>
      isCountryFilterApplied
        ? data.filter((overview) => countries.includes(overview.sellableIn))
        : data,
    [data, countries, isCountryFilterApplied],
  );
}

export function useIsCountryFilterApplied() {
  const { isCountryFilterApplied } = useDefinedContext(MerchantsFiltersContext);
  return isCountryFilterApplied;
}

function useStatusFilterCriteria() {
  return useState<MerchantProcessStatus[]>([]);
}

function useStatusFilter(data: MerchantOverview[]) {
  const { statuses, isStatusFilterApplied } = useDefinedContext(
    MerchantsFiltersContext,
  );

  return useMemo(
    () =>
      isStatusFilterApplied
        ? data.filter((overview) =>
            statuses.includes(overview.processingStatus),
          )
        : data,
    [data, statuses, isStatusFilterApplied],
  );
}

export function useIsStatusFilterApplied() {
  const { isStatusFilterApplied } = useDefinedContext(MerchantsFiltersContext);
  return isStatusFilterApplied;
}

export function useMerchantsFilters(data: MerchantOverview[]) {
  return useStatusFilter(useCountriesFilter(data));
}

type FilterMenuProps<TValue extends string> = MenuButtonProps & {
  isFilterApplied: boolean;
  buttonContent: React.ReactNode;
  reset: () => void;
  resetText: string;
  values: TValue[];
  setValues: (values: TValue[]) => void;
  options: { label: React.ReactNode; value: TValue }[];
};

function FilterMenu<TValue extends string>(props: FilterMenuProps<TValue>) {
  const {
    isFilterApplied,
    buttonContent,
    reset,
    resetText,
    values,
    setValues,
    options,
    ...menuButtonProps
  } = props;

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        variant={isFilterApplied ? 'solid' : 'outline'}
        rightIcon={
          isFilterApplied ? <CheckIcon color="blue.500" /> : <ChevronDownIcon />
        }
        {...menuButtonProps}
      >
        {buttonContent}
      </MenuButton>
      <MenuList maxH="50vh" overflow="auto">
        {isFilterApplied && (
          <>
            <MenuGroup>
              <MenuItem closeOnSelect icon={<></>} onClick={reset}>
                {resetText}
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
          </>
        )}
        <MenuOptionGroup
          type="checkbox"
          onChange={(newValues) => {
            setValues(newValues as TValue[]);
          }}
          value={values}
        >
          {options.map((option) => (
            <MenuItemOption key={option.value} value={option.value}>
              {option.label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

type CountryFilterProps = Partial<InputGroupProps> & {
  merchants: MerchantOverview[];
};

let displayNames: any;

if ('DisplayNames' in window.Intl) {
  const DisplayNames = (Intl as any).DisplayNames;
  displayNames = new DisplayNames(['en'], { type: 'region' });
}

const formatCountryName = (countryCode: SupportedCountry): string =>
  displayNames ? displayNames.of(countryCode) ?? countryCode : countryCode;

const countryOptions = countriesSellableIn
  .map((country) => ({
    label: formatCountryName(country as SupportedCountry),
    value: country as SupportedCountry,
  }))
  .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));

const OptionLabel = (props: {
  label: string;
  value: string;
  highlightedMatch: [number, number][];
}) => (
  <Flex alignItems="center">
    <Text flexGrow={1}>
      <TextWithHighlights
        text={props.label}
        highlightedMatch={props.highlightedMatch}
        minimumLettersMatchLength={1}
      />
    </Text>
    <Text ml={12} fontSize="sm" color="gray.600">
      {props.value}
    </Text>
  </Flex>
);

function getRelevantCountryOptions(merchants: MerchantOverview[]) {
  const relevantCountries = new Set(
    merchants.map((merchant) => merchant.sellableIn),
  );

  return countryOptions.filter((option) => relevantCountries.has(option.value));
}

export function CountryFilter({
  merchants,
  ...autoCompleteInputProps
}: CountryFilterProps) {
  const { countries, setCountries } = useDefinedContext(
    MerchantsFiltersContext,
  );

  const options = getRelevantCountryOptions(merchants);

  return (
    <AutoCompleteInput
      placeholder="Filter by country"
      options={options}
      OptionLabel={OptionLabel}
      values={countries}
      setValues={setCountries}
      noResultsText="No relevant countries found"
      {...autoCompleteInputProps}
    />
  );
}

type StatusFilterProps = MenuButtonProps;

const StatusOption = (props: { status: MerchantProcessStatus }) => (
  <Flex alignItems="center">
    <Text flexGrow={1} mr={4}>
      {distinctStatusTexts[props.status]}
    </Text>
    <StatusIcon status={props.status} />
  </Flex>
);

export function StatusFilter({ ...menuButtonProps }: StatusFilterProps) {
  const { statuses, setStatuses, isStatusFilterApplied } = useDefinedContext(
    MerchantsFiltersContext,
  );

  return (
    <FilterMenu<MerchantProcessStatus>
      isFilterApplied={isStatusFilterApplied}
      buttonContent="Status"
      reset={() => setStatuses([])}
      resetText="Remove all"
      values={statuses}
      setValues={(statuses) => setStatuses(statuses)}
      options={allMerchantProcessStatuses.map((status) => ({
        label: <StatusOption status={status} />,
        value: status,
      }))}
      {...menuButtonProps}
    />
  );
}
