import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
  HStack,
} from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';

import { ChakraComponentProps, KeysOfType } from '../../types/helpers';

type Props<TPropNames> = {
  name: TPropNames;
  label: string;
  isReadOnly?: boolean;
};

type CheckboxFieldProps<TPropNames> = Props<TPropNames> &
  Omit<ChakraComponentProps<typeof Checkbox>, keyof Props<TPropNames>>;

export const CheckboxField = <TFormValues extends {} = any>({
  name,
  label,
  isReadOnly,
  ...props
}: CheckboxFieldProps<KeysOfType<TFormValues, boolean>>) => {
  const [field, meta] = useField<boolean>(name);
  const form = useFormikContext<TFormValues>();

  return (
    <FormControl
      as={HStack}
      isInvalid={!isReadOnly && !!meta.error && meta.touched}
      my={2}
    >
      <Checkbox
        id={name}
        isChecked={field.value}
        {...field}
        isDisabled={isReadOnly || form.isSubmitting}
        value={field.value as any}
        {...props}
      />
      <FormLabel
        htmlFor={name}
        mb={0}
        _hover={{
          cursor: isReadOnly
            ? 'default'
            : form.isSubmitting
            ? 'not-allowed'
            : 'pointer',
        }}
      >
        {label}
      </FormLabel>
      {!isReadOnly && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  );
};
