import {
  AssertAllValuesAreUsed,
  isNonEmptyArray,
  NonEmptyArray,
} from '../../types/helpers';

export type RawColumnsMapping = {
  CATEGORY?: string[];
  EAN_CANDIDATE?: string[];
  ABSTRACT_SKU?: string[];
  IMAGE_URL?: string[];
  LINK?: string[];
  NAME?: string[];
  PRICE?: string[];
};

export type MappableColumn =
  | 'category'
  | 'eanCandidate'
  | 'abstractSku'
  | 'imageUrl'
  | 'link'
  | 'price'
  | 'name';

type ColumnMapping<
  Column extends MappableColumn,
  RawKey extends keyof RawColumnsMapping
> =
  | { status: 'not-active'; name: Column; rawKey: RawKey }
  | {
      status: 'active';
      name: Column;
      rawKey: RawKey;
      columns: NonEmptyArray<string>;
    };

export type ColumnsMapping = [
  ColumnMapping<'category', 'CATEGORY'>,
  ColumnMapping<'eanCandidate', 'EAN_CANDIDATE'>,
  ColumnMapping<'abstractSku', 'ABSTRACT_SKU'>,
  ColumnMapping<'imageUrl', 'IMAGE_URL'>,
  ColumnMapping<'link', 'LINK'>,
  ColumnMapping<'name', 'NAME'>,
  ColumnMapping<'price', 'PRICE'>,
];

export type ActiveColumnMapping = ColumnsMapping[number] & { status: 'active' };

type AreAllColumnsMapped = AssertAllValuesAreUsed<
  MappableColumn,
  ColumnsMapping[number]['name']
> &
  AssertAllValuesAreUsed<
    keyof RawColumnsMapping,
    ColumnsMapping[number]['rawKey']
  >;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const areAllColumnsMapped: AreAllColumnsMapped = true as const;

function transformRawCategory<
  Column extends MappableColumn,
  RawKey extends keyof RawColumnsMapping
>(
  rawKey: RawKey,
  column: Column,
  rawColumnsMapping: RawColumnsMapping,
): ColumnMapping<Column, RawKey> {
  if (rawColumnsMapping[rawKey]) {
    // Typescript chokes on narrowing generic parameter being possibly undefined
    // so let's force it manually.
    const rawMapping = rawColumnsMapping[rawKey] as string[];
    if (isNonEmptyArray(rawMapping)) {
      return { status: 'active', name: column, rawKey, columns: rawMapping };
    }
  }

  return { status: 'not-active', name: column, rawKey };
}

export function transformRawColumnsMapping(
  rawColumnsMapping: RawColumnsMapping,
): ColumnsMapping {
  return [
    transformRawCategory('CATEGORY', 'category', rawColumnsMapping),
    transformRawCategory('EAN_CANDIDATE', 'eanCandidate', rawColumnsMapping),
    transformRawCategory('ABSTRACT_SKU', 'abstractSku', rawColumnsMapping),
    transformRawCategory('IMAGE_URL', 'imageUrl', rawColumnsMapping),
    transformRawCategory('LINK', 'link', rawColumnsMapping),
    transformRawCategory('NAME', 'name', rawColumnsMapping),
    transformRawCategory('PRICE', 'price', rawColumnsMapping),
  ];
}

export function transformColumnsMappingToRaw(
  columnsMapping: ColumnsMapping,
): RawColumnsMapping {
  const rawColumnsMapping: RawColumnsMapping = {};
  columnsMapping.forEach((columnMapping) => {
    if (columnMapping.status === 'active') {
      rawColumnsMapping[columnMapping.rawKey] = columnMapping.columns;
    }
  });
  return rawColumnsMapping;
}

export function isEmptyColumnsMapping(columnsMapping: ColumnsMapping) {
  return columnsMapping.every((mapping) => mapping.status === 'not-active');
}

export function areAllPossibleColumnMappingsApplied(
  columnsMapping: ColumnsMapping,
) {
  return columnsMapping.every((mapping) => mapping.status === 'active');
}

export function activateColumnMapping(
  inactiveColumnMapping: ColumnsMapping[number],
  columns: NonEmptyArray<string>,
) {
  return { ...inactiveColumnMapping, status: 'active', columns };
}

export function deactivateColumnMapping(
  activeColumnMapping: ColumnsMapping[number],
) {
  const { rawKey, name } = activeColumnMapping;
  return { status: 'not-active', rawKey, name };
}
