import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Text,
} from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';
import { ReactNode } from 'react';

import { ChakraComponentProps, KeysOfType } from '../../types/helpers';

type Props<TPropNames> = {
  name: TPropNames;
  label: string;
  options: Array<{ label: ReactNode; value: string }>;
  isReadOnly?: boolean;
};

type SelectFieldProps<TPropNames> = Props<TPropNames> &
  Omit<ChakraComponentProps<typeof Select>, keyof Props<TPropNames>>;

export const SelectField = <TFormValues extends {} = any>({
  name,
  label,
  options,
  isReadOnly,
  ...props
}: SelectFieldProps<KeysOfType<TFormValues, string>>) => {
  const [field, meta] = useField<string>(name);
  const form = useFormikContext<TFormValues>();

  return (
    <FormControl isInvalid={!isReadOnly && !!meta.error && meta.touched}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {!isReadOnly ? (
        <Select {...field} isDisabled={form.isSubmitting} {...props}>
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      ) : (
        <Text color="dimgrey">
          {options.find((option) => option.value === field.value)?.label ??
            field.value}
        </Text>
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
