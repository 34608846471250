import { useRef } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
} from '@chakra-ui/react';

type ConfirmationDialogProps = {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
  headerText: string;
  bodyText: string;
  submitBtnText: string;
  cancelBtnText: string;
};

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const {
    headerText,
    bodyText,
    submitBtnText,
    cancelBtnText,
    isOpen,
    isLoading,
    onSubmit,
    onClose,
  } = props;
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      aria-label="remove"
    >
      <AlertDialogOverlay>
        <AlertDialogContent aria-label="confirm">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerText}
          </AlertDialogHeader>

          <AlertDialogBody>{bodyText}</AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme="red" onClick={onSubmit} isLoading={isLoading}>
              {submitBtnText}
            </Button>
            <Button
              ref={cancelRef}
              onClick={onClose}
              isDisabled={isLoading}
              variant="outline"
              ml={2}
            >
              {cancelBtnText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
