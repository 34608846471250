import { Flex, Button } from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon, UpDownIcon } from '@chakra-ui/icons';
import { useMemo, useReducer } from 'react';

import {
  assertUnreachable,
  ChakraComponentProps,
} from '../../../types/helpers';
import { CategoryMapping } from '../../../data/contracts/affiliateNetworksContracts';

import {
  CategoryKind,
  CategoryMappingEntry,
} from './MerchantCategoryMappingTypes';

type SortOrder = 'asc' | 'desc';
type SortField = CategoryKind;

type SortConfig<T> = {
  order: SortOrder;
  field: T;
} | null;

type CategoryMappingSortConfig = SortConfig<SortField>;

function sortReducer(
  state: CategoryMappingSortConfig,
  field: SortField,
): CategoryMappingSortConfig {
  if (field === state?.field) {
    if (state.order === 'asc') {
      return { order: 'desc', field };
    }

    return null;
  }

  return { order: 'asc', field };
}

export function useSortConfig() {
  const [sortConfig, changeSortConfig] = useReducer(sortReducer, null);
  return [sortConfig, changeSortConfig] as const;
}

export function sortData(
  data: CategoryMappingEntry[],
  sortConfig: CategoryMappingSortConfig,
) {
  if (sortConfig === null) {
    return data;
  }

  return [...data].sort(([merchantA, sprykerA], [merchantB, sprykerB]) => {
    const modifier = sortConfig.order === 'desc' ? -1 : 1;

    if (sortConfig.field === 'merchant') {
      return modifier * merchantA.localeCompare(merchantB);
    }
    if (sortConfig.field === 'spryker') {
      return modifier * (sprykerA ?? '').localeCompare(sprykerB ?? '');
    }
    return assertUnreachable(
      sortConfig.field,
      `Invalid sortConfig field: ${sortConfig.field}`,
    );
  });
}

export function useSorting(
  data: CategoryMapping,
  sortConfig: CategoryMappingSortConfig,
) {
  return useMemo(() => sortData(Object.entries(data), sortConfig), [
    data,
    sortConfig,
  ]);
}

type SortButtonProps = ChakraComponentProps<typeof Button> & {
  field: SortField;
  sortConfig: CategoryMappingSortConfig;
  changeSortConfig: (field: SortField) => void;
};

const SortIcon = (props: {
  field: SortField;
  sortConfig: CategoryMappingSortConfig;
}) => {
  if (props.sortConfig === null || props.sortConfig.field !== props.field) {
    return <UpDownIcon boxSize={3} />;
  }
  if (props.sortConfig.order === 'asc') {
    return <TriangleUpIcon boxSize={3} />;
  }
  if (props.sortConfig.order === 'desc') {
    return <TriangleDownIcon boxSize={3} />;
  }
  throw new Error(`Invalid sort icon state: ${JSON.stringify(props)}`);
};

const SortButton = ({
  field,
  sortConfig,
  changeSortConfig,
  children,
  ...props
}: SortButtonProps) => (
  <Button
    variant="ghost"
    fontWeight="bold"
    rightIcon={<SortIcon sortConfig={sortConfig} field={field} />}
    onClick={() => changeSortConfig(field)}
    {...props}
  >
    {children}
  </Button>
);

type SortButtonsProps = ChakraComponentProps<typeof Flex> & {
  sortConfig: CategoryMappingSortConfig;
  changeSortConfig: (field: SortField) => void;
};

export const SortButtons = ({
  sortConfig,
  changeSortConfig,
  ...props
}: SortButtonsProps) => (
  <Flex alignItems="center" {...props}>
    <SortButton
      field="merchant"
      sortConfig={sortConfig}
      changeSortConfig={changeSortConfig}
      mr="10px"
    >
      Merchant category
    </SortButton>
    <SortButton
      field="spryker"
      sortConfig={sortConfig}
      changeSortConfig={changeSortConfig}
      ml="50px"
      mr="120px"
    >
      Spryker category
    </SortButton>
  </Flex>
);
