import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { CloseIcon, Search2Icon } from '@chakra-ui/icons';
import { useRef, useMemo } from 'react';
import Fuse from 'fuse.js';
import zip from 'lodash/zip';

import { ChakraComponentProps } from '../../../types/helpers';

import { CategoryMappingEntry } from './MerchantCategoryMappingTypes';

type Matches = [number, number][];

function getResults(
  data: CategoryMappingEntry[],
  searchText: string,
  fuse: Fuse<CategoryMappingEntry>,
): [CategoryMappingEntry[], Matches[]] {
  if (!searchText) {
    return [data, []];
  }

  const results = fuse.search(searchText).map((searchResult) => {
    const item = searchResult.item;
    const matchingIndices = searchResult.matches?.[0].indices ?? [];
    return [item, matchingIndices] as const;
  });

  const [items = [], matchingIndexes = []] = zip(...results) as [
    CategoryMappingEntry[],
    Matches[],
  ];

  return [items, matchingIndexes];
}

export function useSearchFilter(
  data: CategoryMappingEntry[],
  searchText: string,
): [CategoryMappingEntry[], Matches[]] {
  const textSearchRef = useRef<Fuse<CategoryMappingEntry> | null>(null);
  const lastDataRef = useRef(data);

  if (!textSearchRef.current) {
    textSearchRef.current = new Fuse(data, {
      ignoreLocation: true,
      includeMatches: true,
      threshold: 0.3,
      keys: ['0'],
    });
  }

  if (data !== lastDataRef.current) {
    lastDataRef.current = data;
    textSearchRef.current.setCollection(data);
  }

  return useMemo(() => getResults(data, searchText, textSearchRef.current!), [
    data,
    searchText,
  ]);
}

type SearchTextInputProps = {
  value: string;
  onChange: ChakraComponentProps<typeof Input>['onChange'];
  onReset: () => void;
};

export const SearchTextInput = (props: SearchTextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <InputGroup role="group">
      <InputLeftElement pointerEvents="none">
        <Search2Icon color="gray.300" />
      </InputLeftElement>
      <Input
        ref={inputRef}
        value={props.value}
        onChange={props.onChange}
        width="280px"
        placeholder="Search merchant categories"
        flexGrow={props.value ? 1 : 0}
        _focusVisible={{
          flexGrow: 1,
        }}
      />
      {props.value && (
        <InputRightElement>
          <IconButton
            onClick={() => {
              inputRef.current?.focus();
              props.onReset();
            }}
            variant="ghost"
            size="xs"
            icon={<CloseIcon />}
            aria-label="reset"
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
