const minutesInMs = 60 * 1000;
const hourInMs = minutesInMs * 60;
const dayInMs = hourInMs * 24;

const formatter = new Intl.RelativeTimeFormat('en', {
  numeric: 'auto',
});

export type UnixTimestamp = number;

export function timeAgo(timestamp: UnixTimestamp) {
  if (!timestamp) {
    return '';
  }

  const msDiff = Date.now() - timestamp;

  let unit: Intl.RelativeTimeFormatUnit;
  let diff: number;

  if (msDiff < hourInMs) {
    unit = 'minute';
    diff = -Math.ceil(msDiff / minutesInMs);
  } else if (msDiff <= dayInMs) {
    unit = 'hour';
    diff = -Math.ceil(msDiff / hourInMs);
  } else {
    unit = 'day';
    diff = -Math.ceil(msDiff / dayInMs);
  }

  return formatter.format(diff, unit);
}

export function formatUnixTimestamp(
  dateInUnixTimestamp: UnixTimestamp,
): string {
  let date = new Date(dateInUnixTimestamp * 1000);

  return date.toLocaleDateString();
}
