import {
  Button,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { AffiliateNetworkId } from '../../data/contracts/affiliateNetworksContracts';
import { ChakraComponentProps } from '../../types/helpers';
import { useMerchantProcessingMutation } from '../../utils/fetch';

type TriggerMerchantProcessingButtonProps = ChakraComponentProps<
  typeof Button
> & {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
  isLoading?: boolean;
};

export function TriggerMerchantProcessingButton(
  props: TriggerMerchantProcessingButtonProps,
) {
  const {
    affiliateNetworkSlug,
    merchantSlug,
    isLoading = false,
    ...buttonProps
  } = props;

  const alertDisclosure = useDisclosure();
  const [hasScheduledProcessing, setHasScheduledProcessing] = useState(
    isLoading,
  );
  const cancelRef = useRef<HTMLButtonElement>(null);

  const merchantProcessingMutation = useMerchantProcessingMutation(
    affiliateNetworkSlug,
    merchantSlug,
  );

  const onConfirmClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    merchantProcessingMutation.mutateAsync();
    setHasScheduledProcessing(true);
    alertDisclosure.onClose();
    props.onClick?.(e);
  };

  return (
    <>
      <Tooltip
        isOpen={!hasScheduledProcessing ? false : undefined}
        label="Already processing"
        // wrapper is needed as disabled button itself won't work with tooltip
        // see more at https://github.com/chakra-ui/chakra-ui/issues/500
        shouldWrapChildren
      >
        <Button
          {...buttonProps}
          onClick={alertDisclosure.onOpen}
          isLoading={merchantProcessingMutation.status === 'loading'}
          isDisabled={hasScheduledProcessing}
        />
      </Tooltip>
      <AlertDialog
        isOpen={alertDisclosure.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertDisclosure.onClose}
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Trigger processing merchant feed
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure?
              <Text mt={6} fontSize="sm">
                {' '}
                All files are processed automatically. Use this only if you know
                that it is actually needed to run processing of merchant feed
                right now.
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onConfirmClick}>Trigger processing</Button>
              <Button
                ref={cancelRef}
                onClick={alertDisclosure.onClose}
                variant="outline"
                ml={3}
              >
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
