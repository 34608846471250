import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { useRouteMatch, Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';

export function SettingsLeftColumn() {
  return (
    <Box data-testid="affiliate-Settings" p={6}>
      <Heading as="h4" size="md" mb={6}>
        <Box as={Link} to={`/`} bgColor={'transparent'}>
          <ChevronLeftIcon /> System Settings
        </Box>
      </Heading>
      <Stack as={'ul'} spacing={2} aria-live="polite">
        <Box
          listStyleType="none"
          p={3}
          borderRadius="md"
          bgColor={
            useRouteMatch('/settings/products-blacklisted')
              ? 'gray.100'
              : 'transparent'
          }
          _hover={{
            bgColor: 'gray.100',
          }}
          as={Link}
          to={`/settings/products-blacklisted`}
        >
          <Text
            gridColumn="2/3"
            isTruncated
            fontSize="lg"
            lineHeight="shorter"
            fontWeight="bold"
          >
            Product blacklist
          </Text>
        </Box>
      </Stack>
    </Box>
  );
}
