import { Box, Grid, Text, Divider } from '@chakra-ui/layout';
import { ArrowForwardIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { forwardRef } from 'react';

import { Categories } from '../../../data/contracts/affiliateNetworksContracts';
import { ChakraComponentProps } from '../../../types/helpers';
import { TextWithHighlights } from '../../../components/TextWithHighlights';

import { CategoryMappingEntry } from './MerchantCategoryMappingTypes';

type CategoryMappingRowProps = ChakraComponentProps<typeof Grid> & {
  item: CategoryMappingEntry;
  isReadOnly: boolean;
  isDisabled: boolean;
  index: number;
  Field: React.ComponentType<{
    merchantCategory: string;
    sprykerCategory: string | null;
    isDisabled: boolean;
    categoryMapping: Categories;
  }>;
  highlightedMatch: [number, number][];
  categoryMapping: Categories;
};

export type CategoryMappingListRef = List;

const itemSize = 64;

const CategoryMappingRow = ({
  item,
  isReadOnly,
  isDisabled,
  index,
  Field,
  highlightedMatch,
  categoryMapping,
  ...props
}: CategoryMappingRowProps) => (
  <Box {...props} width="100%" height="auto">
    <Grid
      role="group"
      templateColumns="minmax(0, 1fr) 100px 160px"
      alignContent="center"
      alignItems="center"
      pos="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      height="64px"
      transition="height 0.1s"
      bgColor="white"
      _hover={{
        height: '100%',
        minHeight: '64px',
        boxShadow:
          // value from sm box shadow but twice - with normal and negative y offset
          '0 -1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      }}
    >
      <Text
        justifySelf="end"
        textAlign="right"
        gridColumn="1/2"
        whiteSpace="nowrap"
        _groupHover={{
          pl: 4,
          whiteSpace: 'normal',
        }}
      >
        <TextWithHighlights
          text={item[0]}
          highlightedMatch={highlightedMatch}
        />
      </Text>
      <ArrowForwardIcon gridColumn="2/3" justifySelf="center" mx={6} />
      <Box pr={'20px'} gridColumn="3/6" width={'320px'}>
        {isReadOnly ? (
          <Text pl="6px">
            {/* show icon when item is either `null` or `""` */}
            {item[1] || (
              <span aria-label="empty" title="empty">
                <NotAllowedIcon />
              </span>
            )}
          </Text>
        ) : (
          <Field
            key={item[0]}
            merchantCategory={item[0]}
            sprykerCategory={item[1]}
            isDisabled={isDisabled}
            categoryMapping={categoryMapping}
          />
        )}
      </Box>
      <Divider pos="absolute" bottom={0} right={0} left={0} />
    </Grid>

    {/* This is just a spacer that takes amount of space needed for unfolded text */}
    <Box
      aria-hidden
      visibility="hidden"
      // margin right mimics grid placement for real text
      mr="260px"
    >
      <Text textAlign="right" pl={4} py="20px">
        {item[0]}
      </Text>
    </Box>
  </Box>
);

type CategoryMappingListProps = {
  data: CategoryMappingRowProps['item'][];
  isReadOnly: boolean;
  isSubmitting: boolean;
  Field: CategoryMappingRowProps['Field'];
  highlightedMatches: CategoryMappingRowProps['highlightedMatch'][];
  categoryMapping: Categories;
};

export const CategoryMappingList = forwardRef<List, CategoryMappingListProps>(
  (props, ref) => (
    <AutoSizer>
      {({ width, height }) => (
        <List
          ref={ref}
          width={width}
          height={height}
          itemCount={props.data.length}
          itemSize={itemSize}
          itemKey={(index) => props.data[index][0]}
        >
          {({ index, style }) => (
            <CategoryMappingRow
              data-testid="category-mapping-row"
              item={props.data[index]}
              isReadOnly={props.isReadOnly}
              isDisabled={props.isSubmitting}
              index={index}
              Field={props.Field}
              highlightedMatch={props.highlightedMatches[index] ?? []}
              pos={style.position}
              top={`${style.top}px`}
              left={`${style.left}px`}
              categoryMapping={props.categoryMapping}
            />
          )}
        </List>
      )}
    </AutoSizer>
  ),
);
