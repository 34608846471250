import { OmitFields } from '../../types/helpers';
import { SupportedCountry } from '../../utils/countriesSellableIn';

import {
  ColumnsMapping,
  RawColumnsMapping,
  transformColumnsMappingToRaw,
  transformRawColumnsMapping,
} from './columnsMapping';
import { MerchantProcessStatus } from './processStatus';

type UnixTimestamp = number;

export type AffiliateNetworkId = 'AWIN' | 'CJ' | 'TDP' | string;

export type AffiliateNetworkOverview = {
  name: string;
  slug: AffiliateNetworkId | string;
  processingStatus: MerchantProcessStatus;
  merchantsNumber: number;
};

export type MerchantOverview = {
  name: string;
  slug: string;
  processingStatus: MerchantProcessStatus;
  lastUpdatedAt: UnixTimestamp;
  sellableIn: SupportedCountry;
  affiliateNetwork: AffiliateNetworkId;
};

type ProductFailure = {
  failureMessage: string;
  linesNumber: string;
};

export type OverviewKeys<T extends keyof MerchantOverview> = Extract<
  T,
  keyof MerchantOverview
>;

export type MerchantFeedStatus = Omit<
  MerchantOverview,
  OverviewKeys<'slug' | 'sellableIn' | 'affiliateNetwork'>
> & {
  lastSuccessfulUpdatedAt: UnixTimestamp | null;
  processedProductsCount: number;
  failedProductsCount: number;
  mostRecentResultsUrl?: string;
  error?: string;
  groupedProductFailures: ProductFailure[];
  groupedProductEnrichmentFailures: ProductFailure[];
  comment?: string;
};

type StandardMerchantFeedConfigBase = {
  name: string;
  displayName: string;
  url: string;
  networkParameterPrefix: string;
  networkParameterSuffix: string;
  storeName: string; // a country
  lowerPriceThreshold: number;
  upperPriceThreshold: number | null;
  merchantScoreAdjustment: number;
  officeDealerId: string;
  sellableIn: string;
  deliveryCost: string;
  deliveryTime: string;
  currency: string;
  comment: string;
};

type StandardMerchantRawFeedConfig = StandardMerchantFeedConfigBase & {
  columnsMapping: RawColumnsMapping;
};

type AwinRawMerchantFeedConfig = StandardMerchantRawFeedConfig & {
  useAwinCategories: boolean;
};
type CjRawMerchantFeedConfig = StandardMerchantRawFeedConfig;
type TradeDoublerRawMerchantFeedConfig = StandardMerchantRawFeedConfig;

type StandardMerchantFeedConfig = StandardMerchantFeedConfigBase & {
  columnsMapping: ColumnsMapping;
};

type AwinMerchantFeedConfig = StandardMerchantFeedConfig & {
  useAwinCategories: boolean;
};
type CjMerchantFeedConfig = StandardMerchantFeedConfig;
type TradeDoublerMerchantFeedConfig = StandardMerchantFeedConfig;

export function transformToMerchantFeedRawConfigPartial(
  merchantFeedConfig: Partial<MerchantFeedConfig>,
): Partial<MerchantFeedRawConfig> {
  const { columnsMapping, ...config } = merchantFeedConfig;

  if (!columnsMapping) {
    return config;
  }

  return {
    ...config,
    columnsMapping: transformColumnsMappingToRaw(columnsMapping),
  };
}

export function transformToMerchantFeedConfig(
  rawMerchantFeedConfig: MerchantFeedRawConfig,
) {
  return {
    ...rawMerchantFeedConfig,
    columnsMapping: transformRawColumnsMapping(
      rawMerchantFeedConfig.columnsMapping,
    ),
  };
}

export type MerchantFeedRawConfig<
  TAffiliateNetworkId extends AffiliateNetworkId | unknown = unknown
> = TAffiliateNetworkId extends 'AWIN'
  ? AwinRawMerchantFeedConfig
  : TAffiliateNetworkId extends 'CJ'
  ? CjRawMerchantFeedConfig
  : TAffiliateNetworkId extends 'TDP'
  ? TradeDoublerRawMerchantFeedConfig
  : StandardMerchantRawFeedConfig;

export type MerchantFeedConfig<
  TAffiliateNetworkId extends AffiliateNetworkId | unknown = unknown
> = TAffiliateNetworkId extends 'AWIN'
  ? AwinMerchantFeedConfig
  : TAffiliateNetworkId extends 'CJ'
  ? CjMerchantFeedConfig
  : TAffiliateNetworkId extends 'TDP'
  ? TradeDoublerMerchantFeedConfig
  : StandardMerchantFeedConfig;

export type NewMerchantFeedConfig =
  | OmitFields<StandardMerchantFeedConfig, 'columnsMapping'>
  | OmitFields<AwinMerchantFeedConfig, 'columnsMapping'>;

export type CategoryMapping = { [merchantCategory: string]: string | null };

export type Categories = {
  [key: string]: string;
};
