import { AffiliateNetworkId } from '../../../data/contracts/affiliateNetworksContracts';
import {
  useMerchantCategoryMappingQuery,
  useCategoryMappingQuery,
} from '../../../utils/fetch';
import { useDelayedLoader } from '../../../utils/hooks/useDelayedLoader';

import {
  CategoryMappingForm,
  CategoryMappingFormSkeleton,
} from './CategoryMappingForm';

type MerchantCategoryMappingProps = {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
};

export function MerchantCategoryMapping(props: MerchantCategoryMappingProps) {
  const query = useMerchantCategoryMappingQuery(
    props.affiliateNetworkSlug,
    props.merchantSlug,
    { refetchOnReconnect: false, refetchOnWindowFocus: false, cacheTime: 0 },
  );
  const categoryMappingQuery = useCategoryMappingQuery();

  const isSkeletonVisible = useDelayedLoader(query.status !== 'success');

  if (isSkeletonVisible) {
    return <CategoryMappingFormSkeleton />;
  }

  if (query.status !== 'success') {
    return null;
  }
  return (
    <CategoryMappingForm
      data={query.data}
      affiliateNetworkSlug={props.affiliateNetworkSlug}
      merchantSlug={props.merchantSlug}
      categoryMapping={categoryMappingQuery.data}
    />
  );
}
