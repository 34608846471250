import {
  Box,
  ComponentWithAs,
  Grid,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react';
import {
  WarningIcon,
  CheckCircleIcon,
  RepeatClockIcon,
} from '@chakra-ui/icons';
import VisuallyHidden from '@chakra-ui/visually-hidden';
import { Skeleton, SkeletonCircle } from '@chakra-ui/skeleton';
import { Link, useRouteMatch } from 'react-router-dom';

import {
  AffiliateNetworkStatus,
  MerchantProcessStatus,
} from '../data/contracts/processStatus';
import { useDelayedLoader } from '../utils/hooks/useDelayedLoader';
import { assertUnreachable, ChakraComponentProps } from '../types/helpers';

export const StatusItemSkeleton = (
  props: ChakraComponentProps<typeof Grid>,
) => (
  <Grid
    templateRows="1fr 1fr"
    templateColumns="min-content auto"
    alignItems="center"
    p={3}
    aria-busy="true"
    aria-valuetext="loading"
    {...props}
  >
    <Box role="status" gridRow="1/3" py={2} pr={5} pl={0}>
      <SkeletonCircle size="4" />
    </Box>
    <Skeleton h="4" mt="1.5" />
    <Skeleton h="2" mt="1.5" width="60%" />
  </Grid>
);

type StatusItemProps = ChakraComponentProps<typeof Box> & {
  detailsPath: string;
  status: AffiliateNetworkStatus | MerchantProcessStatus;
  primaryText: React.ReactNode;
  secondaryText: React.ReactNode;
  title: string;
  sideContent?: React.ReactNode;
};

export function StatusIcon(props: {
  status: AffiliateNetworkStatus | MerchantProcessStatus;
}) {
  if (props.status === 'error') {
    return <WarningIcon color="red.500" />;
  }

  if (
    props.status === 'warning' ||
    props.status === 'initial-category-mapping'
  ) {
    return <WarningIcon color="yellow.500" />;
  }

  if (props.status === 'success') {
    return <CheckCircleIcon color="green.500" />;
  }
  if (
    props.status === 'in-progress' ||
    props.status === 'initial-bootstrap' ||
    props.status === 'in-queue'
  ) {
    return <RepeatClockIcon />;
  }

  return assertUnreachable(props.status);
}

export const StatusItem = ({
  detailsPath,
  status,
  primaryText,
  secondaryText,
  title,
  sideContent,
  ...props
}: StatusItemProps) => {
  const isActiveItem = useRouteMatch(detailsPath);

  return (
    <Box as="li" listStyleType="none" {...props}>
      <Grid
        as={Link}
        to={detailsPath}
        templateRows="1fr 1fr"
        templateColumns={`min-content 1fr ${sideContent ? 'min-content' : ''}`}
        alignItems="center"
        p={3}
        bgColor={isActiveItem ? 'gray.100' : 'transparent'}
        borderRadius="md"
        _hover={{
          bgColor: 'gray.100',
        }}
      >
        <Box role="status" gridRow="1/3" py={2} pr={5} pl={0}>
          <VisuallyHidden>{status}</VisuallyHidden>
          <StatusIcon status={status} />
        </Box>
        <Text
          gridColumn="2/3"
          isTruncated
          fontSize="lg"
          lineHeight="shorter"
          fontWeight="bold"
          title={title}
        >
          {primaryText}
        </Text>
        <Text gridColumn="2/3" isTruncated fontSize="sm" lineHeight="shorter">
          {secondaryText}
        </Text>
        {sideContent && (
          <Text
            gridRow="1/3"
            gridColumn="3/4"
            ml={2}
            fontSize="sm"
            color="gray.600"
          >
            {sideContent}
          </Text>
        )}
      </Grid>
    </Box>
  );
};

export const StatusList: ComponentWithAs<
  'div',
  StackProps & { isLoading: boolean; skeletonItemsCount?: number }
> = (props) => {
  const { isLoading, skeletonItemsCount = 10, ...stackProps } = props;

  const areSkeletonsShown = useDelayedLoader(isLoading);

  return (
    <Stack
      as={props.isLoading ? 'ul' : 'div'}
      spacing={2}
      aria-live="polite"
      {...stackProps}
    >
      {areSkeletonsShown &&
        new Array(skeletonItemsCount)
          .fill(null)
          .map((_, i) => <StatusItemSkeleton key={i} />)}
      {props.children}
    </Stack>
  );
};
