import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  VisuallyHidden,
  IconButton,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

import {
  ActiveColumnMapping,
  ColumnsMapping,
} from '../../../data/contracts/columnsMapping';

import { getLabelForColumn } from './utils/columnNames';

type ColumnMappingActionsProps = {
  onEdit: () => void;
  onRemove: () => void;
};

const ColumnMappingActions = (props: ColumnMappingActionsProps) => (
  <>
    <IconButton
      onClick={props.onEdit}
      aria-label="edit"
      icon={<EditIcon />}
      variant="ghost"
    />
    <IconButton
      onClick={props.onRemove}
      aria-label="remove"
      icon={<DeleteIcon />}
      variant="ghost"
    />
  </>
);

type ColumnsMappingTableProps = {
  columnsMapping: ColumnsMapping;
  onColumnMappingEdit: (columnMapping: ActiveColumnMapping) => void;
  onColumnMappingRemove: (columnMapping: ActiveColumnMapping) => void;
};

export function ColumnsMappingTable(props: ColumnsMappingTableProps) {
  return (
    <Table title="columns mapping">
      <Thead>
        <Tr>
          <Th>Type of column in result file</Th>
          <Th>
            Columns in feed CSV (in relevance order)
            <Text fontSize="xx-small">
              value from the leftmost column will be tried first with following
              fallbacks to the right
            </Text>
          </Th>
          <Th>
            <VisuallyHidden>actions</VisuallyHidden>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.columnsMapping.map((columnMapping) =>
          columnMapping.status === 'active' ? (
            <Tr title="column mapping" key={columnMapping.name}>
              <Td>{getLabelForColumn(columnMapping.name)}</Td>
              <Td>{columnMapping.columns.join('; ')}</Td>
              <Td whiteSpace="nowrap">
                <ColumnMappingActions
                  onEdit={() => props.onColumnMappingEdit(columnMapping)}
                  onRemove={() => props.onColumnMappingRemove(columnMapping)}
                />
              </Td>
            </Tr>
          ) : null,
        )}
      </Tbody>
    </Table>
  );
}
