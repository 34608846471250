import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  MenuButtonProps,
} from '@chakra-ui/react';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { memo, PropsWithChildren, useMemo } from 'react';

import { MerchantProcessStatus } from '../../data/contracts/processStatus';
import {
  MerchantOverview,
  OverviewKeys,
} from '../../data/contracts/affiliateNetworksContracts';
import {
  isSortConfigApplicable,
  SortOrder,
  useSortConfig,
  useSorting,
} from '../../utils/hooks/useSorting';
import {
  createContextWithoutDefaultValue,
  useDefinedContext,
} from '../../utils/hooks/useDefinedContext';

const statusSortingValues: Record<MerchantProcessStatus, number> = {
  error: 10,
  warning: 9,
  'initial-category-mapping': 8,
  'initial-bootstrap': 7,
  'in-progress': 6,
  'in-queue': 6,
  success: 5,
};

function processingStatusComparator(
  a: MerchantProcessStatus,
  b: MerchantProcessStatus,
) {
  const valueA = statusSortingValues[a];
  const valueB = statusSortingValues[b];

  return valueB - valueA;
}

export type SortKey = OverviewKeys<'name' | 'processingStatus' | 'sellableIn'>;

type SortConfigContextValue = readonly [
  ReturnType<typeof useMerchantSortConfig>['0'],
  ReturnType<typeof useMerchantSortConfig>['1'],
];

const SortConfigContext = createContextWithoutDefaultValue<SortConfigContextValue>();

function useMerchantSortConfig() {
  return useSortConfig<SortKey>();
}

export function useMerchantsSorting(data: MerchantOverview[]) {
  const [sortConfig] = useDefinedContext(SortConfigContext);

  return useSorting({
    data,
    sortConfig,
    comparator:
      sortConfig?.field === 'processingStatus'
        ? processingStatusComparator
        : undefined,
  });
}

export function useIsMerchantsSortingApplied() {
  const [sortConfig] = useDefinedContext(SortConfigContext);
  return isSortConfigApplicable(sortConfig);
}

export const SortConfigContextProvider = (props: PropsWithChildren<{}>) => {
  const [sortConfig, setSortConfig] = useMerchantSortConfig();

  const value = useMemo(() => [sortConfig, setSortConfig] as const, [
    sortConfig,
    setSortConfig,
  ]);

  return (
    <SortConfigContext.Provider value={value}>
      {props.children}
    </SortConfigContext.Provider>
  );
};

type MerchantsSortMenuProps = MenuButtonProps;

function MerchantsSortMenu({ ...menuButtonProps }: MerchantsSortMenuProps) {
  const [sortConfig, changeSortConfig] = useDefinedContext(SortConfigContext);

  return (
    <Menu closeOnSelect={false} isLazy={true}>
      <MenuButton
        as={Button}
        variant={sortConfig ? 'solid' : 'outline'}
        rightIcon={
          !!sortConfig ? <CheckIcon color="blue.500" /> : <ChevronDownIcon />
        }
        {...menuButtonProps}
      >
        Sort
      </MenuButton>
      <MenuList>
        {!!sortConfig && (
          <>
            <MenuGroup>
              <MenuItem
                closeOnSelect
                icon={<></>}
                onClick={() => changeSortConfig(null)}
              >
                Back to default order
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
          </>
        )}
        <MenuOptionGroup
          title="Sort by"
          type="radio"
          value={sortConfig?.field ?? ''}
          onChange={(field) =>
            changeSortConfig({
              field: field as SortKey,
            })
          }
        >
          <MenuItemOption value="name">Name</MenuItemOption>
          <MenuItemOption value="processingStatus">Status</MenuItemOption>
          <MenuItemOption value="sellableIn">Country</MenuItemOption>
        </MenuOptionGroup>
        <MenuDivider />
        <MenuOptionGroup
          title="Order"
          type="radio"
          value={sortConfig?.order ?? ''}
          onChange={(order) => changeSortConfig({ order: order as SortOrder })}
        >
          <MenuItemOption value="asc">Ascending</MenuItemOption>
          <MenuItemOption value="desc">Descending</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

const MemoizedMerchantsSortMenu = memo(MerchantsSortMenu);

export { MemoizedMerchantsSortMenu as MerchantsSortMenu };
