import * as React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import { App } from './App';
import { reportWebVitals } from './utils/reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { config } from './utils/config';
import { sentryIntegrations } from './utils/sentry';

if (process.env.NODE_ENV === 'production' && config.env !== 'local') {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.env,
    release: config.sentryRelease,
    integrations: sentryIntegrations,
  });
}

// Mock API in tests or dev env with flag
if (config.env === 'test' || window.localStorage.getItem('useDevMockServer')) {
  const { worker } = require('./data/mocks/server/browser');
  worker.start();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
