import cloneDeep from 'lodash/cloneDeep';
import { rest } from 'msw';
import slugify from 'slugify';

import { config } from '../../../utils/config';
import { SupportedCountry } from '../../../utils/countriesSellableIn';
import { MerchantFeedRawConfig } from '../../contracts/affiliateNetworksContracts';
import { MerchantProcessStatus } from '../../contracts/processStatus';
import {
  affiliateNetworksMock,
  merchantsMocks,
} from '../affiliateNetworksMocks';
import { blacklistedMock } from '../../../sections/Settings/utils/settingsUtils';
let affiliateNetworksData = cloneDeep(affiliateNetworksMock);
let merchantsData = cloneDeep(merchantsMocks);

export function resetMocksData() {
  affiliateNetworksData = cloneDeep(affiliateNetworksMock);
  merchantsData = cloneDeep(merchantsMocks);
}

export const handlers = [
  rest.get(`${config.apiUrl}/networks`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(affiliateNetworksData));
  }),
  rest.get(`${config.apiUrl}/products/blocked`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(blacklistedMock));
  }),
  rest.get(
    `${config.apiUrl}/networks/:affiliateNetwork/merchants`,
    (req, res, ctx) => {
      const affiliateNetwork: string = req.params.affiliateNetwork;
      if (affiliateNetwork in merchantsData) {
        return res(
          ctx.status(200),
          ctx.json(
            merchantsData[affiliateNetwork as keyof typeof merchantsData]
              .overview,
          ),
        );
      }
      return res(ctx.status(404));
    },
  ),
  rest.post<Pick<MerchantFeedRawConfig, 'name' | 'sellableIn'>>(
    `${config.apiUrl}/networks/:affiliateNetwork/merchants`,
    (req, res, ctx) => {
      const affiliateNetwork: string = req.params.affiliateNetwork;
      if (!(affiliateNetwork in merchantsData)) {
        return res(ctx.status(404));
      }
      const config = req.body;

      const newMerchantSlug = slugify(config.name, { lower: true });

      const merchants =
        merchantsData[affiliateNetwork as keyof typeof merchantsData];

      const existingMerchantIndex = merchants.overview.findIndex(
        (m) => m.slug === req.params.merchant,
      );

      if (existingMerchantIndex !== -1) {
        return res(
          ctx.status(400),
          ctx.json({
            error: 'Merchant already exists',
          }),
        );
      }

      const overview = {
        name: config.name,
        slug: newMerchantSlug,
        lastUpdatedAt: Date.now(),
        processingStatus: 'initial-bootstrap' as MerchantProcessStatus,
        affiliateNetwork,
        sellableIn: config.sellableIn! as SupportedCountry,
      };

      const status = {
        name: config.name,
        lastSuccessfulUpdatedAt: null,
        lastUpdatedAt: Date.now(),
        processingStatus: 'initial-bootstrap' as MerchantProcessStatus,
        failedProductsCount: 0,
        processedProductsCount: 0,
        groupedProductFailures: [],
        groupedProductEnrichmentFailures: [],
      };

      merchants.overview.push(overview);
      merchants.status.push(status);
      merchants.config.push(config as any);

      setTimeout(() => {
        console.log(`updatng '${newMerchantSlug}' to initial-category-mapping`);
        overview.processingStatus = 'initial-category-mapping';
        status.processingStatus = 'initial-category-mapping';
      }, 10000);

      return res(
        ctx.status(200),
        ctx.json({
          slug: newMerchantSlug,
        }),
      );
    },
  ),
  rest.get(
    `${config.apiUrl}/networks/:affiliateNetwork/merchants/:merchant/status`,
    (req, res, ctx) => {
      const affiliateNetwork: string = req.params.affiliateNetwork;
      const merchant: string = req.params.merchant;

      if (!(affiliateNetwork in merchantsData)) {
        return res(ctx.status(404));
      }

      const merchants =
        merchantsData[affiliateNetwork as keyof typeof merchantsData];

      const merchantIndex = merchants.overview.findIndex(
        (m) => m.slug === merchant,
      );

      if (merchantIndex === -1) {
        return res(ctx.status(404));
      }

      return res(ctx.status(200), ctx.json(merchants.status[merchantIndex]));
    },
  ),
  rest.get(
    `${config.apiUrl}/networks/:affiliateNetwork/merchants/:merchant/config`,
    (req, res, ctx) => {
      const affiliateNetwork: string = req.params.affiliateNetwork;
      const merchant: string = req.params.merchant;

      if (!(affiliateNetwork in merchantsData)) {
        return res(ctx.status(404));
      }

      const merchants =
        merchantsData[affiliateNetwork as keyof typeof merchantsData];

      const merchantIndex = merchants.overview.findIndex(
        (m) => m.slug === merchant,
      );

      if (merchantIndex === -1) {
        return res(ctx.status(404));
      }

      return res(ctx.status(200), ctx.json(merchants.config[merchantIndex]));
    },
  ),
  rest.patch<Partial<MerchantFeedRawConfig>>(
    `${config.apiUrl}/networks/:affiliateNetwork/merchants/:merchant/config`,
    async (req, res, ctx) => {
      const affiliateNetwork: string = req.params.affiliateNetwork;

      if (!(affiliateNetwork in merchantsData)) {
        return res(
          ctx.status(400),
          ctx.json({
            error: 'Invalid affiliate network',
          }),
        );
      }

      const merchants =
        merchantsData[affiliateNetwork as keyof typeof merchantsData];

      const merchantIndex = merchants.overview.findIndex(
        (m) => m.slug === req.params.merchant,
      );

      if (merchantIndex === -1) {
        return res(
          ctx.status(400),
          ctx.json({
            error: 'Invalid merchant',
          }),
        );
      }

      const config = req.body;
      const existingMerchantConfig = merchants.config[merchantIndex];
      const existingMerchantOverview = merchants.overview[merchantIndex];
      const existingMerchantStatus = merchants.status[merchantIndex];

      let response = {};
      const merchantSlug = config.name
        ? slugify(config.name, { lower: true })
        : existingMerchantOverview.slug;

      if (config.name) {
        response = {
          slug: merchantSlug,
        };
      }

      merchants.config[merchantIndex] = {
        ...existingMerchantConfig,
        ...config,
      };

      merchants.overview[merchantIndex].slug = merchantSlug;
      merchants.overview[merchantIndex].name =
        config.name ?? existingMerchantOverview.name;
      merchants.overview[merchantIndex].sellableIn =
        (config.sellableIn as SupportedCountry) ??
        existingMerchantOverview.sellableIn;

      merchants.status[merchantIndex].name =
        config.name ?? existingMerchantStatus.name;

      return res(ctx.status(200), ctx.json(response));
    },
  ),
];
