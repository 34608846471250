import { ChevronDownIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  MenuButton,
  MenuList,
  useDisclosure,
  Portal,
  useOutsideClick,
  MenuOptionGroup,
  MenuItemOption,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  MenuProps,
  useMenu,
  useMultiStyleConfig,
  omitThemingProps,
  MenuProvider,
  StylesProvider,
  Box,
  MenuItem,
  Text,
  HStack,
  BoxProps,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { forwardRef } from 'react';
import { useRef } from 'react';

import { SetState } from '../../types/helpers';

import { useSearchTextFilter } from './SearchTextInput';

function hasFocusIn(element: HTMLElement | null) {
  return element?.contains(document.activeElement) ?? false;
}

// This is basically code from Chakra UI's Menu component but with overridden
// method for setting focus index. This makes all code trying to set focus
// to any menu elements to always do nothing. Reason of such hacky behaviour
// is to let focus always stay within the autocomplete input, so user can
// type without any interruption - e.g. without this, the focus would be lost
// on hover of menu items or when using backspace and broadening list of results.
export const MenuWithoutFocusManagement = (props: MenuProps) => {
  const { children } = props;

  const styles = useMultiStyleConfig('Menu', props);
  const ownProps = omitThemingProps(props);

  const ctx = useMenu(ownProps);

  const context = React.useMemo(
    () => ({
      ...ctx,
      setFocusedIndex: () => {},
    }),
    [ctx],
  );

  return (
    <MenuProvider value={context}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </MenuProvider>
  );
};

type Option<TItem> = {
  label: string;
  value: TItem;
};

type SelectedItemsProps = {
  values: string[];
  onRemoveItemClick: (selectedValue: string) => void;
};

const SelectedItems = forwardRef<HTMLDivElement, SelectedItemsProps>(
  (props: SelectedItemsProps, ref) => (
    <Flex
      ref={ref}
      flexWrap="wrap"
      mb={2}
      sx={{
        gap: 'var(--chakra-space-2)',
      }}
    >
      {props.values.map((value) => (
        <Tag key={value} flexShrink={0}>
          <TagLabel>{value}</TagLabel>
          <TagCloseButton onClick={() => props.onRemoveItemClick(value)} />
        </Tag>
      ))}
    </Flex>
  ),
);

const searchKey: keyof Option<unknown> = 'label';

const fuseOptions = {
  ignoreLocation: false,
  includeMatches: true,
  keys: [searchKey],
  threshold: 0.1,
};

function useCloseOnOutsideMenuElementsClick(
  menuListRef: React.RefObject<HTMLDivElement>,
  containerRef: React.RefObject<HTMLDivElement>,
  selectedItemsRef: React.RefObject<HTMLDivElement>,
  isOpen: boolean,
  close: () => void,
) {
  useOutsideClick({
    ref: menuListRef,
    handler: (e) => {
      const target = e.target as Node | null;
      const isClickInsideInputGroup = containerRef.current?.contains(target);
      const isClickOnSelectedItem = selectedItemsRef.current?.contains(target);

      if (isClickInsideInputGroup || isClickOnSelectedItem) {
        return;
      }

      if (isOpen) {
        close();
      }
    },
  });
}

function useKeyboardMenuNavigation<TItem>(
  filteredOptions: Option<TItem>[],
  highlightedItemIndex: number | null,
  setHighlightedItemIndex: SetState<number | null>,
  close: () => void,
  open: () => void,
  values: TItem[],
  setValues: (values: TItem[]) => void,
) {
  const inputKeyDownHandler: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        close();
      }

      if (e.key === 'ArrowDown') {
        setHighlightedItemIndex((previousIndex) => {
          if (
            previousIndex === null ||
            previousIndex === filteredOptions.length - 1
          ) {
            return 0;
          }
          return previousIndex + 1;
        });
      }

      if (e.key === 'ArrowUp') {
        setHighlightedItemIndex((previousIndex) => {
          if (previousIndex === null || previousIndex === 0) {
            return filteredOptions.length - 1;
          }
          return previousIndex - 1;
        });
      }

      if (e.key === 'Enter') {
        if (highlightedItemIndex === null) {
          open();
        } else {
          const highlightedItem = filteredOptions[highlightedItemIndex].value;
          let newValues: TItem[];

          if (values.includes(highlightedItem)) {
            newValues = values.filter((value) => value !== highlightedItem);
          } else {
            newValues = [...values, highlightedItem];
          }

          setValues(newValues);
        }
      }
    },
    [
      close,
      filteredOptions,
      highlightedItemIndex,
      open,
      setHighlightedItemIndex,
      setValues,
      values,
    ],
  );
  return inputKeyDownHandler;
}

type AutoCompleteInputProps<TItem> = Partial<BoxProps> & {
  placeholder?: string;
  options: Option<TItem>[];
  OptionLabel: (props: {
    label: string;
    value: TItem;
    highlightedMatch: [number, number][];
  }) => JSX.Element;
  values: TItem[];
  setValues: (newValues: TItem[]) => void;
  noResultsText: React.ReactNode;
};

export function AutoCompleteInput<TItem extends string>({
  placeholder,
  options,
  OptionLabel,
  values,
  setValues,
  noResultsText,
  ...props
}: AutoCompleteInputProps<TItem>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuListRef = useRef<HTMLDivElement>(null);
  const selectedItemsRef = useRef<HTMLDivElement>(null);

  const [searchText, setSearchText] = useState('');
  const [highlightedItemIndex, setHighlightedItemIndex] = useState<
    number | null
  >(null);

  const close = useCallback(() => {
    onClose();
    setHighlightedItemIndex(null);
  }, [onClose]);

  const [filteredOptions, matches] = useSearchTextFilter(
    options,
    searchText,
    fuseOptions,
  );

  useCloseOnOutsideMenuElementsClick(
    menuListRef,
    containerRef,
    selectedItemsRef,
    isOpen,
    close,
  );

  const inputKeyDownHandler = useKeyboardMenuNavigation(
    filteredOptions,
    highlightedItemIndex,
    setHighlightedItemIndex,
    close,
    onOpen,
    values,
    setValues,
  );

  const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    if (!isOpen) {
      onOpen();
    }
    setSearchText(e.target.value);
  };

  const inputBlurHandler: React.FocusEventHandler<HTMLInputElement> = () => {
    setTimeout(() => {
      if (
        !hasFocusIn(containerRef.current) &&
        !hasFocusIn(menuListRef.current) &&
        !hasFocusIn(selectedItemsRef.current)
      ) {
        close();
      }
    }, 20);
  };

  const chevronButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (isOpen) {
      close();
    } else {
      inputRef.current?.focus();
    }
  };

  const removeItemClickHandler = (selectedValue: string) => {
    const valuesWithoutSelected = values.filter(
      (value) => value !== selectedValue,
    );
    setValues(valuesWithoutSelected);

    if (values.length === 1 && values.includes(selectedValue as TItem)) {
      close();
    }
  };

  return (
    <Box {...props}>
      {values.length > 0 && (
        <SelectedItems
          ref={selectedItemsRef}
          values={values}
          onRemoveItemClick={removeItemClickHandler}
        />
      )}
      <InputGroup ref={containerRef}>
        <Input
          ref={inputRef}
          type="text"
          value={searchText}
          placeholder={placeholder}
          onChange={inputChangeHandler}
          onFocus={onOpen}
          onBlur={inputBlurHandler}
          onKeyDown={inputKeyDownHandler}
          pr={12}
          width="auto"
          flexGrow={1}
          flexShrink={1}
        />
        <InputRightElement width={12}>
          <Button
            p={0}
            minWidth="none"
            width={6}
            height={6}
            onClick={chevronButtonClickHandler}
          >
            <ChevronDownIcon />
          </Button>
        </InputRightElement>
        <MenuWithoutFocusManagement isOpen={isOpen} autoSelect={false}>
          <MenuButton position="absolute" left="0" bottom="0" />
          <Portal>
            <MenuList
              ref={menuListRef}
              maxH="50vh"
              overflow="auto"
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  close();
                }
              }}
              onFocus={(e) => {
                // Do not allow menu list to handle focus at all
                inputRef.current?.focus();
              }}
            >
              <MenuOptionGroup
                type="checkbox"
                onChange={(newValues) => {
                  if (newValues.length === 0) {
                    inputRef.current?.focus();
                  }
                  setValues(newValues as TItem[]);
                }}
                value={values}
              >
                {filteredOptions.length === 0 && (
                  <MenuItem bg="transparent !important" cursor="auto">
                    <HStack>
                      <InfoOutlineIcon />
                      <Text>{noResultsText}</Text>
                    </HStack>
                  </MenuItem>
                )}
                {filteredOptions.map((option, index) => (
                  <MenuItemOption
                    key={option.value}
                    value={option.value}
                    background={
                      index === highlightedItemIndex ? 'gray.100' : undefined
                    }
                    onClick={() => setHighlightedItemIndex(index)}
                    _hover={{ background: 'gray.100' }}
                  >
                    <OptionLabel
                      label={option.label}
                      value={option.value}
                      highlightedMatch={matches[index] ?? []}
                    />
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </MenuWithoutFocusManagement>
      </InputGroup>
    </Box>
  );
}
