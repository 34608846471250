import { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import OktaAuthBrowser from '@okta/okta-auth-js/lib/browser/browser';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import { config, oktaConfig } from '../../utils/config';
import { SetState } from '../../types/helpers';

import logo from './360logo.png';

const oktaSignInConfig = {
  baseUrl: `https://${config.oktaDomain}`,
  clientId: oktaConfig.clientId,
  redirectUri: oktaConfig.redirectUri,
  features: { registration: true },
  logo,
  logoText: '360 Code Lab - affiliate partners',
  authParams: {
    scopes: ['openid', 'profile', 'email'],
  },
};

async function initialize(
  widget: OktaSignIn,
  widgetElement: HTMLDivElement,
  oktaAuth: OktaAuthBrowser,
  setError: SetState<unknown>,
) {
  try {
    const tokens = await widget.showSignInToGetTokens({
      el: widgetElement,
    });
    await oktaAuth.handleLoginRedirect(tokens);
  } catch (error) {
    setError(error);
  }
}

const OktaSignInWidget = () => {
  const { oktaAuth } = useOktaAuth();

  const [error, setError] = useState<unknown>(null);
  const widgetElementRef = useRef<HTMLDivElement>(null);
  const widgetRef = useRef<OktaSignIn>(new OktaSignIn(oktaSignInConfig));
  const hasBeenInitializedRef = useRef(false);

  useEffect(() => {
    const widget = widgetRef.current;

    if (widgetElementRef.current && !hasBeenInitializedRef.current) {
      console.log('initializing okta sign-in widget');
      hasBeenInitializedRef.current = true;
      initialize(widget, widgetElementRef.current, oktaAuth, setError);
    }

    return () => {
      console.log('removing okta sign-in widget');
      widget.remove();
    };
  }, [oktaAuth]);

  if (error) {
    throw error;
  }

  return <div ref={widgetElementRef} />;
};

export const Login = () => {
  const { authState } = useOktaAuth();

  if (authState.isPending) {
    return null;
  }

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    <OktaSignInWidget />
  );
};
