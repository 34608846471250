import { useMemo } from 'react';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { blacklisted } from '../sections/Settings/utils/settingsUtils';

import { config } from './config';
import { useAccessToken } from './hooks/useAccessToken';
import {
  FetchResult,
  FetchError,
  ErrorResponseResult,
  performFetchWithLogging,
  getStandardHeaders,
  queryFnFetchErrorWrapper,
} from './fetch';

type NewBlacklistedResult = {
  productId: string;
  name: string;
  comment: string;
};
type NewBlacklistedFormValues = {
  productId: string;
  name: string;
  comment: string;
};
type BlacklistedList = blacklisted[];

type BlacklistedToDeletId = {
  id: string;
};

const getBlacklistedQueryName = 'blacklisted';

type BlacklistedToDeletResult = {
  id: string;
};

async function fetchBlacklisted(
  accessToken: string,
): Promise<FetchResult<BlacklistedList>> {
  const url = `${config.apiUrl}/products/blocked`;

  return performFetchWithLogging(url, {
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

async function blacklistedAdd(
  accessToken: string,
  blacklisted: NewBlacklistedFormValues,
): Promise<FetchResult<NewBlacklistedResult>> {
  const url = `${config.apiUrl}/products/blocked`;
  const payload = JSON.stringify(blacklisted);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

async function blacklistedDelete(
  blacklistedId: string,
  accessToken: string,
): Promise<FetchResult<BlacklistedToDeletResult>> {
  const blacklistedIdSlug = encodeURIComponent(blacklistedId);

  const url = `${config.apiUrl}/products/blocked/${blacklistedIdSlug}`;

  return performFetchWithLogging(url, {
    method: 'DELETE',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export function useBlacklistedQuery(
  queryOptions?: UseQueryOptions<BlacklistedList, FetchError>,
) {
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () => queryFnFetchErrorWrapper(() => fetchBlacklisted(accessToken!)),
    [accessToken],
  );

  return useQuery<BlacklistedList, FetchError>(
    [getBlacklistedQueryName],
    queryFn,
    queryOptions,
  );
}

export function useBlacklistedDeleteMutation(
  mutationOptions?: UseMutationOptions<
    BlacklistedToDeletResult,
    FetchError,
    BlacklistedToDeletId
  >,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(
        async (blacklistedToDeletId: BlacklistedToDeletId) =>
          blacklistedDelete(blacklistedToDeletId.id, accessToken!),
      ),
    [accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      mutationOptions?.onSuccess?.(result, ...args);

      await queryClient.invalidateQueries([getBlacklistedQueryName]);
    },
  });
}

export type useBlacklistedMutationType = UseMutationOptions<
  NewBlacklistedResult,
  ErrorResponseResult,
  NewBlacklistedFormValues
>;

export function useBlacklistedMutation(
  mutationOptions?: useBlacklistedMutationType,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(
        (newBlacklistedFormValues: NewBlacklistedFormValues) =>
          blacklistedAdd(accessToken!, newBlacklistedFormValues),
      ),
    [accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      await queryClient.invalidateQueries([getBlacklistedQueryName]);
      await mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}
