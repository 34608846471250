import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  InputRightElement,
} from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { ChakraComponentProps, KeysOfType } from '../../types/helpers';

type Props<TPropNames> = {
  name: TPropNames;
  label: string;
  isReadOnly?: boolean;
  isPercent?: boolean;
};

type NumberFieldProps<TPropNames> = Props<TPropNames> &
  Omit<ChakraComponentProps<typeof NumberInput>, keyof Props<TPropNames>>;

function percent(value: number | '' | null) {
  if (value === null) {
    return '';
  }
  if (value === '') {
    return value;
  }
  return value * 100;
}

function depercent(value: number | '' | null) {
  if (value === null) {
    return '';
  }
  if (value === '') {
    return value;
  }
  return value / 100;
}

function adjustValue(value: number | '' | null, isPercent: boolean) {
  if (value == null) {
    return '';
  }

  if (isPercent) {
    return percent(value).toString();
  }

  return typeof value === 'string' ? value : value.toString();
}

export const NumberField = <TFormValues extends {} = any>({
  name,
  label,
  isReadOnly,
  isPercent = false,
  ...props
}: NumberFieldProps<KeysOfType<TFormValues, number | '' | null>>) => {
  const [field, meta] = useField<number | ''>(name);
  const form = useFormikContext<TFormValues>();

  const [value, setValue] = useState(() => adjustValue(field.value, isPercent));

  useEffect(() => {
    setValue(adjustValue(field.value, isPercent));
  }, [field.value, isPercent]);

  const changeHandler = (valueAsString: string, valueAsNumber: number) => {
    const value = valueAsString === '' ? valueAsString : valueAsNumber;

    setValue(valueAsString);

    const realValue = isPercent ? depercent(value) : value;

    form.setFieldValue(name, realValue);
    form.setFieldTouched(name, true);
  };

  return (
    <FormControl isInvalid={!isReadOnly && !!meta.error && meta.touched}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {isReadOnly && (
        <Text color="dimgrey">
          {value}
          {isPercent && '%'}
        </Text>
      )}
      {!isReadOnly && (
        <>
          <NumberInput
            {...field}
            value={value}
            id={name}
            onChange={changeHandler}
            isDisabled={form.isSubmitting}
            {...props}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
            {isPercent && (
              <InputRightElement
                right={8}
                width={4}
                children={<Text>%</Text>}
              />
            )}
          </NumberInput>
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </>
      )}
    </FormControl>
  );
};
