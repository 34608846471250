import { CategoryMapping } from '../contracts/affiliateNetworksContracts';

export const simpleCategoryMappingMock: CategoryMapping = Object.fromEntries([
  [`men's clothes`, 'SUB_20001'],
  [`women's clothes`, 'SUB_20001'],
  [`men's clothes > t-shirts`, 'SUB_20001'],
  [`men's clothes > t-shirts > v-neck`, 'SUB_20001'],
  [`men's clothes > t-shirts > tank`, 'SUB_20001'],
  [`men's clothes > shoes`, 'SUB_20005'],
  [`men's clothes > shoes > outdoor`, 'SUB_20005'],
  [`men's clothes > shoes > casual`, 'SUB_20005'],
  [`men's clothes > shoes > business`, 'SUB_20005'],
  [`electronics`, 'SUB_80002'],
  [`electronics > smartphones`, 'SUB_60001'],
  [`electronics > smartphones > iphone`, 'SUB_60002'],
  [`electronics > smartphones > samsung`, 'SUB_60003'],
  [`electronics > tablets`, 'SUB_60001'],
  [`electronics > TVs`, 'SUB_60001'],
  [`books`, 'MC_20001'],
  [`books > science-fiction`, 'MC_20001'],
  [`books > for kids`, 'MC_20001'],
]);

export const simpleMissingCategoriesMock: CategoryMapping = Object.fromEntries([
  [`women's clothes > shoes`, null],
  [`women's clothes > shoes > outdoor`, null],
  [`women's clothes > shoes > casual`, null],
  [`women's clothes > shoes > business`, null],
  [`books > fantasy`, null],
  [`books > romance`, null],
  [`comics`, null],
]);

export const simpleWithMissingCategoriesMock: CategoryMapping = Object.fromEntries(
  [
    ...Object.entries(simpleCategoryMappingMock),
    ...Object.entries(simpleMissingCategoriesMock),
  ].sort(([a], [b]) => a.localeCompare(b)),
);
