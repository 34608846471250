import { Input, Text } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';
import { useState, useMemo, useCallback } from 'react';
import Select from 'react-select';

import { ChakraComponentProps } from '../../../types/helpers';
import { Categories } from '../../../data/contracts/affiliateNetworksContracts';
type SprykerCategoryFieldProps = ChakraComponentProps<typeof Input> & {
  merchantCategory: string;
  sprykerCategory: string | null;
  categoryMapping: Categories;
};

export const SprykerCategoryField = (props: SprykerCategoryFieldProps) => {
  const { merchantCategory, sprykerCategory, categoryMapping } = props;

  const options: {
    label: string;
    value: string;
  }[] = useMemo(
    () =>
      Object.entries(categoryMapping).map((elem: string[]) => {
        return {
          label: elem[1],
          value: elem[0],
        };
      }),
    [categoryMapping],
  );

  const form = useFormikContext<any>();

  const [field] = useField<string | null>(
    `['${merchantCategory.replace(/'/g, "\\'")}']`,
  );

  const [value, setValue] = useState({
    value: sprykerCategory ?? '',
    label: sprykerCategory ?? '',
  });

  const [inputValue, setInputValue] = useState(sprykerCategory ?? '');

  const onBlur = useCallback(() => {
    form.setFieldValue(
      `['${merchantCategory.replace(/'/g, "\\'")}']`,
      inputValue || value.value,
      false,
    );
  }, [inputValue, value.value, merchantCategory, form]);

  const onChange = useCallback((event) => {
    if (event) {
      setValue(event);
    } else {
      setValue({
        value: '',
        label: '',
      });
      setInputValue('');
    }
  }, []);

  const onInputChange = useCallback(
    (value, action) => {
      if (action.action === 'input-blur') {
        if (
          options.filter((elem) => elem.value === action.prevInputValue)
            .length < 1
        ) {
          setValue({
            value: action.prevInputValue,
            label: action.prevInputValue,
          });
          setInputValue(action.prevInputValue);
        }
      } else {
        setInputValue(value);
      }
    },
    [options],
  );

  const formatOptionLabel = (label: string, value: string) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text fontSize={10}>{value}</Text>
        <Text fontSize={14}>{label}</Text>
      </div>
    );
  };

  return (
    <Select
      aria-label={`spryker category for ${merchantCategory}`}
      {...field}
      placeholder="Select option"
      options={options}
      isClearable={true}
      value={value}
      inputValue={inputValue}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={onInputChange}
      formatOptionLabel={({ label, value }) => formatOptionLabel(label, value)}
    />
  );
};
