import {
  Button,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { AffiliateNetworkId } from '../../data/contracts/affiliateNetworksContracts';
import { ChakraComponentProps } from '../../types/helpers';
import { useMerchantDeleteMutation } from '../../utils/fetch';

type DeleteMerchantButtonProps = ChakraComponentProps<typeof Button> & {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
};

export function DeleteMerchantButton(props: DeleteMerchantButtonProps) {
  const { affiliateNetworkSlug, merchantSlug, ...buttonProps } = props;
  const history = useHistory();

  const alertDisclosure = useDisclosure();
  const [hasScheduledProcessing, setHasScheduledProcessing] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const merchantDeleteMutation = useMerchantDeleteMutation(
    affiliateNetworkSlug,
    merchantSlug,
  );

  const onConfirmClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    merchantDeleteMutation.mutate();

    setHasScheduledProcessing(true);
  };

  const onCloseModal = () => {
    if (merchantDeleteMutation.status === 'success') {
      history.replace(`/${props.affiliateNetworkSlug}`, {
        affiliateNetworkRedirect: props.affiliateNetworkSlug,
      });
    }

    alertDisclosure.onClose();
  };

  return (
    <>
      <Tooltip
        isOpen={!hasScheduledProcessing ? false : undefined}
        label="Already processing"
        // wrapper is needed as disabled button itself won't work with tooltip
        // see more at https://github.com/chakra-ui/chakra-ui/issues/500
        shouldWrapChildren
      >
        <Button
          {...buttonProps}
          colorScheme="red"
          onClick={alertDisclosure.onOpen}
          isDisabled={hasScheduledProcessing}
        />
      </Tooltip>
      <AlertDialog
        isOpen={alertDisclosure.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseModal}
        size="lg"
        closeOnOverlayClick={merchantDeleteMutation.status !== 'loading'}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete merchant
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            {merchantDeleteMutation.status === 'error' && (
              <Alert status="error" pr={10}>
                <AlertIcon />
                Delete error, please try again
              </Alert>
            )}
            {merchantDeleteMutation.status === 'success' && (
              <Alert status="success" pr={10}>
                Delete Success
              </Alert>
            )}
            <AlertDialogFooter>
              <Button
                isLoading={merchantDeleteMutation.status === 'loading'}
                onClick={
                  merchantDeleteMutation.status === 'success'
                    ? onCloseModal
                    : onConfirmClick
                }
              >
                {merchantDeleteMutation.status === 'success'
                  ? 'Close'
                  : 'Delete'}
              </Button>
              <Button
                isDisabled={merchantDeleteMutation.status === 'loading'}
                ref={cancelRef}
                onClick={onCloseModal}
                variant="outline"
                ml={3}
              >
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
