import { Table, Thead, Tr, Th, Tbody, Td, IconButton } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

import { formatUnixTimestamp } from '../../utils/dateTime';

import { blacklisted } from './utils/settingsUtils';

type BlacklistedTableActionsProps = {
  onRemove: () => void;
};

const BlacklistedTableActions = (props: BlacklistedTableActionsProps) => (
  <IconButton
    onClick={props.onRemove}
    aria-label="remove"
    icon={<DeleteIcon />}
    variant="ghost"
  />
);

type BlacklistedTableProps = {
  tableElements: blacklisted[];
  onRowRemove: (blacklisted: blacklisted) => void;
};

export function SettingsBlacklistedTable(props: BlacklistedTableProps) {
  return (
    <Table title="blacklisted">
      <Thead>
        <Tr>
          <Th>Date</Th>
          <Th>User</Th>
          <Th>ID</Th>
          <Th>Product Name</Th>
          <Th>Comment</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody data-testid="blacklisted-products-table-body">
        {props.tableElements.map((tableElement) => (
          <Tr title="Blacklisted products" key={tableElement.id}>
            <Td>{formatUnixTimestamp(tableElement.createdAt)}</Td>
            <Td>{tableElement.userName}</Td>
            <Td>{tableElement.id}</Td>
            <Td>{tableElement.name}</Td>
            <Td>{tableElement.comment}</Td>
            <Td whiteSpace="nowrap">
              <BlacklistedTableActions
                onRemove={() => props.onRowRemove(tableElement)}
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
