import { useCallback } from 'react';
import {
  VStack,
  Divider,
  Heading,
  Box,
  Button,
  Alert,
  AlertIcon,
  CloseButton,
} from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';

import {
  AffiliateNetworkId,
  MerchantFeedConfig,
} from '../../data/contracts/affiliateNetworksContracts';
import { useNewMerchantFeedMutation } from '../../utils/fetch';
import {
  AwinFormValues,
  MerchantFeedSettingsFormFields,
  MerchantFeedSettingsFormValues,
  StandardFormValues,
  useMerchantFeedSettingsValidationSchema,
} from '../../components/MerchantFeedSettingsFormFields';

function getDefaultInitialValues(
  affiliateNetworkSlug: AffiliateNetworkId,
): MerchantFeedSettingsFormValues {
  const defaultInitialValues: StandardFormValues = {
    name: '',
    displayName: '',
    url: '',
    networkParameterPrefix: '',
    networkParameterSuffix: '',
    storeName: 'DE',
    lowerPriceThreshold: 0,
    upperPriceThreshold: null,
    merchantScoreAdjustment: 1.0,
    officeDealerId: '',
    sellableIn: '',
    deliveryTime: '',
    deliveryCost: '',
    currency: '',
    comment: '',
  };

  if (affiliateNetworkSlug === 'AWIN') {
    const defaultValues: AwinFormValues = {
      ...defaultInitialValues,
      useAwinCategories: false,
    };
    return defaultValues;
  }

  return defaultInitialValues;
}

function FormStatusAlert(props: { onClose: () => void }) {
  return (
    <Alert status="error" pr={10}>
      <AlertIcon />
      Failed to save new merchant. Try again later or contact support if problem
      occurs again.
      <CloseButton
        onClick={props.onClose}
        position="absolute"
        right={1}
        top={2}
      />
    </Alert>
  );
}

type MerchantFeedSettingsFormProps = {
  affiliateNetworkSlug: AffiliateNetworkId;
};

export function NewMerchantFeedSettingsForm(
  props: MerchantFeedSettingsFormProps,
) {
  const history = useHistory();
  const initialValues: MerchantFeedSettingsFormValues = getDefaultInitialValues(
    props.affiliateNetworkSlug,
  );
  const validationSchema = useMerchantFeedSettingsValidationSchema(
    props.affiliateNetworkSlug,
  );

  const newMerchantFeedMutation = useNewMerchantFeedMutation(
    props.affiliateNetworkSlug,
    {
      onSuccess: (result) => {
        history.replace(`/${props.affiliateNetworkSlug}/${result.slug}`, {
          newMerchantRedirect: result.slug,
        });
      },
    },
  );

  const submitHandler = useCallback(
    async (
      values: MerchantFeedSettingsFormValues,
      formikHelpers: FormikHelpers<MerchantFeedSettingsFormValues>,
    ) => {
      console.log('new merchant:', values);
      formikHelpers.setStatus();

      try {
        await newMerchantFeedMutation.mutateAsync(
          values as MerchantFeedConfig | MerchantFeedConfig<'AWIN'>,
        );
      } catch (error) {
        // Should distinguish 400 errors in future
        Sentry.captureException(error);
        formikHelpers.setStatus('error');
      }
    },
    [newMerchantFeedMutation],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isSubmitting, resetForm, errors, status, setStatus }) => (
        <VStack as={Form} spacing={6} alignItems="start">
          {status === 'error' && (
            <FormStatusAlert onClose={() => setStatus()} />
          )}
          <MerchantFeedSettingsFormFields
            affiliateNetworkSlug={props.affiliateNetworkSlug}
            isEditingForm={false}
          />
          <Button type="submit" mt={4} isLoading={isSubmitting}>
            Start new merchant processing
          </Button>
        </VStack>
      )}
    </Formik>
  );
}

type MerchantsNewSectionProps = {
  affiliateNetworkSlug: AffiliateNetworkId;
};

export function NewMerchantSection(props: MerchantsNewSectionProps) {
  return (
    <Box data-testid="new-merchant-form-section" p={6}>
      <Heading as="h4" size="md" px={4}>
        Add new Merchant
      </Heading>
      <Divider my="4" mb={6} />
      <Box px={4}>
        <NewMerchantFeedSettingsForm
          affiliateNetworkSlug={props.affiliateNetworkSlug}
        />
      </Box>
    </Box>
  );
}
