import { Button } from '@chakra-ui/react';

export const EditOrCancelButton = (props: {
  isReadOnly: boolean;
  isDisabled?: boolean;
  startEdit: () => void;
  cancelEdit: () => void;
}) => (
  <Button
    key={props.isReadOnly ? 'edit' : 'cancel'}
    variant="outline"
    onClick={props.isReadOnly ? props.startEdit : props.cancelEdit}
    isDisabled={props.isDisabled}
  >
    {props.isReadOnly ? 'Edit' : 'Cancel'}
  </Button>
);
