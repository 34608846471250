import {
  Button,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Alert,
  AlertIcon,
  Text,
  useDisclosure,
  Input,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { AffiliateNetworkId } from '../../../data/contracts/affiliateNetworksContracts';
import { ChakraComponentProps } from '../../../types/helpers';
import { useMerchantCategoryMappingFileMutation } from '../../../utils/fetch';

type TriggerMerchantProcessingButtonProps = ChakraComponentProps<
  typeof Button
> & {
  affiliateNetworkSlug: AffiliateNetworkId;
  merchantSlug: string;
};

export function UploadCategoryMappingFileButton(
  props: TriggerMerchantProcessingButtonProps,
) {
  const { affiliateNetworkSlug, merchantSlug, ...buttonProps } = props;

  const [fileSelected, setFileSelected] = useState<File>();

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    setFileSelected(fileList[0]);
  };
  const alertDisclosure = useDisclosure();
  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const merchantCategoryMappingFileMutation = useMerchantCategoryMappingFileMutation(
    affiliateNetworkSlug,
    merchantSlug,
  );

  const onConfirmClick: React.MouseEventHandler<HTMLButtonElement> = async (
    e,
  ) => {
    if (fileSelected) {
      await merchantCategoryMappingFileMutation.mutateAsync(fileSelected);
      setHasFileUploaded(true);
    }
  };

  return (
    <>
      <Tooltip
        isOpen={!hasFileUploaded ? false : undefined}
        label="Already uploaded"
        // wrapper is needed as disabled button itself won't work with tooltip
        // see more at https://github.com/chakra-ui/chakra-ui/issues/500
        shouldWrapChildren
      >
        <Button
          {...buttonProps}
          onClick={alertDisclosure.onOpen}
          isLoading={merchantCategoryMappingFileMutation.status === 'loading'}
          isDisabled={hasFileUploaded}
        />
      </Tooltip>
      <AlertDialog
        isOpen={alertDisclosure.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertDisclosure.onClose}
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Upload file
            </AlertDialogHeader>
            <AlertDialogBody>
              {merchantCategoryMappingFileMutation.status === 'error' && (
                <Alert status="error" pr={10}>
                  <AlertIcon />
                  Upload error, please try again
                </Alert>
              )}
              {merchantCategoryMappingFileMutation.status === 'success' ? (
                <Alert status="success" pr={10}>
                  File Upload Success
                </Alert>
              ) : (
                <label>
                  <Text mt={6} fontSize="sm">
                    {' '}
                    Choose csv file <br />
                    Please upload only CSV format files
                  </Text>
                  <Input
                    type="file"
                    accept=".csv"
                    onChange={(event) => handleImageChange(event)}
                  />
                </label>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                onClick={
                  merchantCategoryMappingFileMutation.status === 'success'
                    ? alertDisclosure.onClose
                    : onConfirmClick
                }
              >
                {merchantCategoryMappingFileMutation.status === 'success'
                  ? 'Close'
                  : 'Upload Config File'}
              </Button>
              <Button
                ref={cancelRef}
                onClick={alertDisclosure.onClose}
                variant="outline"
                ml={3}
              >
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
