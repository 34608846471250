import { useMemo } from 'react';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import * as Sentry from '@sentry/react';

import {
  AffiliateNetworkOverview,
  CategoryMapping,
  MerchantFeedConfig,
  MerchantFeedRawConfig,
  MerchantFeedStatus,
  MerchantOverview,
  NewMerchantFeedConfig,
  transformToMerchantFeedConfig,
  transformToMerchantFeedRawConfigPartial,
  Categories,
} from '../data/contracts/affiliateNetworksContracts';

import { config } from './config';
import { addSentryApiBreadcrumb } from './sentry';
import { useAccessToken } from './hooks/useAccessToken';

const refetchInterval = 1 * 60 * 1000; // one minute

export type ErrorResponseResult = {
  error: string;
  status: number;
  url: string;
  message: string;
};

export type FetchResult<TData> = TData | ErrorResponseResult;

export function isErrorResponseResult<TData>(
  fetchResult: FetchResult<TData>,
): fetchResult is ErrorResponseResult {
  return 'error' in fetchResult && 'status' in fetchResult;
}

async function getFetchResult<TData>(
  response: Response,
): Promise<FetchResult<TData>> {
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    const error = await response.text();
    const message = `Request to "${response.url}" failed with ${response.status}`;
    return { message, error, status: response.status, url: response.url };
  }
}

export async function performFetchWithLogging<TData>(
  url: string,
  requestInit?: RequestInit,
): Promise<FetchResult<TData>> {
  try {
    const response = await fetch(url, requestInit);
    const result = await getFetchResult<TData>(response);

    addSentryApiBreadcrumb({
      url,
      response,
      errorResult: isErrorResponseResult(result) ? result : undefined,
      requestInit,
    });

    return result;
  } catch (exception) {
    const error = exception as Error;
    addSentryApiBreadcrumb({
      url,
      requestInit,
      error,
    });
    throw error;
  }
}

function transformFetchResult<TRawData, TData>(
  result: FetchResult<TRawData>,
  transformer: (rawResult: TRawData) => TData,
): FetchResult<TData> {
  if (isErrorResponseResult(result)) {
    return result;
  }

  return transformer(result);
}

export function getStandardHeaders(accessToken?: string) {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (accessToken) {
    return {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return headers;
}

async function fetchAffiliateNetworks(
  accessToken: string,
): Promise<FetchResult<AffiliateNetworkOverview[]>> {
  return performFetchWithLogging(`${config.apiUrl}/networks`, {
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

async function fetchMerchants(
  affiliateNetworkSlug: string,
  accessToken: string,
): Promise<FetchResult<MerchantOverview[]>> {
  return performFetchWithLogging(
    `${config.apiUrl}/networks/${encodeURIComponent(
      affiliateNetworkSlug,
    )}/merchants`,
    {
      headers: getStandardHeaders(accessToken),
      credentials: 'include',
    },
  );
}

async function fetchMerchantFeedStatus(
  rawAffiliateNetworkSlug: string,
  rawMerchantSlug: string,
  accessToken: string,
): Promise<FetchResult<MerchantFeedStatus>> {
  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);
  const merchantSlug = encodeURIComponent(rawMerchantSlug);

  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants/${merchantSlug}/status`;

  return performFetchWithLogging(url, {
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

async function fetchMerchantFeedConfig(
  rawAffiliateNetworkSlug: string,
  rawMerchantSlug: string,
  accessToken: string,
): Promise<FetchResult<MerchantFeedConfig>> {
  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);
  const merchantSlug = encodeURIComponent(rawMerchantSlug);

  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants/${merchantSlug}/config`;

  const rawResult = await performFetchWithLogging<MerchantFeedRawConfig>(url, {
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });

  return transformFetchResult(rawResult, transformToMerchantFeedConfig);
}

export async function fetchMerchantCategoryMappingFile(
  rawAffiliateNetworkSlug: string,
  rawMerchantSlug: string,
  accessToken: string,
): Promise<Blob> {
  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);
  const merchantSlug = encodeURIComponent(rawMerchantSlug);
  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants/${merchantSlug}/category-mapping/file`;
  const headers: HeadersInit = {
    Accept: 'multipart/form-data',
    Authorization: `Bearer ${accessToken}`,
  };

  const rawResult = await fetch(url, {
    headers: headers,
    credentials: 'include',
  });

  return rawResult.blob();
}

type PatchMerchantFeedConfigResult = {
  slug: string;
};

async function patchMerchantFeedConfig(
  rawAffiliateNetworkSlug: string,
  rawMerchantSlug: string,
  accessToken: string,
  merchantFeedConfigFragment: Partial<MerchantFeedConfig>,
): Promise<FetchResult<PatchMerchantFeedConfigResult>> {
  if (merchantFeedConfigFragment.columnsMapping) {
  }
  const rawMerchantFeedConfigFragment = transformToMerchantFeedRawConfigPartial(
    merchantFeedConfigFragment,
  );

  const payload = JSON.stringify(rawMerchantFeedConfigFragment);

  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);
  const merchantSlug = encodeURIComponent(rawMerchantSlug);

  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants/${merchantSlug}/config`;

  return performFetchWithLogging(url, {
    method: 'PATCH',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

type PostNewMerchantFeedResult = {
  slug: string;
} & MerchantFeedConfig;

async function postNewMerchantFeed(
  rawAffiliateNetworkSlug: string,
  accessToken: string,
  merchantFeedConfig: NewMerchantFeedConfig,
): Promise<FetchResult<PostNewMerchantFeedResult>> {
  const payload = JSON.stringify(merchantFeedConfig);

  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);

  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants`;

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

async function fetchMerchantCategoryMapping(
  rawAffiliateNetworkSlug: string,
  rawMerchantSlug: string,
  accessToken: string,
): Promise<FetchResult<CategoryMapping>> {
  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);
  const merchantSlug = encodeURIComponent(rawMerchantSlug);

  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants/${merchantSlug}/category-mapping`;

  return performFetchWithLogging(url, {
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

async function patchMerchantCategoryMapping(
  rawAffiliateNetworkSlug: string,
  rawMerchantSlug: string,
  accessToken: string,
  categoryMappingPatch: CategoryMapping,
): Promise<FetchResult<CategoryMapping>> {
  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);
  const merchantSlug = encodeURIComponent(rawMerchantSlug);

  const payload = JSON.stringify(categoryMappingPatch);

  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants/${merchantSlug}/category-mapping`;

  return performFetchWithLogging(url, {
    method: 'PATCH',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

async function triggerMerchantProcessing(
  rawAffiliateNetworkSlug: string,
  rawMerchantSlug: string,
  accessToken: string,
) {
  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);
  const merchantSlug = encodeURIComponent(rawMerchantSlug);

  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants/${merchantSlug}/process`;

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

async function postMerchantCategoryFileMapping(
  rawAffiliateNetworkSlug: string,
  rawMerchantSlug: string,
  accessToken: string,
  file: File,
) {
  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);
  const merchantSlug = encodeURIComponent(rawMerchantSlug);
  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants/${merchantSlug}/category-mapping/file`;

  let formData = new FormData();
  formData.append('file', file);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: 'include',
    body: formData,
  });
}

async function deleteMerchant(
  rawAffiliateNetworkSlug: string,
  rawMerchantSlug: string,
  accessToken: string,
) {
  const affiliateNetworkSlug = encodeURIComponent(rawAffiliateNetworkSlug);
  const merchantSlug = encodeURIComponent(rawMerchantSlug);

  const url = `${config.apiUrl}/networks/${affiliateNetworkSlug}/merchants/${merchantSlug}`;

  return performFetchWithLogging(url, {
    method: 'DELETE',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export type FetchError = Error | ErrorResponseResult;

export function queryFnFetchErrorWrapper<
  TData,
  TParams extends any[] = unknown[]
>(fetchFn: (...params: TParams) => Promise<FetchResult<TData>>) {
  return async function (...params: Parameters<typeof fetchFn>) {
    try {
      const result = await fetchFn(...params);

      if (isErrorResponseResult(result)) {
        throw result;
      }

      return result;
    } catch (exception) {
      if (exception instanceof Error || isErrorResponseResult(exception)) {
        throw exception;
      } else {
        throw new Error(exception as any);
      }
    }
  };
}

type CustomQueryOptions = {
  handleErrors?: boolean;
  shouldRefetch?: boolean;
};

function mergeQueryOptions<T>(
  customOptions?: CustomQueryOptions,
  options?: UseQueryOptions<T, FetchError>,
) {
  const queryOptions = {
    ...options,
  };

  if (customOptions?.shouldRefetch) {
    queryOptions.refetchInterval = refetchInterval;
  }

  return queryOptions;
}

function throwFetchError(error: FetchError) {
  if (isErrorResponseResult(error)) {
    throw new Error(error.message);
  }
  throw error;
}

export function useAffiliateNetworksQuery(
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<AffiliateNetworkOverview[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () => queryFnFetchErrorWrapper(() => fetchAffiliateNetworks(accessToken!)),
    [accessToken],
  );

  const query = useQuery<AffiliateNetworkOverview[], FetchError>(
    'affiliateNetworks',
    queryFn,
    actualQueryOptions,
  );

  // @TODO rename `handleErrors` (or split) to indicate
  // actual distinct behavior for refetch errors swallowing
  // and rest of errors being thrown instead of being available
  // from query
  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching affiliate networks', 'error');
    } else {
      throwFetchError(query.error);
    }
  }

  return query;
}

export function useMerchantsQuery(
  affiliateNetworkSlug?: string,
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<MerchantOverview[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(() => {
        if (!affiliateNetworkSlug) {
          throw new Error(
            `Can't fetch merchants - 'affiliateNetworkSlug' is missing`,
          );
        }
        return fetchMerchants(affiliateNetworkSlug, accessToken!);
      }),
    [affiliateNetworkSlug, accessToken],
  );

  const query = useQuery<MerchantOverview[], FetchError>(
    ['merchants', { affiliateNetworkSlug }],
    queryFn,
    actualQueryOptions,
  );

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage(
        `Failed refetching merchants for ${affiliateNetworkSlug}`,
        'error',
      );
    } else {
      throwFetchError(query.error);
    }
  }

  return query;
}

export function useMerchantFeedStatusQuery(
  affiliateNetworkSlug: string,
  merchantSlug: string,
  queryOptions?: UseQueryOptions<MerchantFeedStatus, FetchError>,
) {
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(() =>
        fetchMerchantFeedStatus(
          affiliateNetworkSlug,
          merchantSlug,
          accessToken!,
        ),
      ),
    [affiliateNetworkSlug, merchantSlug, accessToken],
  );

  return useQuery<MerchantFeedStatus, FetchError>(
    ['merchant-feed', { affiliateNetworkSlug, merchantSlug }, 'status'],
    queryFn,
    queryOptions,
  );
}

export function useMerchantFeedConfigQuery(
  affiliateNetworkSlug: string,
  merchantSlug: string,
  queryOptions?: UseQueryOptions<MerchantFeedConfig, FetchError>,
) {
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(() =>
        fetchMerchantFeedConfig(
          affiliateNetworkSlug,
          merchantSlug,
          accessToken!,
        ),
      ),
    [affiliateNetworkSlug, merchantSlug, accessToken],
  );

  return useQuery<MerchantFeedConfig, FetchError>(
    ['merchant-feed', { affiliateNetworkSlug, merchantSlug }, 'config'],
    queryFn,
    queryOptions,
  );
}

export function useMerchantCategoryMappingFile(
  affiliateNetworkSlug: string,
  merchantSlug: string,
  queryOptions?: UseQueryOptions<Blob, FetchError>,
) {
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(() =>
        fetchMerchantCategoryMappingFile(
          affiliateNetworkSlug,
          merchantSlug,
          accessToken!,
        ),
      ),
    [affiliateNetworkSlug, merchantSlug, accessToken],
  );

  return useQuery<Blob, FetchError>(
    [
      'merchant-feed-category-mapping-file',
      { affiliateNetworkSlug, merchantSlug },
      'config',
    ],
    queryFn,
    queryOptions,
  );
}

export function useNewMerchantFeedMutation(
  affiliateNetworkSlug: string,
  mutationOptions?: UseMutationOptions<
    PostNewMerchantFeedResult,
    FetchError,
    NewMerchantFeedConfig
  >,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () =>
      queryFnFetchErrorWrapper((merchantFeedConfig: NewMerchantFeedConfig) =>
        postNewMerchantFeed(
          affiliateNetworkSlug,
          accessToken!,
          merchantFeedConfig,
        ),
      ),
    [affiliateNetworkSlug, accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      await queryClient.invalidateQueries([
        'merchants',
        { affiliateNetworkSlug },
      ]);
      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}

export function useMerchantFeedConfigMutation(
  affiliateNetworkSlug: string,
  merchantSlug: string,
  mutationOptions?: UseMutationOptions<
    PatchMerchantFeedConfigResult,
    FetchError,
    Partial<MerchantFeedConfig>
  >,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(
        (merchantFeedConfig: Partial<MerchantFeedConfig>) =>
          patchMerchantFeedConfig(
            affiliateNetworkSlug,
            merchantSlug,
            accessToken!,
            merchantFeedConfig,
          ),
      ),
    [affiliateNetworkSlug, merchantSlug, accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      await queryClient.invalidateQueries([
        'merchants',
        { affiliateNetworkSlug },
      ]);

      await mutationOptions?.onSuccess?.(result, ...args);

      // This goes after `onSuccess` handler so in case when merchant slug
      // changesfeed details page doesn't break due to unexistent feed url
      // (old slug) before it has a chance to redirect to url with new slug.
      await queryClient.invalidateQueries([
        'merchant-feed',
        { affiliateNetworkSlug, merchantSlug },
      ]);
    },
  });
}

export function useMerchantCategoryMappingQuery(
  affiliateNetworkSlug: string,
  merchantSlug: string,
  queryOptions?: UseQueryOptions<CategoryMapping, FetchError>,
) {
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(() =>
        fetchMerchantCategoryMapping(
          affiliateNetworkSlug,
          merchantSlug,
          accessToken!,
        ),
      ),
    [affiliateNetworkSlug, merchantSlug, accessToken],
  );

  return useQuery<CategoryMapping, FetchError>(
    [
      'merchant-feed',
      { affiliateNetworkSlug, merchantSlug },
      'category-mapping',
    ],
    queryFn,
    queryOptions,
  );
}

export function useCategoryMappingMutation(
  affiliateNetworkSlug: string,
  merchantSlug: string,
  mutationOptions?: UseMutationOptions<unknown, FetchError, CategoryMapping>,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () =>
      queryFnFetchErrorWrapper((categoryMappingPatch: CategoryMapping) =>
        patchMerchantCategoryMapping(
          affiliateNetworkSlug,
          merchantSlug,
          accessToken!,
          categoryMappingPatch,
        ),
      ),
    [affiliateNetworkSlug, merchantSlug, accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      await queryClient.invalidateQueries([
        'merchant-feed',
        { affiliateNetworkSlug, merchantSlug },
        'category-mapping',
      ]);

      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}

export function useMerchantProcessingMutation(
  affiliateNetworkSlug: string,
  merchantSlug: string,
  mutationOptions?: UseMutationOptions<unknown, FetchError>,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(async () =>
        triggerMerchantProcessing(
          affiliateNetworkSlug,
          merchantSlug,
          accessToken!,
        ),
      ),
    [affiliateNetworkSlug, merchantSlug, accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      await queryClient.invalidateQueries([
        'merchant-feed',
        { affiliateNetworkSlug, merchantSlug },
      ]);
      await queryClient.invalidateQueries([
        'merchants',
        { affiliateNetworkSlug },
      ]);

      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}

export function useMerchantCategoryMappingFileMutation(
  affiliateNetworkSlug: string,
  merchantSlug: string,
  mutationOptions?: UseMutationOptions<unknown, FetchError, File>,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () =>
      queryFnFetchErrorWrapper((file: File) =>
        postMerchantCategoryFileMapping(
          affiliateNetworkSlug,
          merchantSlug,
          accessToken!,
          file,
        ),
      ),
    [affiliateNetworkSlug, merchantSlug, accessToken],
  );

  return useMutation(mutationFn, {
    onSuccess: async (result, ...args) => {
      await queryClient.invalidateQueries([
        'merchant-feed',
        { affiliateNetworkSlug, merchantSlug },
        'category-mapping',
      ]);
      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}

export function useMerchantDeleteMutation(
  affiliateNetworkSlug: string,
  merchantSlug: string,
  mutationOptions?: UseMutationOptions<unknown, FetchError>,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(async () =>
        deleteMerchant(affiliateNetworkSlug, merchantSlug, accessToken!),
      ),
    [affiliateNetworkSlug, merchantSlug, accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      mutationOptions?.onSuccess?.(result, ...args);

      await queryClient.invalidateQueries([
        'merchant-feed',
        { affiliateNetworkSlug, merchantSlug },
      ]);

      await queryClient.invalidateQueries([
        'merchants',
        { affiliateNetworkSlug },
      ]);
    },
  });
}

async function fetchCategoryMapping(
  accessToken: string,
): Promise<FetchResult<Categories>> {
  return performFetchWithLogging(`${config.apiUrl}/category-mappings`, {
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export function useCategoryMappingQuery(
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<MerchantOverview[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(() => {
        return fetchCategoryMapping(accessToken!);
      }),
    [accessToken],
  );

  const query = useQuery<any, FetchError>([], queryFn, actualQueryOptions);

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage(`Failed fetch category mapping`, 'error');
    } else {
      throwFetchError(query.error);
    }
  }

  return query;
}
