import slugify from 'slugify';

import { SupportedCountry } from '../../utils/countriesSellableIn';
import {
  AffiliateNetworkOverview,
  MerchantFeedRawConfig,
  MerchantFeedStatus,
  MerchantOverview,
} from '../contracts/affiliateNetworksContracts';
import { AffiliateNetworkStatus } from '../contracts/processStatus';

import {
  simpleCategoryMappingMock,
  simpleMissingCategoriesMock,
  simpleWithMissingCategoriesMock,
} from './categoryMappingMocks';

const awin = {
  name: 'AWIN',
  slug: 'AWIN' as const,
  processingStatus: 'success' as AffiliateNetworkStatus,
  merchantsNumber: 2,
};

const cj = {
  name: 'CJ',
  slug: 'CJ' as const,
  processingStatus: 'warning' as AffiliateNetworkStatus,
  merchantsNumber: 3,
};

const tradeTracker = {
  name: 'Tradetracker',
  slug: 'tradetracker' as const,
  processingStatus: 'error' as AffiliateNetworkStatus,
  merchantsNumber: 1,
};

const emptyAffiliateNetwork = {
  name: 'Empty Affiliate Network loooong name',
  slug: 'empty-affiliate-network' as const,
  processingStatus: 'success' as AffiliateNetworkStatus,
  merchantsNumber: 0,
};

export const affiliateNetworksMock: AffiliateNetworkOverview[] = [
  awin,
  cj,
  tradeTracker,
  emptyAffiliateNetwork,
];

type AffiliateNetworkSlug = (
  | typeof awin
  | typeof cj
  | typeof tradeTracker
  | typeof emptyAffiliateNetwork
)['slug'];

const hourInMilliseconds = 60 * 60 * 1000;

const rtvEuroAgdConfig: MerchantFeedRawConfig<'AWIN'> = {
  name: 'RTV Euro AGD',
  displayName: 'RTV Euro AGD',
  url: 'http://localhost:3000',
  networkParameterPrefix: 'http://localhost:3000',
  networkParameterSuffix: 'http://localhost:3000',
  storeName: 'DE',
  lowerPriceThreshold: 0,
  upperPriceThreshold: null,
  merchantScoreAdjustment: 1.0,
  officeDealerId: 'office1',
  useAwinCategories: false,
  sellableIn: 'CZ',
  deliveryTime: '',
  deliveryCost: '',
  currency: '',
  comment: '',
  columnsMapping: {
    ABSTRACT_SKU: [
      'abstractSkuColumn1',
      'abstractSkuColumn2',
      'abstractSkuColumn3',
    ],
  },
};

const rtvEuroAgdStatus: MerchantFeedStatus = {
  name: 'RTV Euro AGD',
  processingStatus: 'success',
  lastUpdatedAt: Date.now() - 10 * hourInMilliseconds,
  lastSuccessfulUpdatedAt: Date.now() - 10 * hourInMilliseconds,
  processedProductsCount: 2000,
  failedProductsCount: 20,
  mostRecentResultsUrl:
    'https://myworld-my.sharepoint.com/:x:/p/jmikolajczak/EXhcXmiLHcxFt3HDOFy4rcoBsWsmQpkI2kvoqxTxedavuQ?e=hW1QzZ',
  groupedProductFailures: [],
  groupedProductEnrichmentFailures: [],
};

const rtvEuroAgdOverview = overviewFromStatus(rtvEuroAgdStatus, 'AWIN');

const babymarktDeConfig: MerchantFeedRawConfig<'AWIN'> = {
  name: 'babymarkt DE',
  displayName: 'babymarkt DE',
  url: 'http://localhost:3000',
  networkParameterPrefix: 'http://localhost:3000',
  networkParameterSuffix: 'http://localhost:3000',
  storeName: 'DE',
  lowerPriceThreshold: 0,
  upperPriceThreshold: null,
  merchantScoreAdjustment: 1.0,
  officeDealerId: 'office2',
  useAwinCategories: false,
  sellableIn: 'DE',
  columnsMapping: {},
  deliveryTime: '',
  deliveryCost: '',
  currency: '',
  comment: '',
};

const babymarktDeStatus: MerchantFeedStatus = {
  name: 'babymarkt DE',
  processingStatus: 'success',
  lastUpdatedAt: Date.now() - 5 * 60 * 1000,
  lastSuccessfulUpdatedAt: Date.now() - 5 * 60 * 1000,
  processedProductsCount: 34519,
  failedProductsCount: 7,
  mostRecentResultsUrl:
    'https://myworld-my.sharepoint.com/:x:/p/jmikolajczak/EXhcXmiLHcxFt3HDOFy4rcoBsWsmQpkI2kvoqxTxedavuQ?e=hW1QzZ',
  groupedProductFailures: [
    {
      failureMessage:
        'Category Winterharte Stauden > Winterharte Stauden A-Z > Steingartenstauden - Steingartenstauden does not have a matching entry in the provided category mapping ',
      linesNumber: '1-5',
    },
    {
      failureMessage:
        'Cannot transform row: Category Garten does not have a matching entry in the provided category mapping ',
      linesNumber: '7-10',
    },
  ],
  groupedProductEnrichmentFailures: [],
};

const babymarktDeOverview = overviewFromStatus(babymarktDeStatus, 'AWIN', 'DE');

const cjTestMerchantConfig: MerchantFeedRawConfig<'CJ'> = {
  name: 'CJ test merchant',
  displayName: 'CJ test merchant',
  url: 'http://localhost:3000',
  networkParameterPrefix: 'http://localhost:3000',
  networkParameterSuffix: 'http://localhost:3000',
  storeName: 'DE',
  lowerPriceThreshold: 0,
  upperPriceThreshold: null,
  merchantScoreAdjustment: 1.0,
  officeDealerId: 'office3',
  sellableIn: 'HR',
  columnsMapping: {},
  deliveryTime: '',
  deliveryCost: '',
  currency: '',
  comment: '',
};

const cjTestMerchantStatus: MerchantFeedStatus = {
  name: 'CJ test merchant',
  processingStatus: 'success',
  lastUpdatedAt: Date.now() - 100 * hourInMilliseconds,
  lastSuccessfulUpdatedAt: Date.now() - 100 * hourInMilliseconds,
  processedProductsCount: 109203,
  failedProductsCount: 0,
  mostRecentResultsUrl:
    'https://myworld-my.sharepoint.com/:x:/p/jmikolajczak/EXhcXmiLHcxFt3HDOFy4rcoBsWsmQpkI2kvoqxTxedavuQ?e=hW1QzZ',
  groupedProductFailures: [],
  groupedProductEnrichmentFailures: [],
};

const cjTestMerchantOverview = overviewFromStatus(
  cjTestMerchantStatus,
  'CJ',
  'NL',
);

const cjAlwaysRunningConfig: MerchantFeedRawConfig<'CJ'> = {
  name: 'always running',
  displayName: 'always running',
  url: 'http://localhost:3000',
  networkParameterPrefix: 'http://localhost:3000',
  networkParameterSuffix: 'http://localhost:3000',
  storeName: 'DE',
  lowerPriceThreshold: 0,
  upperPriceThreshold: null,
  merchantScoreAdjustment: 1.0,
  officeDealerId: 'office3',
  sellableIn: 'AT',
  columnsMapping: {},
  deliveryTime: '',
  deliveryCost: '',
  currency: '',
  comment: '',
};

const cjAlwaysRunningStatus: MerchantFeedStatus = {
  name: 'always running',
  processingStatus: 'in-progress',
  lastUpdatedAt: Date.now() - 1 * hourInMilliseconds,
  lastSuccessfulUpdatedAt: Date.now() - 100 * hourInMilliseconds,
  processedProductsCount: 0,
  failedProductsCount: 0,
  groupedProductFailures: [],
  groupedProductEnrichmentFailures: [],
};

const cjAlwaysRunningOverview = overviewFromStatus(
  cjAlwaysRunningStatus,
  'CJ',
  'AT',
);

const cjTestWarningConfig: MerchantFeedRawConfig<'CJ'> = {
  name: 'test warning',
  displayName: 'test warning',
  url: 'http://localhost:3000',
  networkParameterPrefix: 'http://localhost:3000',
  networkParameterSuffix: 'http://localhost:3000',
  storeName: 'DE',
  lowerPriceThreshold: 0,
  upperPriceThreshold: null,
  merchantScoreAdjustment: 1.0,
  officeDealerId: 'office3',
  sellableIn: 'DE',
  columnsMapping: {},
  deliveryTime: '',
  deliveryCost: '',
  currency: '',
  comment: '',
};

const cjTestWarningStatus: MerchantFeedStatus = {
  name: 'test warning',
  processingStatus: 'warning',
  lastUpdatedAt: Date.now() - 2 * hourInMilliseconds,
  lastSuccessfulUpdatedAt: Date.now() - 2 * hourInMilliseconds,
  processedProductsCount: 2203,
  failedProductsCount: 456,
  mostRecentResultsUrl:
    'https://myworld-my.sharepoint.com/:x:/p/jmikolajczak/EXhcXmiLHcxFt3HDOFy4rcoBsWsmQpkI2kvoqxTxedavuQ?e=hW1QzZ',
  groupedProductFailures: [
    {
      failureMessage:
        'Category Winterharte Stauden > Winterharte Stauden A-Z > Steingartenstauden - Steingartenstauden does not have a matching entry in the provided category mapping ',
      linesNumber: '1-5, 7, 9-10, 12-15, 22',
    },
    {
      failureMessage:
        'Cannot transform row: Category Garten does not have a matching entry in the provided category mapping ',
      linesNumber: '7-10',
    },
  ],
  groupedProductEnrichmentFailures: [
    {
      failureMessage:
        'Category Winterharte Stauden > Winterharte Stauden A-Z > Steingartenstauden - Steingartenstauden does not have a matching entry in the provided category mapping ',
      linesNumber: '1-5, 7, 9-10, 12-15, 22',
    },
    {
      failureMessage:
        'Cannot transform row: Category Garten does not have a matching entry in the provided category mapping ',
      linesNumber: '7-10',
    },
  ],
};

const cjTestWarningOverview = overviewFromStatus(
  cjTestWarningStatus,
  'CJ',
  'PL',
);

const tradeTrackerHemaConfig: MerchantFeedRawConfig = {
  name: 'hema',
  displayName: 'hema',
  url: 'http://localhost:3000',
  networkParameterPrefix: 'http://localhost:3000',
  networkParameterSuffix: 'http://localhost:3000',
  storeName: 'DE',
  lowerPriceThreshold: 0,
  upperPriceThreshold: null,
  merchantScoreAdjustment: 1.0,
  officeDealerId: 'office-hema',
  sellableIn: 'AT',
  columnsMapping: {},
  deliveryTime: '',
  deliveryCost: '',
  currency: '',
  comment: '',
};

const tradeTrackerHemaStatus: MerchantFeedStatus = {
  name: 'HEMA',
  processingStatus: 'error',
  lastUpdatedAt: Date.now() - 4 * hourInMilliseconds,
  lastSuccessfulUpdatedAt: Date.now() - 27 * hourInMilliseconds,
  processedProductsCount: 0,
  failedProductsCount: 0,
  mostRecentResultsUrl:
    'https://myworld-my.sharepoint.com/:x:/p/jmikolajczak/EXhcXmiLHcxFt3HDOFy4rcoBsWsmQpkI2kvoqxTxedavuQ?e=hW1QzZ',
  error: 'Failed due to unexpected error while downloading csv file',
  groupedProductFailures: [],
  groupedProductEnrichmentFailures: [],
};

const tradeTrackerHemaOverview = overviewFromStatus(
  tradeTrackerHemaStatus,
  'tradetracker',
  'BG',
);

function overviewFromStatus(
  feedStatus: MerchantFeedStatus,
  affiliateNetwork: AffiliateNetworkSlug,
  country: SupportedCountry = 'AT',
) {
  const { name, processingStatus, lastUpdatedAt } = feedStatus;

  const overview: MerchantOverview = {
    name,
    processingStatus,
    lastUpdatedAt,
    slug: slugify(feedStatus.name, { lower: true }),
    affiliateNetwork,
    sellableIn: country,
  };

  return overview;
}

type MerchantMocks = {
  [affiliateNetwork in AffiliateNetworkSlug]: {
    overview: MerchantOverview[];
    status: MerchantFeedStatus[];
    config: affiliateNetwork extends 'AWIN'
      ? MerchantFeedRawConfig<'AWIN'>[]
      : affiliateNetwork extends 'CJ'
      ? MerchantFeedRawConfig<'CJ'>[]
      : MerchantFeedRawConfig[];
  };
};

export const merchantsMocks: MerchantMocks = Object.freeze({
  AWIN: {
    overview: [rtvEuroAgdOverview, babymarktDeOverview],
    status: [rtvEuroAgdStatus, babymarktDeStatus],
    config: [rtvEuroAgdConfig, babymarktDeConfig],
  },
  CJ: {
    overview: [
      cjTestMerchantOverview,
      cjAlwaysRunningOverview,
      cjTestWarningOverview,
    ],
    status: [cjTestMerchantStatus, cjAlwaysRunningStatus, cjTestWarningStatus],
    config: [cjTestMerchantConfig, cjAlwaysRunningConfig, cjTestWarningConfig],
    categoryMapping: [
      simpleWithMissingCategoriesMock,
      simpleCategoryMappingMock,
      simpleMissingCategoriesMock,
    ],
  },
  tradetracker: {
    overview: [tradeTrackerHemaOverview],
    status: [tradeTrackerHemaStatus],
    config: [tradeTrackerHemaConfig],
  },
  'empty-affiliate-network': {
    overview: [],
    status: [],
    config: [],
  },
});
