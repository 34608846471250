import { ChakraProvider, theme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider, QueryClient } from 'react-query';

import { AppRouter } from './AppRouter';

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme} portalZIndex={3}>
          <AppRouter />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </ChakraProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};
