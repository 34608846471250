import { Input } from '@chakra-ui/react';
import React, { ComponentPropsWithoutRef, useMemo } from 'react';

import { MerchantOverview } from '../../data/contracts/affiliateNetworksContracts';
import {
  defaultFuseOptions,
  SearchTextInputGroup,
  useSearchTextFilter,
} from '../../components/Forms/SearchTextInput';
import {
  createContextWithoutDefaultValue,
  useDefinedContext,
} from '../../utils/hooks/useDefinedContext';
import { useStateWithDebounce } from '../../utils/hooks/useStateWithDebounce';
import { SetState } from '../../types/helpers';

type MerchantDebouncedSearchContextValue = {
  searchText: string;
  debouncedSearchText: string;
  setSearchText: SetState<string>;
  flushDebouncedSearchText: () => void | undefined;
  isSearchApplied: boolean;
};

const MerchantDebouncedSearchContext = createContextWithoutDefaultValue<MerchantDebouncedSearchContextValue>();

export const MerchantDebouncedSearchContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const [
    searchText,
    debouncedSearchText,
    setSearchText,
    flushDebouncedSearchText,
  ] = useStateWithDebounce('');

  const isSearchApplied = !!searchText;

  const value = useMemo(
    () => ({
      searchText,
      debouncedSearchText,
      setSearchText,
      flushDebouncedSearchText,
      isSearchApplied,
    }),
    [
      searchText,
      debouncedSearchText,
      setSearchText,
      flushDebouncedSearchText,
      isSearchApplied,
    ],
  );

  return (
    <MerchantDebouncedSearchContext.Provider value={value}>
      {props.children}
    </MerchantDebouncedSearchContext.Provider>
  );
};

const searchKey: keyof MerchantOverview = 'name';

export function useMerchantsSearchFilter(data: MerchantOverview[]) {
  const { debouncedSearchText } = useDefinedContext(
    MerchantDebouncedSearchContext,
  );

  return useSearchTextFilter(data, debouncedSearchText, {
    ...defaultFuseOptions,
    keys: [searchKey],
  });
}

type MerchantsSearchInputProps = Partial<
  ComponentPropsWithoutRef<typeof SearchTextInputGroup>
>;

export function useIsMerchantsSearchApplied() {
  const { isSearchApplied } = useDefinedContext(MerchantDebouncedSearchContext);
  return isSearchApplied;
}

export function MerchantsSearchInput({
  ...searchTextInputGroupProps
}: MerchantsSearchInputProps) {
  const {
    searchText,
    setSearchText,
    flushDebouncedSearchText,
  } = useDefinedContext(MerchantDebouncedSearchContext);

  return (
    <SearchTextInputGroup
      value={searchText}
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      onReset={() => {
        setSearchText('');
        flushDebouncedSearchText();
      }}
      input={<Input placeholder="Search merchants" flexGrow={1} />}
      {...searchTextInputGroupProps}
    />
  );
}
