import React, { useContext, createContext } from 'react';

type TDefinedContext<TValue> = React.Context<TValue | undefined>;

export function createContextWithoutDefaultValue<TValue extends {} | null>() {
  return createContext<TValue | undefined>(undefined);
}

export function useDefinedContext<TValue extends undefined | {} | null>(
  context: TDefinedContext<TValue>,
) {
  const value = useContext(context);
  if (value === undefined) {
    throw new Error(
      `Context value is undefined. Make sure that you have relevant context provider present in component tree.`,
    );
  }

  return value;
}
