import { Text } from '@chakra-ui/react';

export const TextWithHighlights = (props: {
  text: string;
  highlightedMatch: [number, number][];
  minimumLettersMatchLength?: number;
}) => {
  const { minimumLettersMatchLength = 2 } = props;
  const moreThanMinimumLetterMatches = props.highlightedMatch.filter(
    ([start, end]) => end - start >= minimumLettersMatchLength,
  );

  if (moreThanMinimumLetterMatches.length === 0) {
    return props.text;
  }

  const result = [] as any;
  let unmatchedFragmentStart = 0;

  moreThanMinimumLetterMatches.forEach(([start, end], i) => {
    if (unmatchedFragmentStart < start) {
      result.push(props.text.substring(unmatchedFragmentStart, start));
    }
    unmatchedFragmentStart = end + 1;

    result.push(
      <Text key={`fragment-${start}-${end}`} as="span" bgColor="yellow.200">
        {props.text.substring(start, end + 1)}
      </Text>,
    );

    const shouldFillLastUnmatchedFragment =
      i === moreThanMinimumLetterMatches.length - 1 &&
      end < props.text.length - 1;

    if (shouldFillLastUnmatchedFragment) {
      result.push(props.text.substring(end + 1, props.text.length));
    }
  });

  return result;
};
