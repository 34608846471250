const pluralRules = new Intl.PluralRules('en-US');

export function pluralize(count: number, singular: string, plural?: string) {
  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case 'one':
      return singular;
    case 'other':
      return plural ?? `${singular}s`;
    default:
      throw new Error(`Not supported grammatical number: ${grammaticalNumber}`);
  }
}
