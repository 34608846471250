import { useEffect, useRef, useState } from 'react';

import { usePreviousValue } from './usePreviousValue';

const delay = 200;

export function useDelayedLoader(isLoading: boolean) {
  const wasLoadingBefore = usePreviousValue(isLoading);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (!wasLoadingBefore && isLoading) {
      timeoutRef.current = window.setTimeout(() => {
        setIsLoaderVisible(true);
      }, delay);
    }

    if (!isLoading && wasLoadingBefore) {
      window.clearTimeout(timeoutRef.current!);
      setIsLoaderVisible(false);
    }
  }, [isLoading, wasLoadingBefore]);

  useEffect(() => {
    return () => window.clearTimeout(timeoutRef.current!);
  }, []);

  return isLoaderVisible;
}
