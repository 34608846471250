import { Box, Heading, Flex } from '@chakra-ui/react';
import React from 'react';

import { StatusItemSkeleton } from '../../components/StatusList';
import { useMerchantsQuery } from '../../utils/fetch';

import { SortConfigContextProvider } from './MerchantsSorting';
import { MerchantsFiltersContextProvider } from './MerchantsFilters';
import { MerchantDebouncedSearchContextProvider } from './MerchantsSearch';
import { MerchantsActionsPanel } from './MerchantsActionsPanel';
import { MerchantsList } from './MerchantsList';

const MerchantsListTransformationsContextProviders = (
  props: React.PropsWithChildren<{}>,
) => (
  <MerchantsFiltersContextProvider>
    <SortConfigContextProvider>
      <MerchantDebouncedSearchContextProvider>
        {props.children}
      </MerchantDebouncedSearchContextProvider>
    </SortConfigContextProvider>
  </MerchantsFiltersContextProvider>
);

type MerchantsSectionProps = {
  affiliateNetworkSlug: string;
  merchantSlug?: string;
  newMerchantRedirectSlug?: string;
};

export function MerchantsSection(props: MerchantsSectionProps) {
  const query = useMerchantsQuery(props.affiliateNetworkSlug, {
    handleErrors: true,
    shouldRefetch: true,
  });

  return (
    <MerchantsListTransformationsContextProviders
      key={props.affiliateNetworkSlug}
    >
      <Flex data-testid="merchants-section" direction="column" h="100%">
        <Heading as="h4" size="md" p={6}>
          Merchants
        </Heading>
        <MerchantsActionsPanel
          affiliateNetworkSlug={props.affiliateNetworkSlug}
          merchants={query.data ?? []}
        />
        {/*
          Merchants list is virtualized - it grows to the container size
          instead of making it grow via content (due to absolute positioning),
          so we are making this container to take all available space.

          Overflow is set to "hidden" to avoid jumping of scrollbar in
          merchants list when sorting & filtering panel is revealed. This is
          needed as there is Autosizer component that has to recalculate height
          to adjust to new available height reduced because of new content in
          actions panel and it has to finish after uncollapse animation, but in
          the meantime container height will be exceeded making flash of space
          being taken for scrollbar to appear. Setting overflow to hidden will
          prevent it as no scrollbar will ever appear regardless of children
          height.
         */}
        <Box flexGrow={1} h="100%" overflow="hidden" zIndex={1}>
          {query.isFetched ? (
            <MerchantsList
              affiliateNetworkSlug={props.affiliateNetworkSlug}
              merchantSlug={props.merchantSlug}
              newMerchantRedirectSlug={props.newMerchantRedirectSlug}
              merchants={query.data!}
            />
          ) : (
            Array.from(Array(8).keys()).map((index) => (
              <StatusItemSkeleton key={index} mx={6} mt={2} />
            ))
          )}
        </Box>
      </Flex>
    </MerchantsListTransformationsContextProviders>
  );
}
