import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';

export function NoBlacklistedAlert() {
  return (
    <Alert
      status="info"
      title="no column mapping applied"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <AlertIcon />
      <AlertTitle my={1}>There is no blacklisted elements.</AlertTitle>
    </Alert>
  );
}
