import { useCallback } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Stack,
  FormLabel,
} from '@chakra-ui/react';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import * as Sentry from '@sentry/react';
import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';

import {
  ColumnsMapping,
  MappableColumn,
} from '../../../data/contracts/columnsMapping';
import {
  assertUnreachable,
  isNonEmptyArray,
  NonEmptyArray,
} from '../../../types/helpers';
import { FormStatusAlert } from '../../../components/Forms/FormStatusAlert';
import { SelectField } from '../../../components/Forms/SelectField';
import { TextField } from '../../../components/Forms/TextField';
import { FormModal } from '../../../components/Forms/FormModal';

import { allMappableColumns, columnNameLabels } from './utils/columnNames';

type ColumnMappingFormValues = {
  name: MappableColumn;
  columns: string[];
};

// This is needed for TextField to correctly infer possible "name" values.
// As columns are array of string and TextField can only digest single string field,
// this is type that represents all possible formik names for array field.
type NewColumnMappingColumnsFormKeys = {
  [indexedColumn in `${keyof Pick<
    ColumnMappingFormValues,
    'columns'
  >}[${number}]`]: string;
};

const validationShape: Record<keyof ColumnMappingFormValues, Yup.BaseSchema> = {
  name: Yup.string()
    .required('Required')
    .oneOf(allMappableColumns)
    .strict()
    .trim(),
  columns: Yup.array(Yup.string().required(`Can't be blank`)),
};

const validationSchema = Yup.object().shape(validationShape);

export type ColumnMappingFormType =
  | { kind: 'add' }
  | { kind: 'edit'; name: MappableColumn; columns: string[] };

function getAvailableColumnNameOptions(
  formType: ColumnMappingFormType,
  columnsMapping: ColumnsMapping,
) {
  switch (formType.kind) {
    case 'add':
      return columnNameLabels.filter(({ value }) =>
        columnsMapping.some(
          (mapping) =>
            mapping.name === value && mapping.status === 'not-active',
        ),
      );
    case 'edit':
      return [columnNameLabels.find(({ value }) => value === formType.name)!];
    default:
      assertUnreachable(formType);
  }
}

function getInitialNewColumnName(
  columnsMapping: ColumnsMapping,
): MappableColumn {
  for (const columnMapping of columnsMapping) {
    if (columnMapping.status === 'not-active') {
      return columnMapping.name;
    }
  }

  return columnsMapping[0].name;
}

function getInitialColumnMappingFormValues(
  type: ColumnMappingFormType,
  columnsMapping: ColumnsMapping,
) {
  switch (type.kind) {
    case 'add':
      return {
        name: getInitialNewColumnName(columnsMapping),
        columns: [''],
      };
    case 'edit':
      return {
        name: type.name,
        columns: type.columns,
      };
    default:
      assertUnreachable(type);
  }
}

function getColumnMappingFormTitle(type: ColumnMappingFormType) {
  switch (type.kind) {
    case 'add':
      return 'Add new dynamic column mapping';
    case 'edit':
      return 'Edit dynamic column mapping';
    default:
      assertUnreachable(type);
  }
}

function getColumnMappingFormLabel(type: ColumnMappingFormType) {
  switch (type.kind) {
    case 'add':
      return 'new column mapping';
    case 'edit':
      return 'edit column mapping';
    default:
      assertUnreachable(type);
  }
}

const ErrorAlert = (props: { onClose: () => void }) => (
  <FormStatusAlert onClose={props.onClose} mb={6}>
    Failed to save changes. Try again later or contact support if problem occurs
    again.
  </FormStatusAlert>
);

const FallbackColumnsLabel = () => (
  <Box mt={6}>
    <FormLabel>
      Fallback columns in input CSV file (will be used in specified order)
    </FormLabel>
  </Box>
);

const FormLayout = (props: {
  leftColumn: React.ReactNode;
  rightColumn: React.ReactNode;
}) => (
  <Flex flexDirection="row" flexWrap="nowrap" alignItems="flex-start" mt={6}>
    <Box w="calc(30% - 25px)">{props.leftColumn}</Box>
    <Box w="50px" textAlign="center" pt={10}>
      <ArrowBackIcon />
    </Box>
    <Box spacing={0} w="calc(70% - 25px)">
      {props.rightColumn}
    </Box>
  </Flex>
);

const FallbackColumnField = (props: { index: number }) => (
  <TextField<NewColumnMappingColumnsFormKeys>
    name={`columns[${props.index}]`}
    {...(props.index > 0
      ? {
          'aria-label':
            'Fallback columns in input CSV file (will be used in specified order)',
        }
      : {})}
    placeholder="e.g. column_NAME"
  />
);

type FallbackColumnsFieldsProps = {
  values: ColumnMappingFormValues;
};

const FallbackColumnsFields = (props: FallbackColumnsFieldsProps) => (
  <FieldArray
    name="columns"
    render={({ remove, push }) => (
      <>
        <Stack>
          {props.values.columns.map((_, index) =>
            index === 0 ? null : (
              <HStack role="group" title="fallback column actions" key={index}>
                <FallbackColumnField index={index} />
                <IconButton
                  onClick={() => {
                    remove(index);
                  }}
                  aria-label="remove fallback column"
                  icon={<DeleteIcon />}
                  alignSelf="flex-start"
                />
              </HStack>
            ),
          )}
        </Stack>
        <Button
          onClick={() => {
            push('');
          }}
          alignSelf="flex-start"
          mt={4}
        >
          Add {props.values.columns.length > 1 ? 'next ' : ''} fallback column
        </Button>
      </>
    )}
  />
);

const FormActions = (props: {
  isSubmitting: boolean;
  onCancel: () => void;
}) => (
  <>
    <Button
      type="submit"
      isLoading={props.isSubmitting}
      mr={2}
      colorScheme="blue"
    >
      Save
    </Button>
    <Button
      onClick={props.onCancel}
      isDisabled={props.isSubmitting}
      variant="outline"
    >
      Cancel
    </Button>
  </>
);

type ColumnsMappingFormProps = {
  formType: ColumnMappingFormType;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formValues: {
    name: MappableColumn;
    columns: NonEmptyArray<string>;
  }) => Promise<void>;
  columnsMapping: ColumnsMapping;
};

export function ColumnsMappingForm({
  formType,
  isOpen,
  onClose,
  onSubmit,
  columnsMapping,
}: ColumnsMappingFormProps) {
  const columnMappingSubmitHandler = useCallback(
    async (
      { name, columns }: ColumnMappingFormValues,
      formikHelpers: FormikHelpers<ColumnMappingFormValues>,
    ) => {
      try {
        if (!isNonEmptyArray(columns)) {
          throw new Error(`Unexpected empty array for columns`);
        }
        formikHelpers.setStatus();
        await onSubmit({ name, columns });
        onClose();
        formikHelpers.resetForm();
      } catch (error) {
        // Should distinguish 400 errors in future
        Sentry.captureException(error);
        formikHelpers.setStatus('error');
      }
    },
    [onSubmit, onClose],
  );

  return (
    <Formik
      initialValues={getInitialColumnMappingFormValues(
        formType,
        columnsMapping,
      )}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={columnMappingSubmitHandler}
    >
      {({ isSubmitting, resetForm, status, setStatus, values }) => (
        <FormModal
          isOpen={isOpen}
          onClose={onClose}
          contentWrapper={
            <Form aria-label={getColumnMappingFormLabel(formType)} />
          }
          header={getColumnMappingFormTitle(formType)}
          body={
            <>
              {status === 'error' && <ErrorAlert onClose={() => setStatus()} />}
              <FormLayout
                leftColumn={
                  <SelectField<ColumnMappingFormValues>
                    name="name"
                    label={`${
                      formType.kind === 'edit' ? 'Column' : 'Select column'
                    } in result file`}
                    options={getAvailableColumnNameOptions(
                      formType,
                      columnsMapping,
                    )}
                    isReadOnly={formType.kind === 'edit'}
                    title="result column name"
                  />
                }
                rightColumn={
                  <>
                    <TextField<NewColumnMappingColumnsFormKeys>
                      name="columns[0]"
                      label="Column name in input CSV file"
                      placeholder="e.g. column_NAME"
                    />
                    {values.columns.length > 1 && <FallbackColumnsLabel />}
                    <FallbackColumnsFields values={values} />
                  </>
                }
              />
            </>
          }
          footer={
            <FormActions
              isSubmitting={isSubmitting}
              onCancel={() => {
                onClose();
                resetForm();
              }}
            />
          }
        />
      )}
    </Formik>
  );
}
