import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

export function NoColumnsMappingAlert() {
  return (
    <Alert
      status="info"
      title="no column mapping applied"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <AlertIcon />
      <AlertTitle my={1}>There is no custom column mapping applied.</AlertTitle>
      <AlertDescription mb={1}>
        You can use custom column mapping when merchant feed CSV structure is
        not compliant with default column mapping scheme.
        <br />
        To do so, indicate from which CSV feed columns value should be taken.
        Supported for category, EAN and abstract SKU values.
      </AlertDescription>
    </Alert>
  );
}
