import { ComponentWithAs } from '@chakra-ui/system';

export type NonEmptyArray<T> = [T, ...T[]];

export function isNonEmptyArray<T>(array: T[]): array is NonEmptyArray<T> {
  return array.length > 0;
}

export type FormValues<TConfig extends {}> = {
  [ConfigKey in keyof TConfig]: TConfig[ConfigKey] extends number
    ? TConfig[ConfigKey] | ''
    : TConfig[ConfigKey];
};

export type Fields<TObject, TKeys extends keyof TObject> = Extract<
  TKeys,
  keyof TObject
>;

export type OmitFields<TObject, TKeys extends keyof TObject> = Omit<
  TObject,
  Fields<TObject, TKeys>
>;

export type AssertAllValuesAreUsed<TAllValues, TValues> = Exclude<
  TAllValues,
  TValues
> extends never
  ? true
  : false;

export type KeysOfType<
  T extends { [key: string]: any },
  TypeToPick
> = keyof T extends string
  ? {
      [Key in keyof T]: T[Key] extends TypeToPick ? Key : never;
    }[keyof T]
  : never;

export type PickOfType<T, TypeToPick extends T[keyof T]> = {
  [Key in KeysOfType<T, TypeToPick>]: T[Key];
};

export type ChakraComponentProps<
  TChakraComponent extends ComponentWithAs<any, any>
> = Parameters<TChakraComponent>[0];

export type AnyFn = (...args: any) => any;

export function assertUnreachable(_: never, message?: string): never {
  throw new Error(message ?? 'Reached code that should never be reached.');
}

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;
