import { useEffect, useRef } from 'react';

export function usePreviousValue<TValue>(value: TValue) {
  const prevValue = useRef<TValue | null>(null);

  useEffect(() => {
    prevValue.current = value;

    return () => {
      prevValue.current = null;
    };
  });

  return prevValue.current;
}
