import { UnixTimestamp } from '../../../utils/dateTime';

const hourInMilliseconds = 60 * 60 * 1000;

export type blacklisted = {
  createdAt: UnixTimestamp;
  userName: string;
  id: string;
  name: string;
  comment: string;
};

export const blacklistedMock: blacklisted[] = [
  {
    createdAt: Date.now() - 10 * hourInMilliseconds,
    userName: 'amaizing@user.pl',
    id: 'ef4107b3-2637-350a-82b2-7acf3ca36f85',
    name: 'Muck Boots Mens Chore Wellington Boots',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vehicula velit sit amet metus semper tristique. Vestibulum pellentesque ligula a dolor auctor molestie.',
  },
];
